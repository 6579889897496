import styled, { css } from "styled-components";

const HEADER_HEIGHT = "70px";

export const Wrapper = styled.div<{ isWDPreview?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({ isWDPreview }) =>
    isWDPreview &&
    css`
      margin-bottom: 15px;
    `};
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  height: ${HEADER_HEIGHT};
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 60px 1rem 8px;
  min-height: 4.3rem;
  border-bottom: 1px solid #eff1f4;
  background: white;
`;

export const Actions = styled.div`
  margin-left: 2rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 2rem;
  height: 100%;
  overflow-x: hidden;
`;

export const Container = styled.div<{ hasHeader: boolean }>`
  display: flex;
  flex-direction: row;
  height: ${({ hasHeader }) =>
    hasHeader ? `calc(100% - ${HEADER_HEIGHT})` : "100%"};
`;
