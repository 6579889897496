import React from "react";
import { Col, Row } from "antd";
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";
import { DimensionTag, Input, Wrapper } from "components";
import QuestionSidebar from "./QuestionSidebar";
import { DimensionDataFragment } from "codegen/generated/graphql";
import { useFeatureContext } from "../context";

import * as S from "./styles";

interface Props {
  onChangeText: (value: string) => void;
  selectedDimension?: DimensionDataFragment;
}

export const Question: React.FC<Props> = ({
  onChangeText,
  selectedDimension,
}) => {
  const {
    state: {
      data: { text: textValue },
    },
  } = useFeatureContext();

  return (
    <Row style={{ height: "100%" }}>
      <S.Wrapper>
        <Title
          style={{
            fontWeight: "bold",
          }}
          level={4}
        >
          Dimension
        </Title>
        <Wrapper margin="0.5rem 0 0 0">
          <DimensionTag
            background={selectedDimension?.meta.backgroundColor}
            color={selectedDimension?.meta.textColor}
            label={selectedDimension?.name ?? ""}
          />
        </Wrapper>
        <Wrapper margin="1rem 0 0 0">
          <Wrapper margin="0.5rem 0 0.5rem 0">
            <Text strong>Question</Text>
          </Wrapper>
          <Input
            name="question"
            value={textValue}
            onChange={(e) => onChangeText(e.target.value)}
          />
        </Wrapper>
        <Col span={24}>
          <QuestionSidebar />
        </Col>
      </S.Wrapper>
    </Row>
  );
};

export default Question;
