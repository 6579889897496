import { ReactElement, useState } from "react";
import {
  Modal,
  Row,
  Col,
  Typography,
  Input,
  Button,
  Form,
  Space,
  notification,
  Alert,
} from "antd";
import { useShareReadmeMutation } from "codegen/generated/graphql";
import { ButtonTertiary, ImgSvg } from "components";
import minus from "../../assets/images/minus-black.svg";
import plus from "../../assets/images/plus-blue.svg";
import lock from "../../assets/images/lock-green.svg";
import { LABEL } from "utils/constValues";
import { ApolloError } from "@apollo/client";

const { Text, Title } = Typography;
const { TextArea } = Input;

const ShareReadmeModal = ({
  isShareReadmeModal,
  setShowReadMeModal,
  content,
  readMeLink,
  userData,
}: {
  isShareReadmeModal: boolean;
  setShowReadMeModal: (visible: boolean) => void;
  content: boolean;
  readMeLink: any;
  userData: any;
}): ReactElement => {
  const { profile } = userData;
  const [copy, setCopy] = useState(false);
  const [error, setError] = useState(false);
  const [shareReadme] = useShareReadmeMutation();
  const defaultValueBody = `Hello friends!
                  
Here is my POP. I hope you check it out. It’s available here:
${process.env.REACT_APP_DOMAIN_URL + "/" + readMeLink?.link}
  
Thanks!
${profile?.name !== null ? profile?.name : null}`;

  const onFinish = (values: any) => {
    const emailData: string[] = [];
    if (values?.users?.length > 0) {
      values.users.forEach((item: { email: string }) => {
        emailData.push(item.email);
      });
      shareReadme({
        variables: {
          input: {
            readMeId: readMeLink.id,
            emailBody: values?.emailBody,
            sharedWithEmailId: emailData,
            sharableLink:
              process.env.REACT_APP_DOMAIN_URL + "/" + readMeLink?.link,
          },
        },
      })
        .then((res) => {
          if (res?.data) {
            setShowReadMeModal(false);
            notification.success({
              type: "success",
              message: "Email send successfully",
              placement: "bottomLeft",
            });
          }
        })
        .catch((err: ApolloError) => {
          notification.error({
            type: "warning",
            message: err.message.split("[")[0],
            placement: "bottomLeft",
          });
        });
    } else {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  const handleCancel = () => {
    setShowReadMeModal(false);
  };

  const handleCopy = (readMeLink: any) => {
    navigator.clipboard.writeText(
      process.env.REACT_APP_DOMAIN_URL + "/" + readMeLink?.link
    );
    setCopy(true);
  };

  return (
    <Modal
      visible={isShareReadmeModal}
      footer={null}
      style={{
        overflow: "hidden",
        borderRadius: "1rem",
        textAlign: "center",
        padding: "0",
        position: "relative",
      }}
      centered
      closable={false}
      width={"500px"}
      onCancel={handleCancel}
    >
      {!content ? (
        <Row style={{ marginTop: "1rem" }}>
          <Col span={24}>
            <Text>
              Please note you cannot preview and share your POP until all of the
              Quick Start questions are completed.
            </Text>
          </Col>
          <Col
            span={24}
            style={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ButtonTertiary onClick={() => setShowReadMeModal(false)}>
              Ok
            </ButtonTertiary>
          </Col>
        </Row>
      ) : (
        <Form
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{ emailBody: defaultValueBody }}
        >
          {copy && (
            <Alert message="URL copied" type="success" showIcon closable />
          )}
          {error && (
            <Alert
              message="Please add at least one email"
              type="error"
              showIcon
              closable
              onClose={() => setError(false)}
            />
          )}
          <Row style={{ margin: " 0.3rem 0.3rem", textAlign: "justify" }}>
            <Col
              className="col-position"
              style={{ position: "static", width: "100%" }}
            >
              <Title style={{ fontSize: 25 }}>{LABEL.SHARE_MY_POP}</Title>
            </Col>
            <Col
              className="col-position"
              style={{ position: "static", marginTop: 25 }}
            >
              <Title style={{ fontSize: 12 }}>{LABEL.SHARE_LINK_TITLE}</Title>
            </Col>

            <Col
              className="col-position"
              style={{
                marginTop: "0.5rem",
                display: "flex",
                justifyContent: "center",
                gap: "1rem",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Input
                disabled
                value={
                  process.env.REACT_APP_DOMAIN_URL + "/" + readMeLink?.link
                }
                placeholder={
                  process.env.REACT_APP_DOMAIN_URL + "/" + readMeLink?.link
                }
                prefix={<ImgSvg src={lock} alt="mouse" />}
                style={{ fontSize: 13 }}
              />
              <ButtonTertiary
                style={{
                  background: "#01083A",
                  width: "150px",
                  height: "33px",
                  borderRadius: "3px",
                  border: "none",
                  outline: "none",
                }}
                onClick={() => handleCopy(readMeLink)}
              >
                <Text style={{ fontSize: 14 }} strong>
                  {LABEL.COPY}
                </Text>
              </ButtonTertiary>
            </Col>

            <Col className="col-position" style={{ marginTop: 25 }}>
              <Title style={{ fontSize: 12, marginBottom: 10 }}>
                {LABEL.SEND_EMAIL_TITLE}
              </Title>
              <Title style={{ fontSize: 10 }}>{LABEL.EMAIL_ADDRESS}</Title>
              <Form.List name="users">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} style={{ marginBottom: "-5px" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "email"]}
                          rules={[
                            {
                              type: "email",
                              message: "Please enter valid email",
                            },
                            {
                              required: true,
                              message: "Please enter email",
                            },
                          ]}
                        >
                          <Input
                            placeholder="mail@mail.com"
                            suffix={
                              <ImgSvg
                                src={minus}
                                alt="mouse"
                                onClick={() => remove(name)}
                              />
                            }
                            style={{
                              fontSize: 13,
                              width: "440px",
                            }}
                          />
                        </Form.Item>
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="default"
                        block
                        onClick={() => add()}
                        icon={
                          <ImgSvg
                            src={plus}
                            alt="mouse"
                            style={{ marginRight: 5, marginBottom: 1 }}
                          />
                        }
                        style={{ fontSize: 14, width: "440px" }}
                      >
                        {LABEL.ADD_EMAIL}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Title style={{ fontSize: 10 }}>{LABEL.MESSAGE}</Title>
              <Form.Item name="emailBody">
                <TextArea
                  name="emailBody"
                  style={{
                    width: "633px",
                    height: 140,
                    fontSize: 12,
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              style={{
                marginTop: "1px",
                marginLeft: "auto",
              }}
            >
              <ButtonTertiary
                htmlType="submit"
                style={{
                  background: "#01083A",
                  width: "150px",
                  height: "33px",
                  borderRadius: "3px",
                  border: "none",
                  outline: "none",
                }}
              >
                <Text style={{ fontSize: 14 }} strong>
                  {LABEL.SEND_EMAIL}
                </Text>
              </ButtonTertiary>
            </Col>
            <Col
              style={{
                marginTop: "0.1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Button type="default" onClick={() => setShowReadMeModal(false)}>
                {LABEL.SKIP_FOR_NOW}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};

export default ShareReadmeModal;
