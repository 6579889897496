import { FC, useReducer, useState } from "react";
import { Upload, Spin, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { storage } from "utils/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import theme from "theme";

type FileUploadProps = {
  reference: string;
  loading: boolean;
  component: (props: any) => JSX.Element;
  onSuccess?: (src: string) => void;
};

export enum ActionType {
  UPLOAD_PROGRESS,
  UPLOAD_ERROR,
  UPLOAD_END,
}

type Action =
  | { type: ActionType.UPLOAD_PROGRESS; payload: number }
  | { type: ActionType.UPLOAD_ERROR; payload: any }
  | { type: ActionType.UPLOAD_END; payload: any };

type State = {
  onProgress: boolean;
  onComplete: boolean;
  uploadError?: string;
  progress: number;
  fileUrl?: string;
};

const firebaseUploderReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.UPLOAD_PROGRESS:
      return { onProgress: true, onComplete: false, progress: action.payload };
    case ActionType.UPLOAD_ERROR:
      return {
        onProgress: false,
        onComplete: false,
        uploadError: action.payload,
        progress: 100,
      };
    case ActionType.UPLOAD_END:
      return {
        onProgress: false,
        onComplete: true,
        fileUrl: action.payload,
        progress: 100,
      };
    default:
      return state;
  }
};

const firebaseUploadAction =
  (dispatch: any, reference: string) =>
  ({ onError, onProgress, onSuccess, file }: UploadRequestOption) => {
    file = file as Blob;
    const storageRef = ref(
      storage,
      `${reference}/avatar.${file.type.split("/").pop()}`
    );

    const task = uploadBytesResumable(storageRef, file);
    task.on(
      "state_changed",
      function progress(snapshot) {
        dispatch({
          type: ActionType.UPLOAD_PROGRESS,
          payload: snapshot.bytesTransferred / snapshot.totalBytes,
        });
      },

      function error(err) {
        dispatch({ type: ActionType.UPLOAD_ERROR, payload: err });
        onError?.(err, file);
      },

      function complete() {
        getDownloadURL(task.snapshot.ref).then((fileUrl) => {
          dispatch({ type: ActionType.UPLOAD_END, payload: fileUrl });
          onSuccess?.(fileUrl);
        });
      }
    );
  };

export const FileUpload: FC<FileUploadProps> = ({
  reference,
  loading,
  component,
  onSuccess = () => void 0,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [state, dispatch] = useReducer(firebaseUploderReducer, {
    onProgress: false,
    onComplete: false,
    progress: 0,
  });

  return (
    <Tooltip
      overlay={state.onProgress || state.onComplete ? null : "Change image"}
    >
      <Upload
        customRequest={firebaseUploadAction(dispatch, reference)}
        showUploadList={false}
        onChange={(info) =>
          info.file.response && onSuccess(info.file.response as string)
        }
      >
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{ opacity: isHovered ? 0.5 : 1, transition: "all 0.5s" }}
        >
          {loading || state.onProgress
            ? component({
                src: (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 48, color: theme.colors.white }}
                        spin
                      />
                    }
                  />
                ),
              })
            : component({})}
        </div>
      </Upload>
    </Tooltip>
  );
};
