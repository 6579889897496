import styled from "styled-components";
import { Typography, Collapse as ACollapse, Card as ACard } from "antd";
import CheckOutlined from "@ant-design/icons/lib/icons/CheckOutlined";
import { RadioGroup } from "components/Radio";

const { Paragraph: AParagraph, Title: ATtitle, Text: AText } = Typography;

export const Card = styled(ACard)<{
  $isActive?: boolean;
  $selectedPlan?: boolean;
}>`
  border-radius: 1rem;
  transition: transform 250ms;
  height: 600px;
  &:hover {
    transform: scale(1.05);
    border: 1px solid blue;
  }
  box-shadow: ${({ $isActive }) =>
    $isActive ? "0 4px 12px rgba(0,0,0,0.1)" : "none"};
  & > .ant-card-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
  }
`;

export const Paragraph = styled(AParagraph)`
  min-height: 60px;
  margin-top: 10px;
  &.ant-typography {
    color: ${({ theme }) => theme.colors.black};
    font-size: 13px;
    line-height: 19px;
  }
`;

export const Text = styled(AText)`
  margin-bottom: 1rem;
  &.ant-typography {
    color: ${({ theme }) => theme.colors.grey};
    font-size: 13px;
    line-height: 19px;
  }
`;

export const TextTitle = styled(AText)`
  margin-top: 4px;
  &.ant-typography {
    color: ${({ theme }) => theme.colors.blue_100};
    font-size: 17px;
    line-height: 26px;
  }
`;

export const Title = styled(ATtitle)`
  &.ant-typography {
    font-weight: 400;
  }
`;

export const LargeText = styled(AText)`
  &.ant-typography {
    font-size: 42px;
    line-height: 54px;
  }
`;

export const Collapse = styled(ACollapse)`
  font-size: 13px;
  margin-bottom: 12px;

  & > .ant-collapse-item > .ant-collapse-header {
    padding: 2px 0;
  }
`;

export const CheckIcon = styled(CheckOutlined)`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue_70};
  font-size: 9px;
  line-height: 22px;
  margin-right: 10px;
  border-radius: 1rem;
  height: 1rem;
  width: 1rem;
  min-width: 1rem;
`;

export const FancyCard = styled(ACard)`
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  box-shadow: 0px 5px 48px -20px rgba(1, 8, 58, 0.2);
  backdrop-filter: blur(100px);
  border-radius: 20px;
  width: 100%;

  & > .ant-card-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 25px 29px;
  }
`;

export const ButtonWrap = styled.div`
  margin-top: auto;

  .ant-space {
    width: 100%;
  }
`;

export const RadioButton = styled(RadioGroup)`
  .ant-radio-wrapper span:first-child {
    width: 3%;
    min-width: 1rem;
  }
  .ant-radio-wrapper span:last-child {
    width: 95%;
  }
  @media screen and (max-width: 1024px) {
    .ant-radio-wrapper span:first-child {
      width: 6%;
    }
    .ant-radio-wrapper span:last-child {
      width: 94%;
    }
  }
`;
export const TeamTextCurrent = styled(AText)`
  &.ant-typography {
    font-size: 13px;
    width: 100%;
    color: #9b9b9b;
  }
`;

export const TeamTextUpdated = styled(AText)`
  &.ant-typography {
    font-size: 13px;
    width: 100%;
  }
`;
