import { FC, useEffect, useContext } from "react";
import { DashboardType } from "./NavTab.styles";
import { appMutations } from "store/app";
import { USER_TABS_PRIMARY_ADMIN, USER_TABS_ADMIN } from "store/app/constants";
import { UserRole } from "codegen/generated/graphql";
import { AuthContext } from "utils/context/auth";

type Props = {
  enabled: boolean;
};

const NavTab: FC<Props> = ({ enabled }) => {
  /*
   * @archived
   * const history = useHistory();
   * const selectedRole = useReactiveVar(selectedRoleVar);
   */
  const { user } = useContext(AuthContext);
  const { setSelectedRole } = appMutations;

  /*
   * @archived
   * const handleTabChange = (role: any) => {
   *   setSelectedRole(role);
   *   history.push(Routes.DASHBOARD.path);
   * };
   */

  useEffect(() => {
    if (enabled && user?.roles.includes(UserRole.PrimaryAdmin)) {
      enabled && setSelectedRole(USER_TABS_PRIMARY_ADMIN[0]);
      /*
       * @archived
       * } else if (enabled && user?.roles.includes(UserRole.Member)) {
       *   enabled && setSelectedRole(USER_TABS_MANAGER[0]);
       */
    } else {
      enabled && setSelectedRole(USER_TABS_ADMIN[0]);
    }
  }, [enabled, setSelectedRole, user]);

  if (!enabled) return null;

  return (
    <DashboardType>
      {/* <UITabs>
        {(user?.roles.includes(UserRole.PrimaryAdmin)
          ? USER_TABS_PRIMARY_ADMIN
          : USER_TABS_ADMIN
        ).map((role) => (
          <UITabItem
            key={role.title}
            $active={selectedRole?.id === role.id}
            onClick={() => handleTabChange(role)}
          >
            {role.title}
          </UITabItem>
        ))}
      </UITabs> */}
    </DashboardType>
  );
};

export default NavTab;
