import { useEffect, useState, VFC } from "react";
import {
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Typography,
  Button,
  Modal,
  Checkbox,
} from "antd";
import {
  useAccountUserQuery,
  useProfileUpdateMutation,
  UserRole,
  useUpdateProfileAvatarMutation,
  useUserScopesQuery,
  useMeQuery,
  CurrentAccountUsersDocument,
  MeDocument,
} from "codegen/generated/graphql";
import { useHistory, useParams } from "react-router-dom";
import { Avatar, ButtonPrimary, Restricted, FileUpload } from "components";
import { getFormattedDate } from "utils/formatters";
import "react-phone-input-2/lib/style.css";
import { useAuthContext } from "utils/context";
import { appMutations } from "store/app";
import { DimensionChip } from "components/DimensionChip";
import ConfigureScopeModal from "../../ConfigureScopeModal";
import PrimaryAdminConfirmModal from "../../PrimaryAdminConfirmModal";
import { ApolloError } from "@apollo/client";
import { functionListForProfile, industryListForProfile } from "utils/helpers";
import { Routes } from "router/routes";
import TimeZonePicker from "components/TimeZonePicker";

const { Text, Title } = Typography;

interface MemberProfileProps {
  isManager?: boolean;
  isMeMember?: boolean;
  selectedUser: any;
  setShowProfile: any;
}

// TODO:Extract form itself and PrimaryAdmin role part of the form into separate components to decrease the size

const MemberProfile: VFC<MemberProfileProps> = ({
  isManager,
  isMeMember,
  selectedUser,
  setShowProfile,
}) => {
  const history = useHistory();
  const { firebaseUser, user } = useAuthContext();
  const [form] = Form.useForm();
  let { id } = selectedUser;
  const { id: parmaID } = useParams<{ id: string }>();
  if (!id) id = parmaID;

  const [showConfigureScopeModal, setShowConfigureScopeModal] = useState(false);
  const [isPrimaryAdminConfirmModalVisible, setShowPrimaryAdminConfirmModal] =
    useState(false);
  const [isPrimaryAdminSelected, setPrimaryAdminSelected] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<any[]>([]);
  const [configureScopeSelectedData, setConfigureScopeSelectedData] =
    useState<any>({});
  const { data, error } = useAccountUserQuery({
    variables: {
      id,
    },
  });
  const { refetch } = useMeQuery();

  const { data: userScopeData } = useUserScopesQuery({
    skip: user?.roles[0] === UserRole.Member,
  });

  const [updateProfile] = useProfileUpdateMutation();
  const [updateProfileAvatar] = useUpdateProfileAvatarMutation();

  /**
   * @description Method to update profile information
   * @param values Update profile form values
   */
  const handleOnUpdateProfile = (values: any) => {
    updateProfile({
      variables: {
        input: {
          ...values,
          function: values?.function?.trim(),
          jobTitle: values?.jobTitle?.trim(),
          officeLocation: values?.officeLocation?.trim(),
          name: values?.name?.trim(),
          surname: values?.surname?.trim(),
          roles: selectedRoles.length ? [selectedRoles[0].value] : [],
          filterScope: configureScopeSelectedData,
          subjects: values?.subjects,
          objects: values?.objects,
          possessives: values?.possessives,
          customSharableString: values?.customSharableString,
          companyName: values?.companyName,
          industry: values?.industry,
          jobRole: values?.jobRole,
          id,
        },
      },
      refetchQueries: [
        {
          query: CurrentAccountUsersDocument,
          variables: {
            input: {
              search: "",
              take: 30,
            },
          },
        },
      ],
    })
      .then((result) => {
        if (result?.data) {
          if (email !== values.email) {
            history.push(Routes.SIGN_UP_SUCCESS.path);
          } else {
            notification.success({
              type: "success",
              message: `Profile updated successfully`,
              placement: "bottomLeft",
            });
            selectedUser?.id && setShowProfile(false);
            if (isPrimaryAdminSelected && user) {
              appMutations.onAuthChange({
                user: { ...user, roles: [UserRole.Admin] },
                firebaseUser: firebaseUser,
                isLoading: false,
              });
              appMutations.setSelectedRole({
                id: UserRole.PrimaryAdmin,
                title: "Team",
              });
            }
          }
          refetch();
        }
      })
      .catch((err: ApolloError) => {
        notification.error({
          type: "warning",
          message: err.message.split("[")[0],
          placement: "bottomLeft",
        });
      });
  };

  const handleUpdateProfileImage = (src: any) => {
    setIsLoading(true);
    updateProfileAvatar({
      variables: { src },
      refetchQueries: [
        {
          query: MeDocument,
        },
      ],
    })
      .then((result) => {
        if (result?.data?.updateProfileAvatar) {
          const { avatar } = result?.data?.updateProfileAvatar;
          setAvatar(avatar);
          setIsLoading(false);
          notification.success({
            type: "success",
            message: `Profile avatar has been updated`,
            placement: "bottomLeft",
          });
          appMutations.updateProfileImage(avatar || "");
        }
      })
      .catch((err: ApolloError) => {
        notification.error({
          type: "warning",
          message: err.message.split("[")[0],
          placement: "bottomLeft",
        });
      });
  };

  const handleOnFinish = (values: typeof initialValues) => {
    const formValues = { ...values };
    if (disablePronouns) {
      formValues.subjects = "";
      formValues.objects = "";
      formValues.possessives = "";
    }

    if (email !== formValues.email) {
      Modal.confirm({
        title: "Warning!",
        content: (
          <div>
            Please be aware if you update your email address you will be
            immediately logged out and will be required to verify before signing
            in again.
          </div>
        ),
        cancelText: "Cancel",
        okText: "Continue",

        onOk() {
          handleOnUpdateProfile(formValues);
        },
      });
    } else {
      handleOnUpdateProfile(formValues);
    }
  };

  const lastLoginAt = data?.accountUser?.lastLoginAt || undefined;
  const email = data?.accountUser?.email || "";
  const profileImage = data?.accountUser?.profile?.avatar || "";
  const profile = data?.accountUser?.profile;
  const role = data?.accountUser?.roles || undefined;
  const [isLoading, setIsLoading] = useState<any>(false);
  const [avatar, setAvatar] = useState<any>(profileImage);

  const [disablePronouns, setDisablePronouns] = useState(false);

  useEffect(() => {
    if (data?.accountUser?.profile) {
      const { subjects, objects, possessives } = data.accountUser.profile;
      const hasNameOnly = [subjects, objects, possessives].every(
        (val) => val?.toLowerCase() === "name_only" || !val?.length
      );
      setDisablePronouns(hasNameOnly);
    }
  }, [data?.accountUser?.profile]);

  const onFreePlan: any = data?.accountUser?.onFreePlan;
  let userFreePlan: any = "";
  if (onFreePlan === true) {
    userFreePlan = "";
  } else {
    userFreePlan = "userFreePlan";
  }

  const userReportViewerScope = data?.accountUser?.filterScope || {};

  const initialValues = {
    ...profile,
    email,
    timeZone:
      profile?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  const functionName: any = functionListForProfile.sort((a: any, b: any) => {
    return a.value !== "Other" && a.value.localeCompare(b.value);
  });
  const renderFunctionOptions = () => {
    return functionName.map((item: any) => {
      return (
        <Select.Option value={item.value} key={item}>
          {item.label}
        </Select.Option>
      );
    });
  };

  const renderIndustryOptions = () => {
    const industryName: any = industryListForProfile.sort((a: any, b: any) => {
      return a.value !== "Other" && a.value.localeCompare(b.value);
    });

    return industryName.map((item: any) => {
      return (
        <Select.Option value={item.value} key={item}>
          {item.label}
        </Select.Option>
      );
    });
  };

  const primaryRolesList = [
    { id: 1, name: "PrimaryAdmin", value: "PRIMARY_ADMIN" },
    { id: 2, name: "Admin", value: "ADMIN" },
    { id: 4, name: "Member", value: "MEMBER" },
  ];

  const adminRolesList = [
    { id: 1, name: "Admin", value: "ADMIN" },
    { id: 3, name: "Member", value: "MEMBER" },
  ];

  const memberRolesList = [{ id: 3, name: "Member", value: "MEMBER" }];

  const isMemberRolePriorityGreater = (memberRole: any, userRole: any) => {
    if (
      memberRole === UserRole.PrimaryAdmin &&
      userRole !== UserRole.PrimaryAdmin
    ) {
      return true;
    } else if (
      memberRole === UserRole.Admin &&
      userRole !== UserRole.Admin &&
      userRole !== UserRole.PrimaryAdmin
    ) {
      return true;
    } else if (userRole === UserRole.Member) {
      return true;
    } else {
      return false;
    }
  };

  const continuePrimaryRoleSelection = () => {
    setSelectedRoles([primaryRolesList[0]]);
    setPrimaryAdminSelected(true);
    setShowPrimaryAdminConfirmModal(false);
  };

  const triggerRoleSelection = (dimension: any) => {
    if (
      dimension.value === UserRole.PrimaryAdmin &&
      role?.[0] !== UserRole.PrimaryAdmin
    ) {
      setShowPrimaryAdminConfirmModal(true);
    } else {
      setSelectedRoles([dimension]);
      setPrimaryAdminSelected(false);
    }
  };

  useEffect(() => {
    if (role && role?.length) {
      setSelectedRoles([{ value: role[0] }]);
    }
  }, [role]);

  useEffect(() => {
    if (profileImage) {
      setAvatar(profileImage);
    }
  }, [profileImage]);

  if (error || !data?.accountUser) {
    return null;
  }

  return (
    <div>
      <Col offset={1} style={{ marginLeft: selectedUser?.id ? "2rem" : "0px" }}>
        <Row style={{ marginTop: selectedUser?.id && "2rem" }}>
          {/*Avatar col*/}
          <Col span={selectedUser?.id ? 11 : 8}>
            <Row gutter={[0, 24]} style={{ height: "100%" }} align={"top"}>
              <Col span={24}>
                {isMeMember ? (
                  <div
                    style={{
                      width: 120,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <FileUpload
                      reference={firebaseUser?.uid ?? ""}
                      loading={isLoading}
                      onSuccess={(src: string) => handleUpdateProfileImage(src)}
                      component={(props: any) => (
                        <Avatar
                          alt="profilePhoto"
                          size={112}
                          src={avatar}
                          {...props}
                        />
                      )}
                    />

                    {!avatar ? (
                      <Text style={{ fontSize: 11, padding: "2px" }}>
                        Click image to edit
                      </Text>
                    ) : null}
                    <Button
                      hidden={!avatar}
                      type="link"
                      size="small"
                      onClick={() =>
                        updateProfileAvatar({ variables: { src: "" } })
                      }
                    >
                      <Text style={{ fontSize: 11 }}>Remove photo</Text>
                    </Button>
                  </div>
                ) : (
                  <Avatar alt="profilePhoto" size={112} src={avatar} />
                )}
              </Col>
              <Col
                span={24}
                style={{ display: "flex", flexDirection: "column" }}
              ></Col>
            </Row>
          </Col>
          {/*WD Status col*/}
          <Col span={selectedUser?.id ? 10 : 8} style={{ marginLeft: 20 }}>
            <Row gutter={[0, 24]} style={{ height: "100%" }} align={"bottom"}>
              <Col span={23}>
                <Col>
                  <Text strong>POP Status</Text>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    shape="round"
                    style={{
                      cursor: "default",
                      borderWidth: 0,
                      marginTop: "5px",
                      backgroundColor: data?.accountUser?.readMe[0]
                        ?.quickStartComplete
                        ? "#63AC62"
                        : "#C02C5D",
                    }}
                  >
                    {data?.accountUser?.readMe[0]?.quickStartComplete
                      ? "Complete"
                      : "Incomplete"}
                  </Button>
                </Col>
              </Col>
              <Col
                span={20}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Text strong>Last Login</Text>
                <Text type="secondary">
                  {getFormattedDate(lastLoginAt, false, true)}
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>

        {/*Form section */}
        <Row style={{ marginTop: "24px" }}>
          <Col span={selectedUser?.id ? 23 : 16}>
            <Form
              name="profile"
              layout="vertical"
              form={form}
              initialValues={initialValues}
              onFinish={(values) => handleOnFinish(values)}
            >
              <Text strong>Set your personalized URL here</Text>
              <Row>
                <Title
                  style={{
                    color: "#849BAB",
                    fontSize: 15,
                    fontWeight: "lighter",
                    position: "relative",
                    top: 13,
                    marginRight: 2,
                  }}
                >
                  {"https://pop.hellolluna.com/pop/"}
                </Title>
                <Form.Item
                  name="customSharableString"
                  rules={[
                    {
                      required: true,
                      message: "Please personalize your URL",
                    },
                    {
                      pattern: /^[a-zA-Z0-9]+$/,
                      message: "Link can only include letters and numbers.",
                    },
                  ]}
                >
                  <Input size="large" style={{ paddingLeft: 5 }} />
                </Form.Item>
              </Row>
              <Row>
                <Col span={11}>
                  <Form.Item
                    label={<Text strong>First Name</Text>}
                    name="name"
                    rules={[
                      { transform: (value) => value.trim() },
                      {
                        required: true,
                        message: "First Name can't be empty",
                      },
                      {
                        pattern: /.*\S.*/,
                        message: "First Name can't be empty",
                      },
                    ]}
                  >
                    <Input size="large" disabled={!isManager && !isMeMember} />
                  </Form.Item>
                </Col>
                <Col offset={1} span={11}>
                  <Form.Item
                    name="surname"
                    label={<Text strong>Last Name</Text>}
                    rules={[
                      { transform: (value) => value.trim() },
                      { required: true, message: "Last Name can't be empty" },
                      {
                        pattern: /.*\S.*/,
                        message: "Last Name can't be empty",
                      },
                    ]}
                  >
                    <Input size="large" disabled={!isManager && !isMeMember} />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    name="email"
                    label={<Text strong>Email</Text>}
                    rules={[
                      {
                        required: true,
                        message: "Email can't be empty",
                      },
                      {
                        pattern:
                          // eslint-disable-next-line no-useless-escape
                          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                        message: "Email can't be empty",
                      },
                    ]}
                  >
                    <Input size="large" disabled={!isManager && !isMeMember} />
                  </Form.Item>
                </Col>
                <Col offset={1} span={11}></Col>
                {/*For Team Manager*/}
                <Restricted
                  to={[UserRole.PrimaryAdmin, UserRole.Admin, UserRole.Member]}
                >
                  <Col span={6}>
                    <Form.Item label={<Text></Text>}>
                      <Checkbox
                        checked={disablePronouns}
                        onChange={(e) => setDisablePronouns(e.target.checked)}
                      >
                        Name Only
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item
                      name="subjects"
                      label={<Text strong>Subject</Text>}
                    >
                      {isManager || isMeMember ? (
                        <Select disabled={disablePronouns}>
                          <Select.Option value="She">She</Select.Option>
                          <Select.Option value="He">He</Select.Option>
                          <Select.Option value="They">They</Select.Option>
                          <Select.Option value="Ze">Ze</Select.Option>
                        </Select>
                      ) : (
                        <Input size="large" disabled />
                      )}
                    </Form.Item>
                  </Col>

                  <Col offset={1} span={4}>
                    <Form.Item
                      name="objects"
                      label={<Text strong>Object</Text>}
                    >
                      {isManager || isMeMember ? (
                        <Select disabled={disablePronouns}>
                          <Select.Option value="Her">Her</Select.Option>
                          <Select.Option value="Him">Him</Select.Option>
                          <Select.Option value="Hir">Hir</Select.Option>
                          <Select.Option value="Them">Them</Select.Option>
                        </Select>
                      ) : (
                        <Input size="large" disabled />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={4} offset={1}>
                    <Form.Item
                      name="possessives"
                      label={<Text strong>Possessive</Text>}
                    >
                      {isManager || isMeMember ? (
                        <Select disabled={disablePronouns}>
                          <Select.Option value="Hers">Hers</Select.Option>
                          <Select.Option value="His">His</Select.Option>
                          <Select.Option value="Hirs">Hirs</Select.Option>
                          <Select.Option value="Theirs">Theirs</Select.Option>
                        </Select>
                      ) : (
                        <Input size="large" disabled />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <TimeZonePicker name="timeZone" />
                  </Col>
                  <Col span={11} offset={1}>
                    <Form.Item
                      name="officeLocation"
                      label={<Text strong>Location</Text>}
                    >
                      <Input
                        size="large"
                        disabled={!isManager && !isMeMember}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      name="industry"
                      label={<Text strong>Industry</Text>}
                    >
                      <Select size="large">{renderIndustryOptions()}</Select>
                    </Form.Item>
                  </Col>
                  <Col span={11} offset={1}>
                    <Form.Item
                      name="companyName"
                      label={<Text strong>Company Name</Text>}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      name="function"
                      label={<Text strong>Department</Text>}
                    >
                      <Select size="large">{renderFunctionOptions()}</Select>
                    </Form.Item>
                  </Col>
                  <Col span={11} offset={1}>
                    <Form.Item
                      name="jobTitle"
                      label={<Text strong>Job Title</Text>}
                    >
                      <Input
                        size="large"
                        disabled={!isManager && !isMeMember}
                      />
                    </Form.Item>
                  </Col>
                </Restricted>
              </Row>
              <Restricted
                to={[UserRole.PrimaryAdmin, UserRole.Admin, UserRole.Member]}
              >
                {!userFreePlan || user?.roles[0] === UserRole.Member ? (
                  <Col></Col>
                ) : (
                  <Col span={11}>
                    <Form.Item
                      label={<Text strong>User Role</Text>}
                      name="roles"
                    >
                      <Row gutter={[16, 12]} style={{ paddingLeft: "7px" }}>
                        {(user?.roles[0] !== role?.[0] &&
                        !isMemberRolePriorityGreater(role?.[0], user?.roles[0])
                          ? user?.roles[0] === UserRole.PrimaryAdmin
                            ? primaryRolesList
                            : user?.roles[0] === UserRole.Admin
                            ? adminRolesList
                            : memberRolesList
                          : primaryRolesList.filter(
                              (item) => item.value === role?.[0]
                            )
                        ).map((dimension) => {
                          let isActive = false;
                          if (selectedRoles && selectedRoles.length) {
                            isActive = selectedRoles.some(
                              ({ value }) => value === dimension?.value
                            );
                          }
                          return (
                            <DimensionChip
                              key={dimension.id}
                              active={isActive}
                              label={`${dimension.name}`}
                              onClick={() => triggerRoleSelection(dimension)}
                            />
                          );
                        })}
                      </Row>
                    </Form.Item>
                  </Col>
                )}
              </Restricted>
              <Col
                span={23}
                style={{
                  margin: "40px 0",
                }}
              >
                <ButtonPrimary
                  htmlType="submit"
                  disabled={!isMeMember && !isManager}
                >
                  <Text strong>Save</Text>
                </ButtonPrimary>
              </Col>
            </Form>
          </Col>
        </Row>
        <ConfigureScopeModal
          isConfigureScopeModalVisible={showConfigureScopeModal}
          setShowConfigureScopeModal={setShowConfigureScopeModal}
          userScopeData={userScopeData}
          setConfigureScopeSelectedData={setConfigureScopeSelectedData}
          userReportViewerScope={userReportViewerScope}
        />
        <PrimaryAdminConfirmModal
          isPrimaryAdminConfirmModalVisible={isPrimaryAdminConfirmModalVisible}
          setShowPrimaryAdminConfirmModal={setShowPrimaryAdminConfirmModal}
          continuePrimaryRoleSelection={continuePrimaryRoleSelection}
        />
      </Col>
    </div>
  );
};

export default MemberProfile;
