import { VFC, useState, useEffect } from "react";
import {
  useAccountSubscriptionQuery,
  useCancelSubscriptionMutation,
  useCustomerPaymentMethodsQuery,
} from "codegen/generated/graphql";
import { ButtonTertiary, Wrapper } from "components";
import * as S from "../../../boards/styles";
import { Table, Typography, Space, notification } from "antd";
import { useHistory } from "react-router-dom";
import Text from "antd/es/typography/Text";
import { Routes } from "router/routes";
import theme from "theme";
import { LABEL } from "utils/constValues";
import { useColumns } from "hooks";
import { getFormattedDate } from "utils/formatters";
import { ApolloError } from "@apollo/client";
import ChangeTeamSizeModal from "pages/my-team/containers/ChangeTeamSizeModal";
import StripeModal from "pages/plan/stripeModal";
import ChangePaymentMethodModal from "pages/my-team/containers/ChangePaymentMethodModal";
import EmailModal from "pages/my-team/containers/EmailModal";
import AlertModal from "pages/boards/components/alertModal";
/*
 * @archived
 * import { AuthContext } from "utils/context/auth";
 */

const { Title } = Typography;

export const ManageSubscription: VFC = () => {
  /*
   * @archived
   *  const [getSessionUrl] = useStripeCreateCustomerPortalSessionMutation();
   * const { user } = useContext(AuthContext);
   * const id = user?.id;
   */
  const [cancelSubscription] = useCancelSubscriptionMutation();
  const { data: subsData } = useAccountSubscriptionQuery();
  const { data: paymentMethod } = useCustomerPaymentMethodsQuery();
  const paymentData = paymentMethod?.customerPaymentMethods;
  const accountSubsData = subsData?.accountSubscription;
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [planModal, setPlanModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [price, setPrice] = useState<any>();

  useEffect(() => {
    if (accountSubsData && accountSubsData?.plan) {
      switch (accountSubsData?.interval) {
        case "YEAR":
          setPrice(accountSubsData?.plan?.pricePerUnit * 12);
          break;
        case "MONTH":
          setPrice(accountSubsData?.plan?.pricePerUnit * 1);
          break;
        default:
          break;
      }
      /*
       * @archived
       * accountSubsData?.interval === "YEAR"
       *   ? setPrice(accountSubsData?.plan?.pricePerUnit * 12)
       *   : accountSubsData?.interval === "MONTH"
       *   ? setPrice(accountSubsData?.plan?.pricePerUnit * 1)
       *   : accountSubsData?.plan?.pricePerUnit;
       */
    }
  }, [accountSubsData]);

  const handleViewInvoice = (url: any) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  const columns = useColumns(
    [
      {
        title: "Issue Date",
        dataIndex: "issueDate",
        fixed: "left",
        formatter: getFormattedDate,
        sorter: false,
        render: (issueDate) => (
          <Space size={12}>
            <>
              <Text>
                {getFormattedDate(new Date(Number(issueDate)).toLocaleString())}
              </Text>
            </>
          </Space>
        ),
      },
      {
        title: "Payment",
        dataIndex: "__typename",
        sorter: false,
        render: (__typename) => (
          <Space size={12}>
            <>
              <Text>{__typename}</Text>
            </>
          </Space>
        ),
      },
      {
        title: "Price",
        dataIndex: "price",
        sorter: false,
        render: (price) => (
          <Space size={12}>
            <>
              <Text>
                $
                {(+price / 100 || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
            </>
          </Space>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        // @TODO this sort doesn't work now as workDesigns is not scalar
        sorter: false,
        render: (status) => (
          <Space size={4}>
            {
              <>
                <Text>{status.charAt(0).toUpperCase() + status.slice(1)}</Text>
              </>
            }
          </Space>
        ),
      },
      {
        dataIndex: "invoiceUrl",
        fixed: "right",
        sorter: false,
        width: "50px",
        render: (invoiceUrl, data) => (
          <>
            <ButtonTertiary
              htmlType="button"
              style={{
                borderWidth: 0,
                backgroundColor: "#ebebeb",
                borderRadius: 5,
                height: "30px",
              }}
              onClick={() => handleViewInvoice(invoiceUrl)}
              disabled={data?.status === "draft" ? true : false}
            >
              <Text style={{ marginTop: 2.5, color: "#000" }}>{"View"}</Text>
            </ButtonTertiary>
          </>
        ),
      },
    ],
    {
      sorter: true,
      Wrapper: ({ children }) => <Text type="secondary">{children}</Text>,
    }
  );

  const handleAlertModal = async (val: boolean) => {
    setAlertModal(val);
  };

  const handleCancelAlertModal = () => {
    setAlertModal(true);
  };

  const handleCancelSubscription = () => {
    cancelSubscription()
      .then((res) => {
        if (res.data) {
          setAlertModal(false);
          notification.success({
            type: "success",
            message: "Subscription cancel successfully",
            placement: "bottomLeft",
          });
          history.push(Routes.DASHBOARD.path);
          window.location.reload();
        }
      })
      .catch((err: ApolloError) => {
        notification.error({
          type: "warning",
          message: err.message.split("[")[0],
          placement: "bottomLeft",
        });
      });
  };

  const handleTeamSize = () => {
    setModalVisible(true);
  };

  const handleChangeEmail = () => {
    setEmailModal(true);
  };

  const handleTeamSizeModal = (val: any) => {
    setModalVisible(val);
  };

  const handlePaymentModal = (val: any) => {
    setShowPaymentModal(val);
  };

  const handlePlanModal = () => {
    setPlanModal(true);
  };

  const handleCheckPlan = () => {
    if (accountSubsData?.interval === "YEAR") {
      return "annual";
    } else if (accountSubsData?.interval === "MONTH") {
      return "Monthly";
    }
  };

  if (!accountSubsData) return <S.Spinner size="large" />;

  return (
    <Wrapper
      height="84.5vh"
      width="100%"
      gradientBg="linear-gradient(108.84deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.37) 101.71%);"
      radius={theme.radius.commonRadius}
    >
      <div>
        <Title
          style={{
            fontSize: 16,
            marginBottom: 10,
            fontWeight: "700",
          }}
        >
          {LABEL.PLAN_DETAIL}
        </Title>
      </div>
      <div>
        <span style={{ fontSize: 13 }}>
          - You are on{" "}
          <b>
            {accountSubsData?.plan?.plan.name} plan ({handleCheckPlan()})
          </b>
          .
          <text
            onClick={() => handlePlanModal()}
            style={{ color: "#2199e8", cursor: "pointer" }}
          >
            {" "}
            Change plan
          </text>
        </span>
      </div>
      <div>
        <span style={{ fontSize: 13 }}>
          - You have<b> {accountSubsData?.seats} seats </b>(
          {accountSubsData?.seatsTaken} allocated).
          <text
            style={{ color: "#2199e8", cursor: "pointer" }}
            onClick={() => handleTeamSize()}
          >
            {" "}
            Change team size
          </text>
        </span>
      </div>
      <div>
        <span style={{ fontSize: 13 }}>
          - Your plan will renew on{" "}
          <b> {getFormattedDate(accountSubsData?.activeTo)}</b>, at an{" "}
          {handleCheckPlan()?.toLowerCase()} cost of{" "}
          <b>
            $
            {(+price * (+accountSubsData?.seats - 2) || 0).toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
          </b>
        </span>
      </div>

      <div style={{ marginTop: "2rem" }}>
        <Title
          style={{
            fontSize: 16,
            marginBottom: 10,
            fontWeight: "700",
          }}
        >
          {LABEL.Billing_ACTION}
        </Title>
      </div>
      <div>
        <span style={{ fontSize: 13 }}>
          - Future charges will be billed to the{" "}
          {accountSubsData?.billingAction?.type}{" "}
          <b>*******{accountSubsData?.billingAction?.cardLast4digit}</b>.
          <text
            onClick={() => setShowPaymentModal(true)}
            style={{ color: "#2199e8", cursor: "pointer" }}
          >
            {" "}
            Change payment method
          </text>
        </span>
      </div>
      <div>
        <span style={{ fontSize: 13 }}>
          - Billing emails are sent to{" "}
          <b> {accountSubsData?.billingAction?.supportEmail} </b>.
          <text
            style={{ color: "#2199e8", cursor: "pointer" }}
            onClick={() => handleChangeEmail()}
          >
            {" "}
            Change email
          </text>
        </span>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <span style={{ fontSize: 13 }}>
          <text
            style={{ color: "#2199e8", cursor: "pointer" }}
            onClick={() => handleCancelAlertModal()}
          >
            {" "}
            Cancel subscription
          </text>
        </span>
      </div>
      <div>
        <span style={{ fontSize: 13 }}>
          <b>Note: </b>Do not cancel your subscription if you want to{" "}
          <text
            style={{ color: "#2199e8", cursor: "pointer" }}
            onClick={() => handleTeamSize()}
          >
            {" "}
            change team size
          </text>
          {" or"}
          <text
            style={{ color: "#2199e8", cursor: "pointer" }}
            onClick={() => setShowPaymentModal(true)}
          >
            {" "}
            change payment method
          </text>
        </span>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <div style={{ marginTop: "2rem" }}>
          <Title
            style={{
              fontSize: 16,
              marginBottom: 10,
              fontWeight: "700",
            }}
          >
            {LABEL.INVOICE}
          </Title>
        </div>
        <Table
          dataSource={
            accountSubsData?.invoices?.length ? accountSubsData?.invoices : []
          }
          columns={columns}
          size="small"
          rowKey={"id"}
          locale={{
            emptyText: (
              <Text type="secondary">
                It seems there are no Invoice who match your filters
              </Text>
            ),
          }}
        />
      </div>
      {showPaymentModal && (
        <ChangePaymentMethodModal
          isStripeModal={showPaymentModal}
          setModalVisible={(val: any) => handlePaymentModal(val)}
          subscription={false}
          paymentData={paymentData}
        />
      )}
      {modalVisible && accountSubsData && (
        <ChangeTeamSizeModal
          isStripeModal={modalVisible}
          setTeamModal={(val: any) => handleTeamSizeModal(val)}
          accountSubscription={accountSubsData}
        />
      )}
      {planModal && (
        <StripeModal
          isStripeModal={planModal}
          setPlanModal={setPlanModal}
          accountSubscription={accountSubsData}
          action={"Subscription"}
        />
      )}
      {emailModal && (
        <EmailModal
          emailModal={emailModal}
          setEmailModal={setEmailModal}
          accountSubscription={accountSubsData}
        />
      )}
      {alertModal && (
        <AlertModal
          isModalVisible={alertModal}
          setModalVisible={(val: any) => handleAlertModal(val)}
          message={LABEL.CANCEL_SUBSCRIPTION_MSG}
          dualButton={{
            show: true,
            labelText: LABEL.YES,
            cancelLabelText: LABEL.NO,
          }}
          handleButtonAction={() => handleCancelSubscription()}
        />
      )}
    </Wrapper>
  );
};

export default ManageSubscription;
