/*eslint-disable*/
import { useState } from "react";
import { Col, Form, Row, Modal, Tooltip } from "antd";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import { MaterialInput } from "../MaterialInput";
import { PasswordTooltipContent } from "../Tooltip/PasswordTooltipContent";
import { Checkbox } from "../Checkbox";
import Link from "antd/es/typography/Link";
import EXTERNAL_LINKS from "../../utils/externalLinks";
import { useHistory } from "react-router-dom";
import { validateCheckbox } from "utils/form";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { ButtonPrimary } from "components";
import { CheckInvitationQuery } from "codegen/generated/graphql";
import { ACTION } from "utils/constValues";

interface Props {
  signUpWithPassword: any;
  signUpWithProvider: any;
  /**
   * Will be rendered reduced form if we have invited user profile already
   */
  invitedUser?: CheckInvitationQuery["checkInvitation"]["user"];
}

export const InviteSignupForm: React.FC<Props> = ({
  signUpWithPassword,
  invitedUser,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { md, xs } = useBreakpoint();
  const isInvited = !!invitedUser;
  const [loading, setLoading] = useState(false);
  const [isPasswordCheck, setIsPasswordCheck] = useState(false);
  const initialData: any = {
    email: invitedUser?.email,
  };

  const verifyPassword: any = (password: any) => {
    const validatePassword = [];
    let checkValidatePassword = [];
    validatePassword.push(password?.length >= "8" ? true : false);
    validatePassword.push(password?.match(/[A-Z]/) ? true : false);
    validatePassword.push(password?.match(/[0-9]/) ? true : false);
    validatePassword.push(password?.match(/(?=.[!@#$%^&])/) ? true : false);
    checkValidatePassword = validatePassword?.filter((res: any) => {
      return res === false;
    });
    return checkValidatePassword.length > 0 ? true : false;
  };

  const passwordWatcher = Form.useWatch("password", form);

  return (
    <Form
      name={"signUpForm"}
      initialValues={initialData}
      form={form}
      autoComplete={"off"}
      onFinish={({ name, lastName, email, password }) => {
        localStorage.setItem("name", JSON.stringify(`${name}`));
        localStorage.setItem("surname", JSON.stringify(`${lastName}`));
        setLoading(true);
        signUpWithPassword(
          invitedUser?.email || email,
          password,
          ACTION.INVITE,
          name,
          lastName
        ).catch((err: Error) =>
          Modal.error({ title: err.message, onOk: () => setLoading(false) })
        );
      }}
    >
      <Row justify={md ? "start" : "center"} gutter={[0, 16]}>
        <Col>
          <Title level={1}>Sign Up</Title>
        </Col>
        {isInvited && (
          <Col span={24}>
            <Row justify={md ? "start" : "center"}>
              <Col>
                <Text type="secondary">Set up your password before log in</Text>
              </Col>
            </Row>
          </Col>
        )}
        <Col span={24}>
          <Row>
            <>
              <Col span={24}>
                <Row justify="space-between" gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Value is required",
                        },
                      ]}
                    >
                      <MaterialInput placeholder="First Name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Value is required",
                        },
                      ]}
                    >
                      <MaterialInput placeholder="Last Name" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <MaterialInput disabled={true} />
                </Form.Item>
              </Col>
            </>

            <Col span={24}>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldsValue }) => {
                  const { password } = getFieldsValue();
                  return (
                    <Tooltip
                      visible={password ? verifyPassword(password) : false}
                      color="#fff"
                      placement={xs ? "bottom" : "right"}
                      overlayInnerStyle={{
                        boxShadow: "0px 2px 4px rgba(232, 232, 233, 0.5)",
                        width: "270px",
                        padding: "15px",
                        fontSize: "13px",
                      }}
                      title={
                        <PasswordTooltipContent
                          isValidate={(val: boolean) => setIsPasswordCheck(val)}
                          isLengthValid={password?.length >= "8" ? true : false}
                          isUppercaseValid={
                            password?.match(/[A-Z]/) ? true : false
                          }
                          isStrengthValid={
                            password?.match(/[0-9]/) ? true : false
                          }
                          isSpecialValid={
                            password?.match(/(?=.[!@#$%^&])/) ? true : false
                          }
                        />
                      }
                    >
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                      >
                        <MaterialInput
                          placeholder={"Password"}
                          type="password"
                        />
                      </Form.Item>
                    </Tooltip>
                  );
                }}
              </Form.Item>
            </Col>
            <Row gutter={[0, 25]}>
              <Col span={24}>
                <Form.Item
                  name="repeatPassword"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, repeatedPassword) {
                        if (
                          !repeatedPassword ||
                          repeatedPassword === getFieldValue("password")
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <MaterialInput
                    placeholder={"Repeat Password"}
                    type="password"
                    disabled={verifyPassword(passwordWatcher)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  valuePropName="checked"
                  initialValue={false}
                  name="tos"
                  rules={[validateCheckbox("You have to agree with our TOS")]}
                >
                  <Checkbox>
                    <Text>
                      <span>I accept our </span>
                      <Link target="_blank" href={EXTERNAL_LINKS.termsAndConds}>
                        Terms &amp; Conditions
                      </Link>
                      <span>, and </span>
                      <Link target="_blank" href={EXTERNAL_LINKS.privacyPolicy}>
                        {" Privacy Policy"}
                      </Link>
                    </Text>
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={24}>
                <ButtonPrimary
                  loading={loading}
                  htmlType="submit"
                  disabled={!isPasswordCheck}
                >
                  {!isPasswordCheck ? (
                    <b style={{ fontWeight: "500" }}>Sign up</b>
                  ) : (
                    "Sign up"
                  )}
                </ButtonPrimary>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
