import { RouteType, LayoutDescriptor } from "types/route";
import { UserRole } from "codegen/generated/graphql";
import { noop } from "./utils";

import { adminRoutes } from "./routes.admin";

/*
 * @archived
 *TODO:: We need to employ a nested routes architecture to split the routes definitions
 */

// Keep in-sync with used routes in /packages/api/env.ts
type TypeOflogin = typeof import("pages/login");
type TypeOfsign_up = typeof import("pages/sign-up");
type TypeOfauth = typeof import("pages/auth");
type TypeOfforgotten_password = typeof import("pages/forgotten-password");
type TypeOfpop = typeof import("pages/pop");
type TypeOferrorpage = typeof import("pages/errorPage");
type TypeOfrequest_invitation = typeof import("pages/request-invitation");
type TypeOfsubscribe = typeof import("pages/subscribe");
type TypeOfplan = typeof import("pages/plan");
type TypeOfsign_up_success = typeof import("pages/sign-up-success");
type TypeOfdashboard = typeof import("pages/dashboard");
type TypeOfframeworks_preview = typeof import("pages/frameworks/preview");
type TypeOfmy_team_profile = typeof import("pages/my-team/profile");
type TypeOfmy_team_members_member =
  typeof import("pages/my-team/members/member");
type TypeOfmy_team_members_member_redirect =
  typeof import("pages/my-team/members/member/redirect");
type TypeOfmy_team_members = typeof import("pages/my-team/members");
type TypeOfmy_team_members_new_member =
  typeof import("pages/my-team/members/new-member");
type TypeOfboards_boardoverview = typeof import("pages/boards/boardoverview");
type TypeOfboards_boardroster = typeof import("pages/boards/boardroster");
type TypeOfframeworks_collection = typeof import("pages/frameworks/collection");
type TypeOfframeworks_features = typeof import("pages/frameworks/features");
type TypeOfframeworks_features_create_new =
  typeof import("pages/frameworks/features/create-new");
type TypeOfframeworks_features_update =
  typeof import("pages/frameworks/features/update");
type TypeOfframeworks_dimensions = typeof import("pages/frameworks/dimensions");
type TypeOfframeworks_dimensions_new_dimension =
  typeof import("pages/frameworks/dimensions/new-dimension");
type TypeOfframeworks_templates = typeof import("pages/frameworks/templates");
type TypeOfframeworks_templates_new_template =
  typeof import("pages/frameworks/templates/new-template");
type TypeOfreports = typeof import("pages/reports");
type TypeOfboards = typeof import("pages/boards");
type TypeOfreadme = typeof import("pages/readme");
type TypeOfhelp_center = typeof import("pages/help-center");
/*
 * @archived
 * type TypeOfwork_design_collection =
 *   typeof import("pages/work-design/collection");
 */
type TypeOfwork_design = typeof import("pages/work-design");
// type TypeOfwork_design_preview = typeof import("pages/work-design/preview");
type TypeOfdashboard_congratulation =
  typeof import("pages/dashboard/congratulation");
type Typeframeworks_createFromScratch_CreateFromScratch =
  typeof import("pages/frameworks/createFromScratch/CreateFromScratch");
export const PlainRoute = {
  POP: {
    path: "/pop/:id",
    component: (): Promise<TypeOfpop> => import("pages/pop"),
    type: RouteType.Plain,
  },
};
export const AuthRoutes = {
  SUBSCRIBE: {
    path: "/subscribe",
    component: (): Promise<TypeOfsubscribe> => import("pages/subscribe"),
    exact: false,
    type: RouteType.Plain,
  },
  PLANS: {
    path: "/plans",
    component: (): Promise<TypeOfplan> => import("pages/plan"),
    exact: false,
    type: RouteType.Plain,
  },
  AUTH: {
    path: "/auth",
    component: (): Promise<TypeOfauth> => import("pages/auth"),
    type: RouteType.Plain,
    exact: false,
  },
  ERROR: {
    path: "/error/:tag",
    component: (): Promise<TypeOferrorpage> => import("pages/errorPage"),
    type: RouteType.Plain,
  },
  MARKETING_WEBSITE: {
    path: "/",
    exact: true,
    component: (): Promise<TypeOflogin> => import("pages/login"),
    type: RouteType.Public,
  },
  SIGN_UP: {
    path: "/sign-up",
    component: (): Promise<TypeOfsign_up> => import("pages/sign-up"),
    type: RouteType.Public,
  },
  FORGOT_PASSWORD: {
    path: "/forgotten-password",
    component: (): Promise<TypeOfforgotten_password> =>
      import("pages/forgotten-password"),
    type: RouteType.Public,
  },
  LOGIN: {
    path: "/login",
    component: (): Promise<TypeOflogin> => import("pages/login"),
    type: RouteType.Public,
  },
  REQUEST_INVITATION: {
    path: "/request-invitation",
    component: (): Promise<TypeOfrequest_invitation> =>
      import("pages/request-invitation"),
    type: RouteType.Public,
  },
  SIGN_UP_SUCCESS: {
    path: "/sign-up-success",
    component: (): Promise<TypeOfsign_up_success> =>
      import("pages/sign-up-success"),
    type: RouteType.Private,
  },
};
export const AppRoutes = {
  DASHBOARD: {
    path: "/app/dashboard",
    component: (): Promise<TypeOfdashboard> => import("pages/dashboard"),
    type: RouteType.Private,
  },
  FRAMEWORKS: {
    path: "/frameworks",
    component: noop,
    type: RouteType.Private,
  },
  FRAMEWORK_PREVIEW: {
    path: "/frameworks/preview/:id",
    component: (): Promise<TypeOfframeworks_preview> =>
      import("pages/frameworks/preview"),
    type: RouteType.Private,
  },
  TEAM_PROFILE: {
    path: "/my-team/profile",
    component: (): Promise<TypeOfmy_team_profile> =>
      import("pages/my-team/profile"),
    type: RouteType.Private,
    exact: false,
  },
  TEAM_PROFILE_SUBSCRIPTION: {
    path: "/my-team/profile/subscriptions",
    component: noop,
    type: RouteType.Private,
  },
  MY_PROFILE_ACCOUNT: {
    path: "/my-profile/:id/account",
    component: (): Promise<TypeOfmy_team_members_member> =>
      import("pages/my-team/members/member"),
    type: RouteType.Private,
  },
  MY_PROFILE: {
    path: "/my-profile/:id",
    component: (): Promise<TypeOfmy_team_members_member> =>
      import("pages/my-team/members/member"),
    type: RouteType.Private,
  },
  MY_PROFILE_REDIRECT: {
    path: "/my-profile",
    component: (): Promise<TypeOfmy_team_members_member_redirect> =>
      import("pages/my-team/members/member/redirect"),
    exact: true,
    type: RouteType.Private,
  },
  TEAM_PROFILE_ACCOUNT: {
    path: "/my-team/profile/account",
    component: noop,
    type: RouteType.Private,
  },
  TEAM_INTEGRATIONS: {
    path: "/my-team/profile/integrations",
    component: noop,
    type: RouteType.Private,
  },
  TEAM_MEMBERS: {
    path: "/my-team/members",
    component: (): Promise<TypeOfmy_team_members> =>
      import("pages/my-team/members"),
    restrictedTo: [UserRole.PrimaryAdmin, UserRole.Admin, UserRole.LlunaAdmin],
    type: RouteType.Private,
  },
  TEAM_MEMBER_PROFILE: {
    path: "/my-team/members/profile/:id",
    component: (): Promise<TypeOfmy_team_members_member> =>
      import("pages/my-team/members/member"),
    type: RouteType.Private,
    exact: false,
  },
  TEAM_MEMBER_WORK_DESIGNS: {
    path: "/my-team/members/profile/:id/work-designs",
    component: noop,
    type: RouteType.Private,
  },
  TEAM_MEMBER_CREATE: {
    path: "/my-team/members/create",
    component: (): Promise<TypeOfmy_team_members_new_member> =>
      import("pages/my-team/members/new-member"),
    type: RouteType.Private,
  },
  BOARD_OVERVIEW: {
    path: "/boards/overview",
    component: (): Promise<TypeOfboards_boardoverview> =>
      import("pages/boards/boardoverview"),
    type: RouteType.Private,
  },
  BOARD_ROSTER: {
    path: "/boards/roster",
    component: (): Promise<TypeOfboards_boardroster> =>
      import("pages/boards/boardroster"),
    type: RouteType.Private,
  },
  FRAMEWORKS_CREATE_NEW: {
    path: "/frameworks/create-new",
    component:
      (): Promise<Typeframeworks_createFromScratch_CreateFromScratch> =>
        import("pages/frameworks/createFromScratch/CreateFromScratch"),
    type: RouteType.Private,
  },
  FRAMEWORKS_EDIT: {
    path: "/frameworks/edit/:id",
    component:
      (): Promise<Typeframeworks_createFromScratch_CreateFromScratch> =>
        import("pages/frameworks/createFromScratch/CreateFromScratch"),
    type: RouteType.Private,
  },
  FRAMEWORKS_CREATE_NEW_FROM_SCRATCH: {
    path: "/frameworks/create-new/from-scratch/:id",
    component:
      (): Promise<Typeframeworks_createFromScratch_CreateFromScratch> =>
        import("pages/frameworks/createFromScratch/CreateFromScratch"),
    type: RouteType.Private,
  },
  FRAMEWORKS_CREATE_NEW_FROM_TEMPLATE: {
    path: "/frameworks/create-new/from-template",
    component: noop,
    type: RouteType.Private,
  },
  FRAMEWORKS_CREATE_NEW_FROM_COLLECTION: {
    path: "/frameworks/create-new/from-collection",
    component: noop,
    type: RouteType.Private,
  },
  FRAMEWORKS_CREATE_NEW_FROM_ARCHIVE: {
    path: "/frameworks/create-new/from-archive",
    component: noop,
    type: RouteType.Private,
  },
  FRAMEWORKS_COLLECTION: {
    path: "/frameworks/collection",
    component: (): Promise<TypeOfframeworks_collection> =>
      import("pages/frameworks/collection"),
    type: RouteType.Private,
  },
  FRAMEWORKS_FEATURES: {
    path: "/frameworks/features",
    component: (): Promise<TypeOfframeworks_features> =>
      import("pages/frameworks/features"),
    type: RouteType.Private,
  },
  FRAMEWORKS_FEATURE_CREATE_NEW: {
    path: "/frameworks/features/create-new",
    component: (): Promise<TypeOfframeworks_features_create_new> =>
      import("pages/frameworks/features/create-new"),
    type: RouteType.Private,
  },
  FRAMEWORKS_FEATURE_UPDATE: {
    path: "/frameworks/feature/update/:id",
    component: (): Promise<TypeOfframeworks_features_update> =>
      import("pages/frameworks/features/update"),
    type: RouteType.Private,
  },
  FRAMEWORKS_NEW_FEATURE: {
    path: "/frameworks/features/new-feature",
    component: noop,
    type: RouteType.Private,
  },
  FRAMEWORKS_DIMENSIONS: {
    path: "/frameworks/dimensions",
    component: (): Promise<TypeOfframeworks_dimensions> =>
      import("pages/frameworks/dimensions"),
    type: RouteType.Private,
  },
  FRAMEWORKS_NEW_DIMENSION: {
    path: "/frameworks/dimensions/new-dimension",
    component: (): Promise<TypeOfframeworks_dimensions_new_dimension> =>
      import("pages/frameworks/dimensions/new-dimension"),
    type: RouteType.Private,
  },
  FRAMEWORKS_EDIT_DIMENSION: {
    path: "/frameworks/dimensions/edit-dimension/:id",
    component: (): Promise<TypeOfframeworks_dimensions_new_dimension> =>
      import("pages/frameworks/dimensions/new-dimension"),
    type: RouteType.Private,
  },
  FRAMEWORKS_TEMPLATES: {
    path: "/frameworks/templates",
    component: (): Promise<TypeOfframeworks_templates> =>
      import("pages/frameworks/templates"),
    type: RouteType.Private,
  },
  FRAMEWORKS_NEW_TEMPLATE: {
    path: "/frameworks/templates/new-template",
    component: (): Promise<TypeOfframeworks_templates_new_template> =>
      import("pages/frameworks/templates/new-template"),
    type: RouteType.Private,
  },
  REPORTS: {
    path: "/reports",
    component: (): Promise<TypeOfreports> => import("pages/reports"),
    restrictedTo: [UserRole.PrimaryAdmin, UserRole.Admin, UserRole.Member],
    type: RouteType.Private,
  },
  BOARDS: {
    path: "/boards",
    component: (): Promise<TypeOfboards> => import("pages/boards"),
    restrictedTo: [UserRole.PrimaryAdmin, UserRole.Admin, UserRole.Member],
    type: RouteType.Private,
  },

  README: {
    path: "/pop",
    component: (): Promise<TypeOfreadme> => import("pages/readme"),
    restrictedTo: [UserRole.PrimaryAdmin, UserRole.Admin, UserRole.Member],
    type: RouteType.Private,
  },
  HELP_CENTER: {
    path: "/help-center",
    component: (): Promise<TypeOfhelp_center> => import("pages/help-center"),
    type: RouteType.Private,
  },
  /*
   * @archived
   * WORK_DESIGNS: {
   *   path: "/work-designs",
   *   component: (): Promise<TypeOfwork_design_collection> =>
   *     import("pages/work-design/collection"),
   *   type: RouteType.Private,
   * },
   */
  WORK_DESIGN: {
    path: "/my-latest-work-design",
    component: (): Promise<TypeOfwork_design> => import("pages/work-design"),
    type: RouteType.Private,
  },
  WORK_DESIGN_PERSONALIZE: {
    path: "/my-work-design/:id",
    component: (): Promise<TypeOfwork_design> => import("pages/work-design"),
    type: RouteType.Private,
  },
  /*
   * @archived
   * WORK_DESIGN_PREVIEW: {
   *   path: "/work-design-preview/:id",
   *   component: (): Promise<TypeOfwork_design_preview> =>
   *     import("pages/work-design/preview"),
   *   type: RouteType.Private,
   * },
   */
  CONGRATULATION_MOBILE: {
    path: "/work-design/:id/congratulation",
    component: (): Promise<TypeOfdashboard_congratulation> =>
      import("pages/dashboard/congratulation"),
    type: RouteType.Private,
    exact: true,
  },
  ...adminRoutes,
};

export const Routes = { ...AppRoutes, ...AuthRoutes, ...PlainRoute };
const StaticRouter: LayoutDescriptor[] = [
  {
    layout: () => import("layout/PlainLayout"),
    routes: Object.values(PlainRoute),
  },
  {
    layout: () => import("layout/AuthLayout"),
    routes: Object.values(AuthRoutes),
  },
  {
    layout: () => import("layout/MainLayout"),
    routes: Object.values(AppRoutes),
  },
];

export default StaticRouter;
