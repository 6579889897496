import {
  useCallback,
  useMemo,
  useState,
  useContext,
  ReactElement,
} from "react";
import {
  Dropdown,
  Menu,
  Space,
  Button,
  TableProps,
  Alert,
  notification,
  Modal,
  Col,
} from "antd";
import Text from "antd/es/typography/Text";
import { CloseOutlined } from "@ant-design/icons";
import { ReactComponent as Dots } from "assets/icons/dots.svg";
import { Table, Icon, Avatar } from "components";
import { useColumns } from "hooks/table";
import { getFormattedDate } from "utils/formatters";
import {
  CurrentAccountUsersDocument,
  useInviteMemberToAccountMutation,
  useMeQuery,
  UserRole,
  useSendUpdateRemainderMutation,
  useToggleUsersMutation,
} from "codegen/generated/graphql";
import useInviteUserToBoardDialog from "../containers/InviteUserToBoard.dialog";
import { LABEL } from "utils/constValues";
import { ApolloError } from "@apollo/client";
import { AuthContext } from "utils/context/auth";
import MemberProfile from "./member/components/MemberProfile";
/*
 * @archived
 * import { useHistory } from "react-router-dom";
 */
interface UserManagementListProps<T extends any> {
  tableProps: TableProps<T>;
  data: any;
  selected: any;
  selectedItems: any;
  error: any;
  action: any;
  setAlert: any;
}

export const UserManagementList = <T extends any>({
  tableProps,
  data,
  selected,
  selectedItems,
  setAlert,
  error,
  action,
}: UserManagementListProps<T>): ReactElement => {
  const [sendUpdateRemainder] = useSendUpdateRemainderMutation();
  const [resendInvitation] = useInviteMemberToAccountMutation();
  const [showProfile, setShowProfile] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>();
  const { user } = useContext(AuthContext);

  const isMeMember = useMemo(
    () => selectedUser?.id === user?.id,
    [selectedUser, user]
  );
  const isPrimaryAdmin = user?.roles.includes(UserRole.PrimaryAdmin);
  const isAdmin = user?.roles.includes(UserRole.Admin);
  const { data: meQuery } = useMeQuery();
  //const history = useHistory();
  const [toggleUsers] = useToggleUsersMutation({
    update: (cache) => {
      // Notify Apollo cache that `accountUsers` (with any set of vars) is outdated.
      cache.evict({ fieldName: "currentAccountUsers" });
    },
  });

  const makeToggleUsers = useCallback(
    (userIds: string, isEnabled: boolean) => () => {
      toggleUsers({ variables: { userIds, isEnabled } });
    },
    [toggleUsers]
  );

  const [InviteDialog, showInviteDialog] = useInviteUserToBoardDialog();

  const handleViewProfile = (profile: any) => {
    setShowProfile(true);
    setSelectedUser(profile);
  };

  const handleInvite = (id: any) => {
    showInviteDialog({ id, selectedItems });
  };

  const handleSendReminder = (id: any) => {
    const sendInput: any = [];
    sendInput.push(id);
    const input = {
      userId: sendInput,
    };
    sendUpdateRemainder({
      variables: { input: input },
    }).then((res) => {
      if (res.data) {
      }
    });
  };

  const handleResendInvitation = async (email: any) => {
    const sendInput: any = [];
    sendInput.push(email);
    const input = {
      emails: sendInput,
    };
    resendInvitation({
      variables: { input: input },
      refetchQueries: [
        {
          query: CurrentAccountUsersDocument,
          variables: {
            input: {
              search: "",
              take: 30,
            },
          },
        },
      ],
    })
      .then((res) => {
        if (res.data) {
          notification.success({
            type: "success",
            message: "Invitation send successfully",
            placement: "bottomLeft",
          });
        }
      })
      .catch((err: ApolloError) => {
        notification.error({
          type: "warning",
          message: err.message.split("[")[0],
          placement: "bottomLeft",
        });
      });
  };

  const handleRedirect = (profile: any, readMe: any) => {
    if (
      profile.customSharableString &&
      readMe?.length > 0 &&
      readMe[0].quickStartComplete
    ) {
      window.open(
        process.env.REACT_APP_DOMAIN_URL + "/" + profile?.customSharableString
      );
    }
  };

  const columns = useColumns<T>(
    [
      {
        title: "Email",
        key: "email",
        fixed: "left",
        sorter: (a: any, b: any) => a?.email?.localeCompare(b?.email),
        render: ({ profile, isEnabled, email }) => (
          <Space size={12}>
            {profile && (
              <>
                <Text
                  disabled={!isEnabled}
                  style={{ whiteSpace: "normal", height: "auto" }}
                >
                  {email}
                </Text>
              </>
            )}
          </Space>
        ),
      },
      {
        title: "Name",
        key: "profile.name",
        fixed: "left",
        sorter: (a: any, b: any) =>
          a.profile?.name?.localeCompare(b.profile?.name),
        defaultSortOrder: "ascend",
        sortDirections: ["descend", "ascend"],
        render: ({ profile, id, isEnabled }) => (
          <Space size={12}>
            {profile && (
              <>
                <Avatar src={profile?.avatar} />
                <Text disabled={!isEnabled}>
                  {profile?.name} {profile?.surname}
                </Text>
              </>
            )}
          </Space>
        ),
      },
      {
        title: "POP Last Update",
        dataIndex: "readMe",
        formatter: getFormattedDate,
        sorter: (a: any, b: any) => a.readMe.length - b.readMe.length,
        render: (readMe) => (
          <Space size={12}>
            {readMe?.length > 0 ? (
              <>
                <Text>{getFormattedDate(readMe[0].updatedAt)}</Text>
              </>
            ) : null}
          </Space>
        ),
      },
      {
        title: "Board Memberships",
        dataIndex: "boardMember",
        sorter: (a: any, b: any) => a.boardMember.length - b.boardMember.length,
        render: (boardMember) => (
          <Space size={12}>
            {boardMember && (
              <>
                <Text>{boardMember.length}</Text>
              </>
            )}
          </Space>
        ),
      },
      {
        title: "Quick Start Status",
        dataIndex: "readMe",
        // @TODO this sort doesn't work now as workDesigns is not scalar
        sorter: true,
        render: (readMe) => (
          <Space size={12}>
            {
              <>
                <Button
                  type="primary"
                  shape="round"
                  style={{
                    cursor: "default",
                    borderWidth: 0,
                    backgroundColor:
                      readMe?.length > 0 && readMe[0].quickStartComplete
                        ? "#63AC62"
                        : "#C02C5D",
                  }}
                >
                  <Text style={{ color: "#ffffff", marginTop: 3 }}>
                    {readMe?.length > 0 && readMe[0].quickStartComplete
                      ? "Complete"
                      : "Incomplete"}
                  </Text>
                </Button>
              </>
            }
          </Space>
        ),
      },
      {
        title: "Verification Status",
        key: "isVerified",
        // @TODO this sort doesn't work now as workDesigns is not scalar
        sorter: true,
        render: ({ isVerified }) => (
          <Space size={12}>
            {
              <>
                <Button
                  type="primary"
                  shape="round"
                  style={{
                    cursor: "default",
                    borderWidth: 0,
                    backgroundColor: isVerified ? "#63AC62" : "#C02C5D",
                  }}
                >
                  <Text style={{ color: "#ffffff", marginTop: 3 }}>
                    {isVerified ? "Confirmed" : "Pending"}
                  </Text>
                </Button>
              </>
            }
          </Space>
        ),
      },
      {
        title: "",
        key: "profile",
        sorter: false,
        render: ({ profile, readMe }) => (
          <Space size={12}>
            {
              <>
                <Button
                  disabled={
                    readMe?.length > 0 && readMe[0].quickStartComplete
                      ? false
                      : true
                  }
                  onClick={() => handleRedirect(profile, readMe)}
                  style={{
                    height: 36,
                    width: "80px",
                    borderRadius: 8,
                    borderWidth: 1.8,
                    borderColor: "rgb(98, 121, 141)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 2,
                    }}
                  >
                    <span
                      style={{
                        position: "relative",
                        right: 7,
                      }}
                    >
                      <Icon fontSize={20} name="visibleGrey" />
                    </span>
                    <span
                      style={{ justifyContent: "flex-end", marginLeft: "auto" }}
                    >
                      {"POP"}
                    </span>
                  </div>
                </Button>
              </>
            }
          </Space>
        ),
      },
      {
        key: "dotsMenu",
        fixed: "right",
        sorter: false,
        width: "50px",
        render: (
          { id, isEnabled, readMe, boardMember, isVerified, email },
          profile
        ) => (
          <Dropdown
            overlay={() => (
              <Menu>
                <Menu.Item
                  key="1"
                  disabled={!isEnabled}
                  onClick={() => handleViewProfile(profile)}
                >
                  View profile
                </Menu.Item>
                <Menu.Item
                  key="2"
                  disabled={!isEnabled}
                  onClick={() => handleInvite(profile)}
                >
                  Invite to a Board
                </Menu.Item>
                {isVerified ? (
                  <Menu.Item
                    key="3"
                    disabled={
                      !isEnabled ||
                      (readMe?.length > 0 && readMe[0].quickStartComplete)
                    }
                    onClick={() => handleSendReminder(id)}
                  >
                    {LABEL.SEND_UPDATE_REMINDER}
                  </Menu.Item>
                ) : (
                  <Menu.Item
                    key="3"
                    onClick={() => handleResendInvitation(email)}
                  >
                    {LABEL.RESEND_INVITATION}
                  </Menu.Item>
                )}
                {isEnabled ? (
                  <Menu.Item
                    key="4"
                    onClick={makeToggleUsers(id, false)}
                    disabled={meQuery?.me?.id === id}
                  >
                    Disable User
                  </Menu.Item>
                ) : (
                  <Menu.Item key="4" onClick={makeToggleUsers(id, true)}>
                    Enable User
                  </Menu.Item>
                )}
              </Menu>
            )}
            trigger={["click"]}
          >
            <Button type="link" icon={<Dots />} />
          </Dropdown>
        ),
      },
    ],
    {
      sorter: true,
      Wrapper: ({ children }) => <Text type="secondary">{children}</Text>,
    }
  );

  const membersTableRenderer = useMemo(() => {
    return (
      <>
        <Modal
          maskClosable={false}
          destroyOnClose={true}
          visible={showProfile}
          footer={null}
          style={{
            overflow: "hidden",
            borderRadius: "1rem",
            padding: "0",
            position: "relative",
          }}
          onCancel={() => setShowProfile(false)}
          closable={true}
          closeIcon={<CloseOutlined />}
          width={850}
        >
          <>
            <Col>
              <Col
                span={12}
                style={{ display: "flex", marginLeft: "2rem", marginTop: 0 }}
              >
                <Text style={{ fontSize: 28, fontWeight: "bold" }}>
                  {meQuery?.me?.id === selectedUser?.id
                    ? "My Profile"
                    : "User Profile"}
                </Text>
              </Col>
            </Col>

            <MemberProfile
              isManager={isPrimaryAdmin || isAdmin}
              isMeMember={isMeMember}
              selectedUser={selectedUser}
              setShowProfile={(val: any) => setShowProfile(val)}
            />
          </>
        </Modal>
        <InviteDialog id={null} selectedItems={selectedItems} />
        {error && (
          <Alert
            message={
              action === "invite"
                ? "Please select user"
                : "You can not disable yourself."
            }
            type="error"
            showIcon
            closable
            onClose={() => setAlert(false)}
          />
        )}
        <Table
          {...tableProps}
          columns={columns}
          rowSelection={{
            hideSelectAll: true,
            getCheckboxProps: (record: any) => ({
              disabled: !record.isEnabled,
            }),
            onSelect: (val: any) => {
              selected(val);
            },
          }}
          size="small"
          rowKey={"id"}
          locale={{
            emptyText: (
              <Text type="secondary">
                It seems there are no Members who match your filters
              </Text>
            ),
          }}
        />
      </>
    );
  }, [
    InviteDialog,
    columns,
    tableProps,
    selected,
    selectedItems,
    error,
    action,
    setAlert,
    showProfile,
    selectedUser,
    isAdmin,
    isMeMember,
    isPrimaryAdmin,
    meQuery,
  ]);

  return membersTableRenderer;
};
