import React, { createContext, useContext } from "react";

import { useApolloClient } from "@apollo/client";
import { Me, FirebaseUser, InvitationState } from "store/app/types";
import { useApplicationStore, useInvitationStore } from "store/app";

import { auth } from "../firebase";

export interface AuthState {
  user: Me;
  firebaseUser: FirebaseUser;
  invitation: InvitationState;
}
export interface IAuthContext extends AuthState {
  logout: () => Promise<void>;
}

export const AuthProvider: React.FC = ({ children }) => {
  const client = useApolloClient();
  const { user, firebaseUser } = useApplicationStore();
  const invitation = useInvitationStore();

  const contextValue = React.useMemo<IAuthContext>(
    () => ({
      user,
      firebaseUser,
      invitation,
      logout: async () => {
        await auth.signOut();
        await client.clearStore();
      },
    }),
    [user, firebaseUser, invitation, client]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;

export const AuthContext = createContext<IAuthContext>({
  user: null,
  firebaseUser: null,
  invitation: null,
  logout: Promise.resolve,
});

export const useAuthContext = (): AuthState => useContext(AuthContext);
