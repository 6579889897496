import { FC, useState, useContext } from "react";
import Input from "components/Input";
import Button from "components/Button";
import theme from "theme";
import Wrapper from "components/Wrapper";
import { Col, notification, Row, Form, Tooltip } from "antd";
import { Title } from "../TeamProfile/TeamProfile.styles";
import {
  reauthenticateUser,
  auth,
  updateFirebaseUserPassword,
} from "utils/firebase";
import { EmailAuthProvider } from "firebase/auth";
import { PasswordTooltipContent } from "components";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { UserRole } from "codegen/generated/graphql";
import { AuthContext } from "utils/context/auth";

enum FORM_FIELD_NAME {
  CURRENT_PASSWORD = "currentPassword",
  NEW_PASSWORD = "newPassword",
  REPEATED_PASSWORD = "repeatedPassword",
}

export const AccountPasswordChange: FC = () => {
  const [form] = Form.useForm();
  const { xs } = useBreakpoint();
  const [isPasswordCheck, setIsPasswordCheck] = useState(false);
  const { user } = useContext(AuthContext);
  const isLlunaAdmin = user?.roles.includes(UserRole.LlunaAdmin);

  const handleSubmit = async (
    currentPassword: string,
    newPassword: string,
    repeatedNewPassword: string
  ) => {
    if (newPassword !== repeatedNewPassword) {
      notification.error({
        type: "error",
        message: "New password and repeat new password are not matching",
        placement: "bottomLeft",
      });

      return null;
    }

    const firebaseUser = auth.currentUser;

    if (firebaseUser?.email) {
      const credential = EmailAuthProvider.credential(
        firebaseUser.email,
        currentPassword
      );

      try {
        await reauthenticateUser(firebaseUser, credential);
      } catch (e) {
        notification.error({
          type: "error",
          message: "Couldn't re-authenticate, please try again.",
          placement: "bottomLeft",
        });
      }
      updateFirebaseUserPassword(firebaseUser, newPassword)
        .then(() => {
          form.resetFields();
          notification.success({
            type: "success",
            message: "Password changed",
            placement: "bottomLeft",
          });
        })
        .catch((e: any) => {
          notification.error({
            type: "error",
            message: (
              <>
                {e.message.substring(0, 9) === "Firebase:"
                  ? e.message.slice(10, -1)
                  : e.message}
              </>
            ),
            placement: "bottomLeft",
          });
        });
    }
  };

  const verifyPassword: any = (password: any) => {
    const validatePassword = [];
    let checkValidatePassword = [];
    validatePassword.push(password?.length >= "8" ? true : false);
    validatePassword.push(password?.match(/[A-Z]/) ? true : false);
    validatePassword.push(password?.match(/[0-9]/) ? true : false);
    validatePassword.push(password?.match(/(?=.[!@#$%^&])/) ? true : false);
    checkValidatePassword = validatePassword?.filter((res: any) => {
      return res === false;
    });
    return checkValidatePassword.length > 0 ? true : false;
  };

  const passwordWatcher = Form.useWatch("newPassword", form);

  return (
    <Form
      name={"accountPasswordChangeForm"}
      form={form}
      autoComplete={"off"}
      onFinish={({
        currentPassword,
        newPassword,
        repeatedPassword,
      }: {
        [FORM_FIELD_NAME.CURRENT_PASSWORD]: string;
        [FORM_FIELD_NAME.NEW_PASSWORD]: string;
        [FORM_FIELD_NAME.REPEATED_PASSWORD]: string;
      }) => {
        handleSubmit(currentPassword, newPassword, repeatedPassword);
      }}
    >
      <Title level={4} margin="3rem 0 1rem 0">
        Update Password
      </Title>
      <Wrapper width="50%">
        <Form.Item
          name={FORM_FIELD_NAME.CURRENT_PASSWORD}
          rules={[
            {
              required: true,
              message: "Value is required",
            },
          ]}
        >
          <Input
            label="Current Password"
            name="currentPassword"
            type="password"
            withIcon
            disabled={isLlunaAdmin}
          />
        </Form.Item>
      </Wrapper>
      <Row gutter={[10, 0]}>
        <Col span={12}>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsValue }) => {
              const { newPassword } = getFieldsValue();
              return (
                <Tooltip
                  // visible={!!newPassword && !xs}
                  visible={newPassword ? verifyPassword(newPassword) : false}
                  color="#f5f5f5"
                  placement={"right"}
                  overlayInnerStyle={{
                    boxShadow: "0px 2px 4px rgba(232, 232, 233, 0.5)",
                    width: xs ? "150px" : "230px",
                    padding: "5px",
                    fontSize: xs ? "8px" : "13px",
                  }}
                  trigger={"click"}
                  title={
                    <PasswordTooltipContent
                      isValidate={(val: boolean) => setIsPasswordCheck(val)}
                      isLengthValid={newPassword?.length >= "8" ? true : false}
                      isUppercaseValid={
                        newPassword?.match(/[A-Z]/) ? true : false
                      }
                      isStrengthValid={
                        newPassword?.match(/[0-9]/) ? true : false
                      }
                      isSpecialValid={
                        newPassword?.match(/(?=.[!@#$%^&])/) ? true : false
                      }
                      xs={xs}
                    />
                  }
                >
                  <Form.Item
                    name={FORM_FIELD_NAME.NEW_PASSWORD}
                    rules={[
                      {
                        required: true,
                        message: "Value is required",
                      },
                    ]}
                  >
                    <Input
                      label="New Password"
                      name="newPassword"
                      type="password"
                      withIcon
                      disabled={isLlunaAdmin}
                    />
                  </Form.Item>
                </Tooltip>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <Col span={12}>
        <Form.Item
          name={"repeatedPassword"}
          rules={[
            {
              required: true,
              message: "Value is required",
            },
          ]}
        >
          <Input
            label="Repeat Password"
            name="repeatedPassword"
            type="password"
            // disabled={verifyPassword(passwordWatcher)}
            withIcon
            disabled={isLlunaAdmin || verifyPassword(passwordWatcher)}
          />
        </Form.Item>
      </Col>
      <Form.Item shouldUpdate>
        {() => (
          <Button
            $width="100%"
            $bgColor={theme.colors.blue_100}
            $color="white"
            $padding="0.6rem 0 1.9rem 0"
            htmlType="submit"
            disabled={
              !form.isFieldsTouched() ||
              !!form.getFieldsError().filter(({ errors }) => errors.length)
                .length ||
              form.getFieldValue(FORM_FIELD_NAME.CURRENT_PASSWORD) ===
                undefined ||
              form.getFieldValue(FORM_FIELD_NAME.NEW_PASSWORD) === undefined ||
              form.getFieldValue(FORM_FIELD_NAME.REPEATED_PASSWORD) ===
                undefined ||
              !isPasswordCheck
            }
          >
            <strong>Save Changes</strong>
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};
