import { Modal, Space, Col, Form, Input, Row, Select } from "antd";
import moment from "moment-timezone";
import { ButtonTertiary } from "components";
import { ImgSvg } from "components/ImgSvg";
import Text from "antd/lib/typography/Text";
import close from "assets/icons/close.svg";
import tickmarkYellow from "assets/icons/tickmarkYellow.svg";
import { ReactElement } from "react";

const ConfigureScopeModal = ({
  isConfigureScopeModalVisible,
  setShowConfigureScopeModal,
  userScopeData,
  setConfigureScopeSelectedData,
  userReportViewerScope,
}: {
  isConfigureScopeModalVisible: boolean;
  setShowConfigureScopeModal: (visible: boolean) => void;
  userScopeData: any;
  setConfigureScopeSelectedData: (data: any) => void;
  userReportViewerScope?: any;
}): ReactElement => {
  const [form] = Form.useForm();

  const jobTitles = userScopeData?.userScopes?.jobTitles || undefined;
  const offices = userScopeData?.userScopes?.offices || undefined;
  const employmentTypes =
    userScopeData?.userScopes?.employmentTypes || undefined;
  const functions = userScopeData?.userScopes?.functions || undefined;
  const genders = userScopeData?.userScopes?.genders || undefined;
  const raceEthinicities =
    userScopeData?.userScopes?.raceEthinicities || undefined;

  const hireDateValue = userReportViewerScope?.hireDate?.value
    ? moment(userReportViewerScope?.hireDate?.value)
    : undefined;

  const initialValues = {
    ...userReportViewerScope,
    hireDate: {
      operator: userReportViewerScope?.hireDate?.operator,
      value: hireDateValue,
    },
  };

  const renderJobTitleOptions = () => {
    return jobTitles?.map((jobTitle: string) => {
      return (
        <Select.Option value={jobTitle} key={jobTitle}>
          {jobTitle}
        </Select.Option>
      );
    });
  };
  const renderOfficeOptions = () => {
    return offices?.map((office: string) => {
      return (
        <Select.Option value={office} key={office} placeholder="Select office">
          {office}
        </Select.Option>
      );
    });
  };
  const renderEmploymentTypesOptions = () => {
    return employmentTypes?.map((employmentType: string) => {
      return (
        <Select.Option
          value={employmentType}
          key={employmentType}
          placeholder="Select employment type"
        >
          {employmentType}
        </Select.Option>
      );
    });
  };

  const renderFunctionsOptions = () => {
    return functions?.map((functionItem: string) => {
      return (
        <Select.Option
          value={functionItem}
          key={functionItem}
          placeholder="Select function"
        >
          {functionItem}
        </Select.Option>
      );
    });
  };
  const renderGendersOptions = () => {
    return genders?.map((gender: string) => {
      return (
        <Select.Option value={gender} key={gender} placeholder="Select gender">
          {gender}
        </Select.Option>
      );
    });
  };
  const renderRaceEthinicitiesOptions = () => {
    return raceEthinicities?.map((race: string) => {
      return (
        <Select.Option value={race} key={race} placeholder="Select race">
          {race}
        </Select.Option>
      );
    });
  };

  const handleOnSave = (values: any) => {
    const filterScope = {
      employmentTypes:
        typeof values?.employmentTypes === "object"
          ? values?.employmentTypes
          : values?.employmentTypes
          ? [values?.employmentTypes]
          : [],
      functions:
        typeof values?.functions === "object"
          ? values?.functions
          : values?.functions
          ? [values?.functions]
          : [],
      genders:
        typeof values?.genders === "object"
          ? values?.genders
          : values?.genders
          ? [values?.genders]
          : [],
      jobTitles:
        typeof values?.jobTitles === "object"
          ? values?.jobTitles
          : values?.jobTitles
          ? [values?.jobTitles]
          : [],
      offices:
        typeof values?.offices === "object"
          ? values?.offices
          : values?.offices
          ? [values.offices]
          : [],
      raceEthinicities:
        typeof values?.raceEthinicities === "object"
          ? values?.raceEthinicities
          : values?.raceEthinicities
          ? [values.raceEthinicities]
          : [],
      salary: values?.salary ? values.salary : undefined,
      hireDate: values?.hireDate ? values.hireDate : undefined,
    };
    setConfigureScopeSelectedData(filterScope);
    setShowConfigureScopeModal(false);
  };

  return (
    <Modal
      visible={isConfigureScopeModalVisible}
      footer={null}
      style={{
        overflow: "hidden",
        borderRadius: "7px",
        textAlign: "center",
        padding: 0,
      }}
      centered
      closable={false}
      width={700}
    >
      <div style={{ textAlign: "left" }}>Configure Scope</div>
      <Row style={{ marginTop: "24px" }}>
        <Col span={24}>
          <Form
            name="profile"
            layout="vertical"
            form={form}
            onFinish={(values) => handleOnSave(values)}
            initialValues={initialValues}
          >
            <Row>
              <Col span={11}>
                <Form.Item
                  label={<Text strong>Job Title</Text>}
                  name="jobTitles"
                >
                  <Select placeholder="Select job title" allowClear>
                    {renderJobTitleOptions()}
                  </Select>
                </Form.Item>
              </Col>
              <Col offset={1} span={11}>
                <Form.Item label={<Text strong>Office</Text>} name="offices">
                  <Select placeholder="Select office" allowClear>
                    {renderOfficeOptions()}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.Item
                  label={<Text strong>Employment Type</Text>}
                  name="employmentTypes"
                >
                  <Select placeholder="Select employment type" allowClear>
                    {renderEmploymentTypesOptions()}
                  </Select>
                </Form.Item>
              </Col>
              <Col offset={1} span={11}>
                <Form.Item
                  label={<Text strong>Function</Text>}
                  name="functions"
                >
                  <Select placeholder="Select function" allowClear>
                    {renderFunctionsOptions()}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.Item label={<Text strong>Gender</Text>} name="genders">
                  <Select placeholder="Select gender" allowClear>
                    {renderGendersOptions()}
                  </Select>
                </Form.Item>
              </Col>
              <Col offset={1} span={11}>
                <Form.Item
                  label={<Text strong>Race/ethnicity</Text>}
                  name="raceEthinicities"
                >
                  <Select placeholder="Select race" allowClear>
                    {renderRaceEthinicitiesOptions()}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Row>
                  <Form.Item label={<Text strong>Salary</Text>}>
                    <Input.Group style={{ display: "flex" }}>
                      <Space>
                        <Form.Item name={["salary", "operator"]} noStyle>
                          <Select placeholder="Select">
                            <Select.Option value="LESS_THAN" key="1">
                              Less than
                            </Select.Option>
                            <Select.Option value="GREATER_THAN" key="2">
                              Greater than
                            </Select.Option>
                            <Select.Option value="EQUALS_TO" key="3">
                              Equals To
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name={["salary", "value"]} noStyle>
                          <Input
                            placeholder="Input salary"
                            type="number"
                            min={0}
                          />
                        </Form.Item>
                      </Space>
                    </Input.Group>
                  </Form.Item>
                </Row>
              </Col>
              <Col offset={1} span={11}>
                <Row>
                  {/* <Form.Item label={<Text strong>Hire date</Text>}>
                    <Input.Group style={{ display: "flex" }}>
                      <Space>
                        <Form.Item name={["hireDate", "operator"]} noStyle>
                          <Select placeholder="Select">
                            <Select.Option value="LESS_THAN" key="1">
                              Less than
                            </Select.Option>
                            <Select.Option value="GREATER_THAN" key="2">
                              Greater than
                            </Select.Option>
                            <Select.Option value="EQUALS_TO" key="3">
                              Equals To
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name={["hireDate", "value"]} noStyle>
                          <DatePicker
                            style={{ width: "100%" }}
                            placeholder="Pick a date"
                          />
                        </Form.Item>
                      </Space>
                    </Input.Group>
                  </Form.Item> */}
                </Row>
              </Col>
            </Row>

            <Space style={{ width: "100%", marginTop: "15px" }}>
              <ButtonTertiary htmlType="submit">
                <ImgSvg
                  src={tickmarkYellow}
                  alt="Save"
                  style={{ marginRight: 10, marginLeft: 10 }}
                />
                <Text strong>Save</Text>
              </ButtonTertiary>
              <ButtonTertiary
                outlined
                transparent
                onClick={() => setShowConfigureScopeModal(false)}
              >
                <ImgSvg
                  src={close}
                  alt="Back"
                  style={{ marginRight: 10, marginLeft: 10 }}
                />
                <Text strong>Close</Text>
              </ButtonTertiary>
            </Space>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default ConfigureScopeModal;
