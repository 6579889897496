import styled from "styled-components";
import { Icon, RadioGroup as RadioGroupBase } from "components";
import TabsBar from "components/TabsBar";

export const RadioGroup = styled(RadioGroupBase)`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 0.5rem;
  }
`;

export const ChoiceWrap = styled(RadioGroupBase)`
  border-bottom: 1px solid #e1e5ea;
  padding: 0 0 10px 10px;
  margin-top: 5px;
`;

export const ChoiceText = styled.span`
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.grey_60};
`;

export const Placeholder = styled.span`
  position: relative;
  /* top: 30px; */
  /* left: 1rem; */
  color: ${({ theme }) => theme.colors.blue_100};
  background-color: ${({ theme }) => theme.colors.blue_10};
  z-index: 10;
  font-size: 0.9em;
  width: fit-content;
  font-weight: lighter;
  border: 1px solid #dce8f0;
  border-radius: 3px;
  padding: 0 5px;
  white-space: nowrap;
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: linear-gradient(
    109.04deg,
    rgba(255, 255, 255, 0.63) -23.19%,
    rgba(255, 255, 255, 0.09) 101.71%
  );
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  box-shadow: 0px 11px 18px -5px rgba(1, 8, 58, 0.04);
  backdrop-filter: blur(32px);
  border-radius: 20px;

  padding: 10px 24px 10px 24px;
  height: 100%;
  overflow-y: auto;
`;

export const TabBar = styled(TabsBar)`
  & {
    .ant-tabs-content {
      display: flex;
      flex: 1;
      height: 100%;
      overflow-y: auto;
    }
  }
`;

export const DeleteChoiceIcon = styled(Icon)`
  float: right;
  position: relative;
  top: 35px;
  left: 20px;
  z-index: 10;
  cursor: pointer;
`;

export const PlaceholderInfo = styled.div`
  color: ${({ theme }) => theme.colors.grey_70};
  margin-top: 25px;
  width: 80%;
`;

export const SidebarTitle = styled.text`
  color: ${({ theme }) => theme.colors.blue_100};
  font-size: 0.9em;
`;
