import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Space, Typography, message, Tooltip } from "antd";
import theme from "theme";
import { DashboardCard } from "../DashboardCard";
//import { ButtonTertiary } from "components";
import {
  useReadMeForShareQuery,
  useGetReadMeQuery,
  useDashboardTeamMemberQuery,
} from "codegen/generated/graphql";
import ShareReadmeModal from "pages/readme/shareReadmeModal";
import { AuthContext } from "utils/context/auth";
import { SettingFilled, FormOutlined } from "@ant-design/icons";
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share";
import {
  SocialButton,
  LinkedInIcon,
  TwitterIcon,
  FacebookIcon,
  MailIcon,
  LinkIcon,
} from "./styles";
const { Title, Text } = Typography;
const DISABLED_TOOLTIP_TITLE =
  "You must complete the quickstart questions before sharing your POP.";

export const ShareMyPOP: React.FC = () => {
  const { data: readmeForShareData } = useReadMeForShareQuery();
  const { data: shareAbleLink } = useDashboardTeamMemberQuery();
  const sharePopLink: any =
    process.env.REACT_APP_DOMAIN_URL +
    "/" +
    shareAbleLink?.me?.profile?.customSharableString;
  const readMeLink = {
    id: readmeForShareData?.readMeForShare?.id,
    link: shareAbleLink?.me?.profile?.customSharableString,
  };

  //const id: any = readmeForShareData?.readMeForShare?.id;
  const [isShareReadmeModal, setShowReadMeModal] = useState(false);
  const { user } = useContext(AuthContext);
  const { data: readmeData } = useGetReadMeQuery({
    variables: {
      userId: user?.id || "",
    },
  });
  const hasQuickStart = !!readmeData?.readMe?.quickStartComplete;
  //const hasQuickStart = false;
  const handleCopy = () => {
    navigator.clipboard.writeText(sharePopLink);
    message.info("Link copied");
  };

  /*
   * @archived
   * const handleReadMeModal = (val: boolean) => {
   *   setShowReadMeModal(val);
   * };
   */

  return (
    <DashboardCard title="" style={{ background: theme.colors.blue_20 }}>
      <Space size={20} direction="vertical" />
      <Space size={10} align="center" style={{ marginBottom: "15px" }}>
        <SettingFilled />
        <Title style={{ fontSize: 20 }}>Sharing my POP</Title>
      </Space>
      <Space size={20} direction="horizontal" />
      <Title style={{ fontSize: 14 }}>My POP view count:</Title>
      <Text style={{ fontSize: 20 }}>
        {!readmeData?.readMe?.views ? 0 : readmeData?.readMe?.views}
      </Text>
      <Title style={{ fontSize: 14 }}>My POP url:</Title>
      <Space size={10} align="center">
        <Link
          to={{
            pathname: `/my-profile/${user?.id}`,
          }}
        >
          <FormOutlined />
        </Link>
        <Text className="prevent-select" style={{ fontSize: 12 }}>
          {sharePopLink}
        </Text>
      </Space>
      <Space
        size={10}
        align="center"
        style={{
          marginTop: "15px",
          marginBottom: "15px",
          justifyContent: "space-evenly",
        }}
      >
        <SocialButton className={hasQuickStart ? "" : "disabled"}>
          <Tooltip
            title={hasQuickStart ? "Share on Twitter" : DISABLED_TOOLTIP_TITLE}
          >
            <TwitterShareButton
              url={sharePopLink}
              disabled={hasQuickStart ? false : true}
            >
              <TwitterIcon />
            </TwitterShareButton>
          </Tooltip>
        </SocialButton>
        <SocialButton className={hasQuickStart ? "" : "disabled"}>
          <Tooltip
            title={hasQuickStart ? "Share on Facebook" : DISABLED_TOOLTIP_TITLE}
          >
            <FacebookShareButton
              url={sharePopLink}
              disabled={hasQuickStart ? false : true}
            >
              <FacebookIcon />
            </FacebookShareButton>
          </Tooltip>
        </SocialButton>
        <SocialButton className={hasQuickStart ? "" : "disabled"}>
          <Tooltip
            title={hasQuickStart ? "Share on LinkedIn" : DISABLED_TOOLTIP_TITLE}
          >
            <LinkedinShareButton
              url={sharePopLink}
              disabled={hasQuickStart ? false : true}
            >
              <LinkedInIcon style={{ fontSize: "20px" }} />
            </LinkedinShareButton>
          </Tooltip>
        </SocialButton>
        <Tooltip title={hasQuickStart ? "Copy Link" : DISABLED_TOOLTIP_TITLE}>
          <SocialButton
            className={hasQuickStart ? "" : "disabled"}
            onClick={() => (hasQuickStart ? handleCopy() : null)}
          >
            <LinkIcon disabled={hasQuickStart ? false : true} />
          </SocialButton>
        </Tooltip>

        <Tooltip title={hasQuickStart ? "Send Email" : DISABLED_TOOLTIP_TITLE}>
          <SocialButton
            className={hasQuickStart ? "" : "disabled"}
            onClick={() => (hasQuickStart ? setShowReadMeModal(true) : null)}
          >
            <MailIcon disabled={hasQuickStart ? false : true} />
          </SocialButton>
        </Tooltip>
      </Space>
      {isShareReadmeModal && (
        <ShareReadmeModal
          isShareReadmeModal={isShareReadmeModal}
          setShowReadMeModal={setShowReadMeModal}
          readMeLink={readMeLink}
          content={true}
          userData={user}
        />
      )}
    </DashboardCard>
  );
};
