import styled from "styled-components";
import { Typography, Input } from "antd";
const { Text: AText } = Typography;
const { Search } = Input;
export const Text = styled(AText).attrs<{ alt?: boolean }>(
  ({ alt, theme }) => ({
    color: alt ? theme.colors.grey : theme.colors.blue_100,
  })
)<{ alt?: boolean }>`
  &.ant-typography {
    color: ${(props: any) => props.color};
    font-size: 15px;
    line-height: 21.5px;
  }
`;

export const ConfirmationWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  gap: 2rem;
`;

export const CouponText = styled.span`
  cursor: pointer;
  :hover {
    color: blue;
  }
`;

export const couponSearch = styled(Search)`
  &.ant-btn-primary {
    background-color: white;
    color: black;
    border-color: none;
  }
`;
