import { Col, Row, Form, Space } from "antd";
import React from "react";
import { Checkbox } from "./styles";
/*
 * @archived
 * import {
 *   Checkbox as cCheckbox
 * } from "components";
 */
import { RadioOption } from "../../common/types";
import { chunk } from "lodash";
import * as S from "./styles";
import { Placeholder } from "..";
import Text from "antd/lib/typography/Text";

type Props = {
  options: RadioOption[];
  hasTwoColumnLayout?: boolean;
  formFieldName: string;
  select?: boolean;
};

const getHalvedArray = (arr: RadioOption[]) => {
  const half = Math.ceil(arr.length / 2);
  return chunk(arr, half);
};

export const CheckboxWithBackground: React.FC<Props> = (props) => {
  const { hasTwoColumnLayout = false, options, formFieldName, select } = props;
  const [leftColumnOptions, rightColumnOptions] = getHalvedArray(options);
  return (
    <Form.Item name={formFieldName} noStyle>
      <Checkbox.Group style={{ width: "100%" }}>
        <Row gutter={[24, 0]}>
          {!hasTwoColumnLayout ? (
            options?.map(({ value, label, placeholder }, index) => (
              <Col span={24} key={index}>
                <Checkbox value={value} defaultChecked={select}>
                  <Space size={10}>
                    {placeholder?.value && (
                      <Placeholder
                        value={placeholder.value}
                        text={placeholder.text}
                      />
                    )}
                    <Text type="secondary">{label}</Text>
                  </Space>
                </Checkbox>
              </Col>
            ))
          ) : (
            <>
              {leftColumnOptions.map(({ value, label }, index) => (
                <Col span={12} key={index}>
                  <S.Checkbox value={value}>{label}</S.Checkbox>
                </Col>
              ))}
              {rightColumnOptions.map(({ value, label }, index) => (
                <Col span={12} key={index}>
                  <S.Checkbox value={value}>{label}</S.Checkbox>
                </Col>
              ))}
            </>
          )}
        </Row>
      </Checkbox.Group>
    </Form.Item>
  );
};
