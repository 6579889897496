import { FirebaseOptions, initializeApp } from "firebase/app";
import {
  initializeAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  browserLocalPersistence,
  browserPopupRedirectResolver,
  createUserWithEmailAndPassword,
  User,
  confirmPasswordReset,
  applyActionCode,
  reauthenticateWithCredential,
  AuthCredential,
  updatePassword,
  updateProfile,
  AuthError,
  UserCredential,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import { getStorage } from "firebase/storage";
import { UpdateLastLoggedInDocument } from "codegen/generated/graphql";
import { client } from "apolloClient";
enum Env {
  STAGING = "readmestaging",
  DEMO = "demo",
  PRODUCTION = "production",
}
const configs = {
  [Env.STAGING]: {
    apiKey: "AIzaSyD6gqvg6irqG5rb71azeF31Ea8XiFFaixI",
    authDomain: "readme-staging.hellolluna.com",
    projectId: "lluna-readme-staging",
    storageBucket: "lluna-readme-staging.appspot.com",
  },
  [Env.PRODUCTION]: {
    apiKey: "AIzaSyCtfzNGDTyeSj6oKWuj3r0FnEThr5Eyfu0",
    authDomain: "pop.hellolluna.com",
    projectId: "lluna-readme-production",
    storageBucket: "lluna-readme-production.appspot.com",
  },
} as Record<Env, FirebaseOptions>;
const firebaseConfig =
  configs[(process.env.REACT_APP_ENV as Env) || Env.STAGING];
const app = initializeApp(firebaseConfig);
const auth = initializeAuth(app, {
  persistence: browserLocalPersistence,
  popupRedirectResolver: browserPopupRedirectResolver,
});
const storage = getStorage(app);
const authProviders = {
  google: new GoogleAuthProvider(),
  microsoft: new OAuthProvider("microsoft.com"),
  linkedin: new OAuthProvider("linkedin.com"),
};
export type AuthProviderId = keyof typeof authProviders;
const AuthErrorMessages: Record<string, string> = {
  default: "Authentication failed",
  "auth/email-already-in-use": "You have already registered. Please log in",
  "auth/operation-not-allowed": "Operation not allowed",
  "auth/weak-password": "Weak password",
  "auth/invalid-email": "Invalid email address",
  "auth/user-disabled": "User banned",
  "auth/user-not-found": "User not found",
  "auth/wrong-password": "Incorrect password",
};
const CustomErrorCode = "custom";
const DifferentCredentialCode = "auth/account-exists-with-different-credential";
export const updateLastLoggedIn = (user: UserCredential): Promise<any> =>
  client
    .mutate({
      mutation: UpdateLastLoggedInDocument,
    })
    .then(() => user);
export const getAuthErrorMessage = (error: AuthError): string => {
  const { code, message, customData } = error;
  console.error(`Auth error [${code}] ${message}`, customData);
  return code === CustomErrorCode
    ? message
    : AuthErrorMessages[code] ||
        `${AuthErrorMessages.default}: ${code
          .replace(/.*\//g, "")
          .replace(/-/g, " ")}`;
};
export const authError = (message: string, data?: Partial<AuthError>): any =>
  Object.assign(new Error(message), {
    ...data,
    code: CustomErrorCode,
    message,
  });
/*
 * @archived
 * Handle sign-in to accounts with different credentials
 * https://firebase.google.com/docs/auth/web/microsoft-oauth#expandable-1
 */
export const linkDifferentCredential = async (
  error: AuthError
): Promise<any> => {
  if (error.code !== DifferentCredentialCode) throw error;
  const { email = "" } = error.customData || {};
  const methods = await fetchSignInMethodsForEmail(auth, email).catch(logError);
  // TODO: Implement sign-in with existing provider and linking new credential
  const providers = methods?.join(", or ") || "a different provider";
  throw authError(`Please log in with ${providers}`, error);
};
const logError = (error: Error) => console.error(error);
const logOperation = (operation: string) => (userCredentials: any) => {
  const { user, providerId, _tokenResponse } = userCredentials;
  console.log(
    `User ${operation} with ${providerId || "email"} as ${user.email} [${
      user.uid
    }]${user.emailVerified ? "" : " (not verified)"}`,
    userCredentials,
    _tokenResponse
  );

  if (_tokenResponse) {
    if (_tokenResponse.firstName) {
      localStorage.setItem(
        "name",
        JSON.stringify(`${_tokenResponse.firstName}`)
      );
    }
    if (_tokenResponse.lastName) {
      localStorage.setItem(
        "surname",
        JSON.stringify(`${_tokenResponse.lastName}`)
      );
    }
  }
  return userCredentials;
};
export const logSignIn = logOperation("signed in");
export const logSignUp = logOperation("signed up");
export const signInWithProvider = async (
  providerId: AuthProviderId,
  invitedUserEmail = ""
): Promise<UserCredential> =>
  signInWithPopup(
    auth,
    authProviders[providerId].setCustomParameters({
      login_hint: invitedUserEmail,
    })
  )
    .then(logSignIn)
    .catch(linkDifferentCredential);
// .then(updateLastLoggedIn);

export const signInWithPassword = (
  email: string,
  password: string
): Promise<any> =>
  signInWithEmailAndPassword(auth, email, password)
    .then(logSignIn)
    .then(updateLastLoggedIn);

export const signUp = (
  email: string,
  password: string,
  name?: string
): Promise<any> =>
  createUserWithEmailAndPassword(auth, email, password)
    .then(logSignUp)
    .then(async (userCredentials) => {
      if (name)
        await updateProfile(userCredentials.user, { displayName: name });
      return userCredentials;
    });

/*
 * @archived
 *.then(updateLastLoggedIn);
 * TODO: Remove, done on backend now
 * export const sendVerificationLink = (user: User) => sendEmailVerification(user);
 *
 * export const sendPasswordResetLink = (email: string) =>
 *   sendPasswordResetEmail(auth, email, {
 *     url: window.location.origin + "/reset-password?email=" + email,
 *   });
 */
export const reauthenticateUser = (
  user: User,
  credential: AuthCredential
): any => reauthenticateWithCredential(user, credential);
export const updateFirebaseUserPassword = (user: User, password: string): any =>
  updatePassword(user, password);
export const confirmPasswordResetLink = (
  oobCode: string,
  newPassword: string
): any => confirmPasswordReset(auth, oobCode, newPassword);
export const confirmEmailVerification = (oobCode: string): any =>
  applyActionCode(auth, oobCode);
export type { UserCredential } from "firebase/auth";
export { auth, storage };

export const newSignUp = (
  email: string,
  password: string,
  name?: string
): any => {
  const response = createUserWithEmailAndPassword(auth, email, password);
  return response;
};

export const newSignInWithProvider = async (
  providerId: AuthProviderId,
  invitedUserEmail = ""
): Promise<UserCredential> => {
  const response = signInWithPopup(
    auth,
    authProviders[providerId].setCustomParameters({
      login_hint: invitedUserEmail,
    })
  );
  return response;
};

export const newSignInWithPassword = (
  email: string,
  password: string
): Promise<any> => {
  const response = signInWithEmailAndPassword(auth, email, password);
  return response;
};

export const authCookieKey = "pop-auth";
