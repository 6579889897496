import { Routes } from "router/routes";
import { DASHBOARD, menuItem } from "./common";

const FRAMEWORKS_ADMIN = menuItem({
  key: "frameworks",
  title: "Frameworks",
  activeIcon: "eclipseYellow",
  inactiveIcon: "eclipseGrey",
  path: "",
});

const ACCOUNTS = menuItem({
  key: "accounts",
  title: "Accounts & Users",
  activeIcon: "teamYellow",
  inactiveIcon: "teamGrey",
  path: "",
  submenu: [
    {
      key: "account/all",
      title: "Create an account",
      path: `${Routes.ADMIN_ACCOUNTS.path}/create`,
    },
    {
      key: Routes.ADMIN_ACCOUNTS.path,
      title: "All accounts",
      path: Routes.ADMIN_ACCOUNTS.path,
    },
  ],
});

export const AdminMenu = [
  DASHBOARD,
  {
    ...FRAMEWORKS_ADMIN,
    submenu: [
      {
        key: Routes.FRAMEWORKS_FEATURES.path,
        title: "Features",
        path: Routes.FRAMEWORKS_FEATURES.path,
      },
      {
        key: Routes.FRAMEWORKS_DIMENSIONS.path,
        title: "Dimensions",
        path: Routes.FRAMEWORKS_DIMENSIONS.path,
      },
      /*
       * @archived
       * {
       *   key: "frameworks/templates",
       *   title: "Templates",
       *   path: Routes.FRAMEWORKS_TEMPLATES.path,
       * },
       */
    ],
  },
  ACCOUNTS,
];
