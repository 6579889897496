import React, { useContext } from "react";
import Title from "antd/es/typography/Title";
import { Row, Col } from "antd";
import { ButtonPrimary, Icon } from "../index";
import Text from "antd/es/typography/Text";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { AuthContext } from "utils/context/auth";

//import { useHistory } from "react-router-dom";

export const SignUpSuccess: React.FC = () => {
  //const history = useHistory();
  // eslint-disable-next-line
  // @ts-ignore
  const { logout } = useContext(AuthContext);
  const { xs } = useBreakpoint();

  return (
    <Row justify="center" gutter={[0, 21]}>
      <Col span={24} style={{ marginTop: xs ? "60px" : "130px" }}>
        <Title level={xs ? 3 : 1} style={{ textAlign: "center" }}>
          Your account has been successfully created
        </Title>
      </Col>
      <Col span={24}>
        <Title
          type="secondary"
          level={xs ? 4 : 5}
          style={{ textAlign: "center" }}
        >
          We’ve sent you a verification link to your mailbox. Please verify your
          email before getting started.
        </Title>
      </Col>
      <Col span={24}>
        <ButtonPrimary
          icon={<Icon name="checkmark" style={{ fontSize: "11px" }} />}
          onClick={() => {
            logout();
          }}
        >
          <Text strong>Login</Text>
        </ButtonPrimary>
      </Col>
      {/* <Col span={24}>
        <ButtonTertiary
          htmlType="submit"
          onClick={logout}
          outlined
          transparent
          style={{ width: "100%" }}
        >
          <Text strong> Logout</Text>
        </ButtonTertiary>
      </Col> */}
    </Row>
  );
};
