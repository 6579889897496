import { useState, useMemo, useEffect, ReactElement } from "react";
import { TeamMemberList } from "pages/my-team/members/TeamMemberList";
import { useParams, useHistory, generatePath } from "react-router-dom";
import {
  AccountsDocument,
  Account,
  AccountUsersDocument,
  useUpdateLlunaAccountMutation,
} from "codegen/generated/graphql";
import { Routes } from "router/routes";
import ContentLayout from "layout/ContentLayout";
import { ContentType } from "layout/ContentLayout/ContentLayout";
import { useTable } from "hooks/table";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { UserWithAttributes } from "common/types";
import { Input, Row, Space, Typography, Col, Switch, notification } from "antd";
import { ButtonPrimary, ButtonTertiary, Icon } from "components";
import * as S from "./AccountUserList.styles";
const { Text, Title } = Typography;

export const AccountUserList = (): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const handleOnRedirect = (to: string) => history.push(to);

  const [searchInputValue, setSearchInputValue] = useState("");
  const [hrisIntegrationChecked, setHrisIntegrationChecked] = useState(false);
  const [collaborationToolsChecked, setCollaborationToolsChecked] =
    useState(false);
  const [inputSubscriptionId, setSubscriptionId] = useState("");
  const [useStripeChecked, setUseStripeChecked] = useState(false);
  const fetchAccounts = useLazyQuery(AccountsDocument);
  const { data: { accounts: accountsData = [] } = {} } = useTable<Account>(
    fetchAccounts,
    {
      key: "accounts",
    }
  );

  const fetch = useLazyQuery(AccountUsersDocument);
  const { tableProps, data: { accountUsers: data } = {} } =
    useTable<UserWithAttributes>(fetch as any, {
      searchInputValue,
      key: "accountUsers.users",
      additionalVariables: {
        accountId: id,
      },
    });

  const memberList = useMemo(() => {
    return (
      <TeamMemberList<UserWithAttributes> tableProps={tableProps} data={data} />
    );
  }, [data, tableProps]);

  const onHrisIntegrationChange = (checked: boolean) => {
    setHrisIntegrationChecked(checked);
    handleAccountUpdate(checked, collaborationToolsChecked);
  };

  const onCollaborationToolsChange = (checked: boolean) => {
    setCollaborationToolsChecked(checked);
    handleAccountUpdate(hrisIntegrationChecked, checked);
  };

  const [updateLlunaAccount] = useUpdateLlunaAccountMutation({
    onCompleted: () => {
      notification.success({
        type: "success",
        message: `Account was updated`,
        placement: "bottomLeft",
      });
    },
    onError: (err: ApolloError) => {
      notification.error({
        type: "warning",
        message: err.message.split("[")[0],
        placement: "bottomLeft",
      });
    },
    refetchQueries: [
      {
        query: AccountsDocument,
        variables: {},
      },
    ],
  });

  const handleAccountUpdate = (
    isHrisIntegrationChecked: boolean,
    isCollaborationToolsChecked: boolean
  ) => {
    updateLlunaAccount({
      variables: {
        input: {
          accountId: id,
          integrationHRIS: isHrisIntegrationChecked,
          integrationCollaboration: isCollaborationToolsChecked,
          actionKey: "integration",
        },
      },
    });
  };

  useEffect(() => {
    if (accountsData && accountsData?.length) {
      const accountItem: any = accountsData.find((item: any) => item.id === id);

      setHrisIntegrationChecked(accountItem?.integrationHRIS);
      setCollaborationToolsChecked(accountItem?.integrationCollaboration);
      if (!accountItem?.subscriptions[0]) {
        setSubscriptionId("");
      } else {
        setSubscriptionId(accountItem?.subscriptions[0]?.stripeSubscriptionId);
        if (!accountItem?.subscriptions[0]) {
          setUseStripeChecked(false);
        } else {
          setUseStripeChecked(true);
        }
      }
    }
  }, [id, accountsData]);

  const onsubmitSubscription = () => {
    if (!inputSubscriptionId) {
      notification.error({
        type: "warning",
        message: `Please enter the Subscription Id`,
        placement: "bottomLeft",
      });
    } else {
      updateLlunaAccount({
        variables: {
          input: {
            accountId: id,
            useStripe: true,
            subscriptionId: inputSubscriptionId,
            actionKey: "subscription",
          },
        },
      });
    }
  };
  const onStripeChange = (checked: boolean) => {
    setUseStripeChecked(checked);
    if (checked === false) {
      updateLlunaAccount({
        variables: {
          input: {
            accountId: id,
            subscriptionId: "",
            useStripe: false,
            actionKey: "subscription",
          },
        },
      });
      setSubscriptionId("");
    } else {
      if (!inputSubscriptionId) {
        console.log("First time call toggle button");
      }
    }
  };
  return (
    <ContentLayout
      header={{
        title: "Account Info",
      }}
      typeContent={ContentType.FullWidth}
    >
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <S.Wrapper>
            <Col span={5}>
              <Text>HRIS Integration</Text>
              <Space size={10} style={{ marginLeft: "1rem" }}>
                <Switch
                  checked={hrisIntegrationChecked}
                  onChange={onHrisIntegrationChange}
                />
              </Space>
            </Col>
            <Col span={8}>
              <Text>Collaboration Tools Integration</Text>
              <Space size={10} style={{ marginLeft: "1rem" }}>
                <Switch
                  checked={collaborationToolsChecked}
                  onChange={onCollaborationToolsChange}
                />
              </Space>
            </Col>
            <Col span={4}>
              <Text>Use Stripe</Text>
              <Space size={10} style={{ marginLeft: "1rem" }}>
                <Switch checked={useStripeChecked} onChange={onStripeChange} />
              </Space>
            </Col>

            {useStripeChecked ? (
              <Col span={9} style={{ bottom: 10 }}>
                <Row>
                  <Col span={14}>
                    <Input
                      placeholder="Subscription Id..."
                      size={"large"}
                      required={true}
                      value={inputSubscriptionId}
                      onChange={(e: any) => {
                        setSubscriptionId(e.target.value);
                      }}
                    />
                  </Col>
                  <Col span={4} offset={1} style={{ display: "contents" }}>
                    <ButtonPrimary
                      style={{
                        height: "38px",
                        width: "80px",
                        marginLeft: "12px",
                      }}
                      onClick={(e: any) => onsubmitSubscription()}
                    >
                      <Text
                        style={{ textAlign: "center", marginTop: 3 }}
                        strong
                      >
                        Save
                      </Text>
                    </ButtonPrimary>
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col span={9}></Col>
            )}
          </S.Wrapper>
        </Col>
        <Col span={12}>
          <Title level={3}>Users</Title>
        </Col>
        <Col span={12} style={{ display: "flex", justifyContent: "right" }}>
          <ButtonTertiary
            icon={<Icon fontSize={22} name="plus" />}
            onClick={() =>
              handleOnRedirect(
                generatePath(Routes.ADMIN_ACCOUNT_USER_ADD.path, { id })
              )
            }
          >
            Add User
          </ButtonTertiary>
        </Col>

        <Input
          placeholder="Search by name..."
          size={"large"}
          value={searchInputValue}
          onChange={(e) => {
            setSearchInputValue(e.target.value);
          }}
        />
      </Row>
      {memberList}
    </ContentLayout>
  );
};
