import { FC, useContext } from "react";
/*
 * @archived
 * import { getFormattedDate, getFormattedTime } from "utils/formatters";
 * import { WorkDesignView } from "components";
 */
import { DashboardCard } from "../DashboardCard";
import * as S from "./styles";
import { Typography } from "antd";
import { useGetReadMeQuery } from "codegen/generated/graphql";
import { AuthContext } from "utils/context/auth";
import { Summary } from "pages/readme/readmeComponent/Summary";

export const CurrentPOP: FC = () => {
  const { user } = useContext(AuthContext);
  const { data: readmeData } = useGetReadMeQuery({
    variables: {
      userId: user?.id || "",
    },
  });
  const hasQuickStart = !!readmeData?.readMe?.quickStartComplete;
  const lastUpdatedDate = new Date(readmeData?.readMe?.updatedAt);
  const lastUpdatedFormatted = lastUpdatedDate.toLocaleDateString("en-us", {
    month: "long",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <DashboardCard span={24} icon="eclipseYellow" title="Current POP">
      <S.Container>
        {!hasQuickStart ? (
          <S.WrapperLeft>
            <Typography.Title level={2}>
              Quick start not yet complete!
            </Typography.Title>
            <Summary />
          </S.WrapperLeft>
        ) : (
          <S.WrapperLeft>
            <Typography.Title level={2}>Ready to be shared!</Typography.Title>
            <Summary />
            <Typography.Title level={4}>
              Last updated: {lastUpdatedFormatted}
            </Typography.Title>
          </S.WrapperLeft>
        )}
      </S.Container>
    </DashboardCard>
  );
};
