import { Radio as AntRadio } from "antd";
import styled from "styled-components";

export const Radio = styled(AntRadio)`
  // Color for Label
  color: ${(props) => props.theme.colors.grey_70};
  font-size: 15px;
  & .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${(props) => props.theme.colors.blue_80};
  }

  // Color for Inner dot
  & .ant-radio-inner::after {
    background-color: ${(props) => props.theme.colors.blue_60};
  }

  & .ant-radio-input:hover + .ant-radio-inner {
    border-color: ${(props) => props.theme.colors.blue_70};
  }

  & .ant-radio-checked .ant-radio-inner {
    border-color: ${(props) => props.theme.colors.blue_70};
    box-shadow: none;
  }
`;

export const RadioGroup = styled(AntRadio.Group)`
  display: flex;
  width: 100%;
  & .ant-radio-button-wrapper {
    font-size: 13px;
    flex-grow: 1;
    text-align: center;
    min-height: 100px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-child {
      border-bottom-left-radius: 1rem;
      border-top-left-radius: 1rem;
    }
    &:last-child {
      border-bottom-right-radius: 1rem;
      border-top-right-radius: 1rem;
    }
    &.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]) {
      background: blue;
      color: white;
    }
  }
`;
