import { ReactElement, useState } from "react";
import {
  Modal,
  Col,
  Row,
  Typography,
  Spin,
  notification,
  InputNumber,
} from "antd";
import * as S from "../../plan/components/Subscribe.styles";
import image from "../../../assets/images/rightArrow.png";
import { ButtonTertiary } from "components/Button";
import AlertModal from "pages/boards/components/alertModal";
import { LABEL } from "utils/constValues";
import { ApolloError } from "@apollo/client";
import {
  AccountSubscriptionDocument,
  useChangeTeamSizeMutation,
} from "codegen/generated/graphql";
import { STRIPE_PRODUCTION } from "pages/plan/constants";

const ChangeTeamSizeModal = ({
  isStripeModal,
  setTeamModal,
  accountSubscription,
}: {
  isStripeModal: boolean;
  setTeamModal: any;
  accountSubscription: any;
}): ReactElement => {
  const { Text } = Typography;
  const [isEnable, setIsEnable] = useState(false);
  const [isUpdted, setIsUpdate] = useState<any>(false);
  const [quantity, setQantity] = useState<any>(accountSubscription?.seats);
  const [preQuantity, setPreQuantity] = useState<any>(
    accountSubscription?.seats
  );
  const [updateTeamSize] = useChangeTeamSizeMutation();
  const [price] = useState<any>(accountSubscription?.plan?.pricePerUnit);
  const [alertModal, setAlertModal] = useState(false);
  const [, setUpdatedPrice] = useState<any>(null);

  const handleCancel = () => {
    setTeamModal(false);
    setIsUpdate(false);
    setQantity(accountSubscription?.seats);
  };

  const handleCheckPlan = () => {
    if (accountSubscription?.interval === "YEAR") {
      return "annually";
    } else if (accountSubscription?.interval === "MONTH") {
      return "Monthly";
    }
  };

  const handleMember = (value: any) => {
    setIsUpdate(true);
    let updatedPrice: any = null;
    updatedPrice =
      accountSubscription?.interval === "YEAR"
        ? value * price * 12
        : accountSubscription?.interval === "MONTH"
        ? value * price * 1
        : value * price;
    setUpdatedPrice(updatedPrice);
    setQantity(value);
  };

  const handleSubmitButton = (quantity: any) => {
    if (quantity === preQuantity) {
      setAlertModal(true);
    } else {
      setIsEnable(true);
      const input: any = {
        planPriceId:
          process.env.REACT_APP_PRODUCTION === STRIPE_PRODUCTION
            ? accountSubscription?.plan?.liveStripePriceId
            : accountSubscription?.plan?.testStripePriceId,
        quantity: quantity - preQuantity,
      };

      updateTeamSize({
        variables: { input: input },
        refetchQueries: [AccountSubscriptionDocument],
      })
        .then((res: any) => {
          if (res.data) {
            setIsUpdate(false);
            setTeamModal(false);
            setIsEnable(false);
            setPreQuantity(accountSubscription?.seats);
            notification.success({
              type: "success",
              message: "Team size updated successfully",
              placement: "bottomLeft",
            });
          }
        })
        .catch((err: ApolloError) => {
          setIsEnable(false);
          notification.error({
            type: "warning",
            message: err.message.split("[")[0],
            placement: "bottomLeft",
          });
        });
    }
  };

  const handleAlertModal = async (val: boolean) => {
    setAlertModal(val);
  };

  return (
    <>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        visible={isStripeModal}
        footer={null}
        style={{
          overflow: "hidden",
          borderRadius: "1rem",
          padding: "0",
          position: "relative",
        }}
        onCancel={handleCancel}
        closable={true}
        width={"36%"}
      >
        <Spin tip="Loading..." spinning={isEnable}>
          {accountSubscription?.plan && (
            <>
              <Col>
                <Col span={12} style={{ display: "flex" }}>
                  <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                    Change team size
                  </Text>
                </Col>
              </Col>
              <Row style={{ marginTop: "2rem" }}>
                <Col span={24}>
                  <InputNumber
                    // label="Number of Seats"
                    value={quantity}
                    onChange={(e: any) => {
                      if (e >= preQuantity) {
                        handleMember(e);
                      }
                    }}
                    name="seats"
                    style={{ marginBottom: "9px" }}
                    min={preQuantity}
                  />
                </Col>
              </Row>
              <Row gutter={[8, 8]} style={{ margin: "24px 0" }}>
                <Col
                  span={10}
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <p style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: "700",
                        color: "#9B9B9B",
                      }}
                    >
                      Current
                    </Text>
                  </p>
                  <p style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <S.TeamTextCurrent>
                      {accountSubscription?.seats} members
                    </S.TeamTextCurrent>
                  </p>
                  <p style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <S.TeamTextCurrent>
                      Billed {handleCheckPlan()} at $
                      {accountSubscription?.interval === "YEAR"
                        ? (
                            +accountSubscription?.plan?.pricePerUnit *
                              12 *
                              (+preQuantity - 2) || 0
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : accountSubscription?.interval === "MONTH"
                        ? (
                            +accountSubscription?.plan?.pricePerUnit *
                              1 *
                              (+preQuantity - 2) || 0
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : 0}{" "}
                    </S.TeamTextCurrent>
                  </p>
                </Col>

                <Col
                  span={2}
                  style={{
                    marginRight: "20px",
                    paddingTop: "3rem",
                  }}
                >
                  {isUpdted && (
                    <img
                      src={image}
                      alt="test"
                      className="bg-img"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  )}
                </Col>

                <Col
                  span={10}
                  style={{
                    marginTop: "20px",
                  }}
                >
                  {isUpdted && (
                    <>
                      <p style={{ marginTop: "0px", marginBottom: "0px" }}>
                        <Text style={{ fontSize: 12, fontWeight: "700" }}>
                          Updated
                        </Text>
                      </p>
                      <p style={{ marginTop: "0px", marginBottom: "0px" }}>
                        <S.TeamTextUpdated>
                          {quantity} members
                        </S.TeamTextUpdated>
                      </p>
                      <p style={{ marginTop: "0px", marginBottom: "0px" }}>
                        <S.TeamTextUpdated>
                          Billed {handleCheckPlan()} at $
                          {isUpdted
                            ? accountSubscription?.interval === "YEAR"
                              ? (
                                  +accountSubscription?.plan?.pricePerUnit *
                                    12 *
                                    (+quantity - 2) || 0
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : (
                                  +accountSubscription?.plan?.pricePerUnit *
                                    (+quantity - 2) || 0
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                            : 0}
                        </S.TeamTextUpdated>
                      </p>
                    </>
                  )}
                </Col>
              </Row>
              <Row style={{ marginTop: "2rem" }}>
                <Col span={6}>
                  <ButtonTertiary
                    onClick={() => handleSubmitButton(quantity)}
                    style={{
                      background: "#01083A",
                      width: "155px",
                      height: "Hug (36px)",
                      border: "none",
                      outline: "none",
                      margin: "0 auto",
                    }}
                  >
                    <Text strong> Proceed </Text>
                  </ButtonTertiary>
                </Col>
              </Row>
            </>
          )}
        </Spin>
      </Modal>
      {alertModal && (
        <AlertModal
          isModalVisible={alertModal}
          setModalVisible={(val: any) => handleAlertModal(val)}
          message={LABEL.PROCEED_MSG}
          dualButton={{ show: false, labelText: LABEL.OK }}
          handleButtonAction
        />
      )}
    </>
  );
};
export default ChangeTeamSizeModal;
