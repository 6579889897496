import { ApolloError } from "@apollo/client";
import { Modal, Col, Row, Form, Input, Typography, notification } from "antd";
import {
  AccountSubscriptionDocument,
  MeDocument,
  useChangeSubscriptionBillingMutation,
} from "codegen/generated/graphql";
import { ButtonTertiary } from "components";
import { ReactElement } from "react";

const EmailModal = ({
  emailModal,
  setEmailModal,
  accountSubscription,
}: {
  emailModal: boolean;
  setEmailModal: any;
  accountSubscription: any;
}): ReactElement => {
  const { Text } = Typography;

  const [form] = Form.useForm();
  const [updateEmail] = useChangeSubscriptionBillingMutation();
  const email = accountSubscription?.billingAction?.supportEmail || "";

  const handleCancel = () => {
    setEmailModal(false);
  };

  const onFinish = (values: any) => {
    const input: any = {
      email: values?.email,
    };
    updateEmail({
      variables: { input: input },
      refetchQueries: [AccountSubscriptionDocument, MeDocument],
    })
      .then((res: any) => {
        if (res.data) {
          setEmailModal(false);
          notification.success({
            type: "success",
            message: "Email updated successfully",
            placement: "bottomLeft",
          });
        }
      })
      .catch((err: ApolloError) => {
        notification.error({
          type: "warning",
          message: err.message.split("[")[0],
          placement: "bottomLeft",
        });
      });
  };

  return (
    <Modal
      maskClosable={false}
      destroyOnClose={true}
      visible={emailModal}
      footer={null}
      style={{
        overflow: "hidden",
        borderRadius: "1rem",
        padding: "0",
        position: "relative",
      }}
      onCancel={handleCancel}
      closable={true}
      width={"40%"}
    >
      <>
        <Col>
          <Col span={12} style={{ display: "flex" }}>
            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
              Change email address
            </Text>
          </Col>
        </Col>
        <Row style={{ marginTop: "24px" }}>
          <Col span={16}>
            <Form
              name="profile"
              layout="vertical"
              form={form}
              onFinish={onFinish}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="email"
                    label={"Email"}
                    rules={[
                      {
                        required: true,
                        message: "Email can't be empty",
                      },
                    ]}
                  >
                    <Input size="large" defaultValue={email} />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ marginTop: "1rem" }}>
                <Col span={7}>
                  <ButtonTertiary
                    htmlType="submit"
                    style={{
                      background: "#01083A",
                      width: "155px",
                      height: "Hug (36px)",
                      border: "none",
                      outline: "none",
                      margin: "0 auto",
                    }}
                  >
                    <Text strong> Update </Text>
                  </ButtonTertiary>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </>
    </Modal>
  );
};
export default EmailModal;
