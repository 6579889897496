export const LABEL = {
  POP: "POP",
  SKIP_FOR_NOW: "Skip for now",
  SEND_EMAIL: "Send email",
  SEND: "Send",
  OK: "OK",
  CONFIRM: "Confirm",
  SUBMIT: "Submit",
  MESSAGE: "Message",
  RESEND_INVITATION: "Resend Invitation",
  SEND_UPDATE_REMINDER: "Send update reminder",
  EMAIL_ADDRESS: "Email Addresses*",
  COPY: "Copy",
  SEND_EMAIL_TITLE: "Send an email directly to people of your choice",
  SHARE_LINK_TITLE: "Share a publicly-accessible link with anybody",
  SHARE_MY_POP: "Share My POP",
  ADD_EMAIL: "Add email",
  QUICK_START_ALERT:
    "Please note you cannot preview and share your POP until all of the Quick Start questions are completed.",
  ADDITIONAL_COMMENTS: "Additional comments",
  ADDITIONAL_COMMENTS_PLACEHOLDER: "Type your additional comment...",
  MARK_ALL_AS_READ: "Mark all as Read",
  CREATE: "Create",
  ADD_USER: "Add User",
  SEARCH_USER: "Search for user...",
  SEARCH_BOARD: "Search for Board",
  BOARD_ALERT:
    "This board is not yet visible. When all invited members have completed at least the quickstart component of their POP, this board will be available to view. You will be notified when it's ready.",
  PLAN_DETAIL: "Plan details",
  Billing_ACTION: "Billing actions",
  INVOICE: "Invoices",
  CANCEL: "Cancel",
  NO: "No",
  YES: "Yes",
  DELETE_BOARD: "Delete board",
  DELETE_BOARD_MSG:
    "Are you sure you want to delete this board? All members will lose access to this board and the results may only be visible again by recreating it and adding the same members.",
  SUBSCRIPTION_PAYMENT_MSG:
    "You have used all of your seats. You are inviting new members which will charge you extra. Do you want to proceed?",
  CANCEL_SUBSCRIPTION_MSG: "Do you want to cancel the subscription?",
  PROCEED_MSG: "You should change team size to proceed?",
};

export const ACTION = {
  SIGN_UP: "SignUp",
  INVITE: "Invite",
};

export const ERRORTAG = {
  INVITE: "Invite",
};
