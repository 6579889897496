import styled from "styled-components";
import { Spin } from "antd";
//Board page css
export const boardPlan = styled.div`
  display: flex;
  gap: 7rem;
`;

export const cardBody = styled.div<{ initialCard: boolean }>`
  display: flex;
  cursor: pointer;
  height: 280px;
  border-radius: 20;
  padding: 1rem;
  border-radius: 20px;
  position: "relative";
  background: ${({ initialCard }) =>
    initialCard
      ? "linear-gradient(122.55deg, rgba(255, 243, 225, 0.9) -15.83%, rgba(254, 250, 244, 0.9) 111.77%)"
      : "linear-gradient(122.55deg, rgba(201, 207, 255, 0.9) -15.83%, rgba(231, 233, 255, 0.9) 111.77%)"};
`;

export const cardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: normal;
  width: 100%;
  justify-content: space-between;
  padding: 1rem;
`;

export const bottomPercent = styled.span<{ incomplete: boolean }>`
  position: absolute;
  bottom: 0px;
  left: 17px;
  background: ${({ incomplete }) => (incomplete ? "#FA9E9F" : "#74D0D0")};
  padding: 8px 3rem;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  border: 1px solid #c8ccec;
  color: ${({ incomplete }) => (incomplete ? "#335E5E" : "#602538")};
`;
//outer card percent css
export const outerCard = styled.span`
  position: absolute;
  bottom: 0px;
  left: 17px;
  background: #fff;
  padding: 8px 3rem;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  border: 1px solid #c8ccec;
`;

export const cardrightContent = styled.div<{ initialCard: boolean }>`
  width: 8rem;
  height: 8rem;
  display: flex;
  flex-direction: column;
  background: ${({ initialCard }) => (initialCard ? "#F5EAD9" : "#C7CCF4")};
  padding: 2rem;
  border-radius: 8rem;
  margin: 0px;
  min-width: 8rem;
  min-height: 8rem;
`;

export const manageBoardText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-left: 7.5rem;
`;

export const Spinner = styled(Spin)`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const headerLayout = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const cardBoard = styled.p`
  color: #01083a;
  font-weight: 500;
  font-size: 1.4rem;
  word-break: break-word;
`;
export const deleteBoard = styled.p`
  color: grey;
  font-weight: 500;
  font-size: 15px;
  &:hover {
    color: #01083a;
    text-decoration: underline;
    cursor: pointer;
  }
`;
