import { UserRole } from "codegen/generated/graphql";
import openspace from "assets/images/openspace.svg";
import scooterRide from "assets/images/scooterRide.svg";
/*
 * @archived
 * import relaxing from "assets/images/relaxing.svg";
 * import working from "assets/images/working.svg";
 */
import theme from "theme";
import { COLOR_PRESETS } from "components/Button/colors";
import { Routes } from "router/routes";

export interface ActionBannerConfig {
  headline: string;
  firstLine: string;
  secondLine: string;
  buttonPreset: keyof typeof COLOR_PRESETS;
  bannerBgColor: string;
  buttonText: string;
  link: string;
  imageSrc: string;
}

export type ActionBanners = {
  [key in UserRole]?: any;
  /*
   * @archived
   *[UserRole.TeamAdmin]: {
   *[key in
   *  | "create-team"
   *  | "create-framework"
   *  | "share-framework"
   *  | "framework-shared"]: ActionBannerConfig;
   *};
   */
};

export const actionBanners: ActionBanners = {
  [UserRole.PrimaryAdmin]: {
    /*
     * @archived
     * "create-team": {
     *   headline: "Welcome!",
     *   firstLine: "First things first,",
     *   secondLine: "you need to create and define your team",
     *   buttonPreset: "gold",
     *   bannerBgColor: theme.colors.gold_20,
     *   buttonText: "Create Team Profile",
     *   link: Routes.TEAM_PROFILE.path,
     *   imageSrc: openspace,
     * },
     * "create-framework": {
     *   headline: "Getting exciting",
     *   firstLine: "Frameworks are the essence of LLUNA",
     *   secondLine: "set up features for your team’s work designs.",
     *   buttonPreset: "blue",
     *   bannerBgColor: theme.colors.blue_20,
     *   buttonText: "Create Framework",
     *   link: Routes.FRAMEWORKS_CREATE_NEW.path,
     *   imageSrc: scooterRide,
     * },
     * "share-framework": {
     *   headline: "Almost there",
     *   firstLine: "Notify your team members",
     *   secondLine: "to register on LLUNA and create their work designs.",
     *   buttonPreset: "teal",
     *   bannerBgColor: theme.colors.teal_10,
     *   buttonText: "Share Framework",
     *   link: Routes.FRAMEWORKS_COLLECTION.path,
     *   imageSrc: relaxing,
     * },
     * "framework-shared": {
     *   headline: "Active Frameworks",
     *   firstLine: (fwCount: number) => `You have ${fwCount} active frameworks,`,
     *   secondLine: "go ahead and manage your collection",
     *   buttonPreset: "magenta",
     *   bannerBgColor: theme.colors.magenta_10,
     *   buttonText: "Frameworks Collection",
     *   link: Routes.FRAMEWORKS_COLLECTION.path,
     *   imageSrc: working,
     * },
     */
    "create-pop": {
      headline: "Welcome!",
      firstLine: "First things first,",
      secondLine: "let's create your first POP",
      buttonPreset: "gold",
      bannerBgColor: theme.colors.gold_20,
      buttonText: "My POP",
      link: Routes.README.path,
      imageSrc: openspace,
    },
    "active-pop": {
      headline: "Welcome back!",
      firstLine: "Ready to update your POP?",
      secondLine: "Go ahead!",
      buttonPreset: "blue",
      bannerBgColor: theme.colors.blue_20,
      buttonText: "Update my POP",
      link: Routes.README.path,
      imageSrc: scooterRide,
    },
  },
  [UserRole.Admin]: {
    /*
     * @archived
     * "create-team": {
     *   headline: "Welcome!",
     *   firstLine: "First things first,",
     *   secondLine: "you need to create and define your team",
     *   buttonPreset: "gold",
     *   bannerBgColor: theme.colors.gold_20,
     *   buttonText: "Create Team Profile",
     *   link: Routes.TEAM_PROFILE.path,
     *   imageSrc: openspace,
     * },
     * "create-framework": {
     *   headline: "Getting exciting",
     *   firstLine: "Frameworks are the essence of LLUNA",
     *   secondLine: "set up features for your team’s work designs.",
     *   buttonPreset: "blue",
     *   bannerBgColor: theme.colors.blue_20,
     *   buttonText: "Create Framework",
     *   link: Routes.FRAMEWORKS_CREATE_NEW.path,
     *   imageSrc: scooterRide,
     * },
     * "share-framework": {
     *   headline: "Almost there",
     *   firstLine: "Notify your team members",
     *   secondLine: "to register on LLUNA and create their work designs.",
     *   buttonPreset: "teal",
     *   bannerBgColor: theme.colors.teal_10,
     *   buttonText: "Share Framework",
     *   link: Routes.FRAMEWORKS_COLLECTION.path,
     *   imageSrc: relaxing,
     * },
     * "framework-shared": {
     *   headline: "Active Frameworks",
     *   firstLine: (fwCount: number) => `You have ${fwCount} active frameworks,`,
     *   secondLine: "go ahead and manage your collection",
     *   buttonPreset: "magenta",
     *   bannerBgColor: theme.colors.magenta_10,
     *   buttonText: "Frameworks Collection",
     *   link: Routes.FRAMEWORKS_COLLECTION.path,
     *   imageSrc: working,
     * },
     */
    "create-pop": {
      headline: "Welcome!",
      firstLine: "First things first,",
      secondLine: "let's create your first POP",
      buttonPreset: "gold",
      bannerBgColor: theme.colors.gold_20,
      buttonText: "My POP",
      link: Routes.README.path,
      imageSrc: openspace,
    },
    "active-pop": {
      headline: "Welcome back!",
      firstLine: "Ready to update your POP?",
      secondLine: "Go ahead!",
      buttonPreset: "blue",
      bannerBgColor: theme.colors.blue_20,
      buttonText: "Update my POP",
      link: Routes.README.path,
      imageSrc: scooterRide,
    },
    /*
     * @archived
     * [UserRole.TeamManager]: {
     *   "create-team": {
     *     headline: "Welcome!",
     *     firstLine: "First things first,",
     *     secondLine: "you need to create and define your team",
     *     buttonPreset: "gold",
     *     bannerBgColor: theme.colors.gold_20,
     *     buttonText: "Create Team Profile",
     *     link: Routes.TEAM_PROFILE.path,
     *     imageSrc: openspace,
     *   },
     *   "create-framework": {
     *     headline: "Getting exciting",
     *     firstLine: "Frameworks are the essence of LLUNA",
     *     secondLine: "set up features for your team’s work designs.",
     *     buttonPreset: "blue",
     *     bannerBgColor: theme.colors.blue_20,
     *     buttonText: "Create Framework",
     *     link: Routes.FRAMEWORKS_CREATE_NEW.path,
     *     imageSrc: scooterRide,
     *   },
     *   "share-framework": {
     *     headline: "Almost there",
     *     firstLine: "Notify your team members",
     *     secondLine: "to register on LLUNA and create their work designs.",
     *     buttonPreset: "teal",
     *     bannerBgColor: theme.colors.teal_10,
     *     buttonText: "Share Framework",
     *     link: Routes.FRAMEWORKS_COLLECTION.path,
     *     imageSrc: relaxing,
     *   },
     *   "framework-shared": {
     *     headline: "Active Frameworks",
     *     firstLine: (fwCount: number) => `You have ${fwCount} active frameworks,`,
     *     secondLine: "go ahead and manage your collection",
     *     buttonPreset: "magenta",
     *     bannerBgColor: theme.colors.magenta_10,
     *     buttonText: "Frameworks Collection",
     *     link: Routes.FRAMEWORKS_COLLECTION.path,
     *     imageSrc: working,
     *   },
     */
  },
  [UserRole.Member]: {
    /*
     * @archived
     * "create-workdesign": {
     *   headline: "Welcome!",
     *   firstLine: "First things first,",
     *   secondLine: "submit your Personal Work Design",
     *   buttonPreset: "gold",
     *   bannerBgColor: theme.colors.gold_20,
     *   buttonText: "Submit Work Design",
     *   link: Routes.WORK_DESIGN.path,
     *   imageSrc: openspace,
     * },
     * "active-workdesign": {
     *   headline: "Active Work Design",
     *   firstLine: "You can check your preferences anytime",
     *   secondLine: "Next refresh: ",
     *   buttonPreset: "blue",
     *   bannerBgColor: theme.colors.blue_20,
     *   buttonText: "My Work Design",
     *   link: Routes.WORK_DESIGNS.path,
     *   imageSrc: scooterRide,
     * },
     */
    "create-pop": {
      headline: "Welcome!",
      firstLine: "First things first,",
      secondLine: "let's create your first POP",
      buttonPreset: "gold",
      bannerBgColor: theme.colors.gold_20,
      buttonText: "My POP",
      link: Routes.README.path,
      imageSrc: openspace,
    },
    "active-pop": {
      headline: "Welcome back!",
      firstLine: "Ready to update your POP?",
      secondLine: "Go ahead!",
      buttonPreset: "blue",
      bannerBgColor: theme.colors.blue_20,
      buttonText: "Update my POP",
      link: Routes.README.path,
      imageSrc: scooterRide,
    },
  },
};

export enum ChoicesPlaceholders {
  USD = "$USD",
  HOURS = "Hours",
  DAYS = "Days",
  WEEKS = "Weeks",
  MONTHS = "Months",
  YEARS = "Years",
  PERCENTAGE = "% Percentage",
  DATE = "Date picker",
}
