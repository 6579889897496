export const setCookie = (name: string, value: string, days = 30): void => {
  const keyVal = `${name}=${value.trim()};`;
  const date = new Date();
  date.setDate(date.getDate() + days);
  const expires = `expires=${date.toUTCString()};`;
  const path = "path=/;";

  const isLocal = window.location.hostname.includes("localhost");
  const domain = `Domain=${isLocal ? "localhost" : "hellolluna.com"};`;
  const cookie = keyVal + expires + path + domain;

  document.cookie = cookie;
};

export const getCookie = (name: string): string | null => {
  const parsed = document.cookie.split(";");
  const result = parsed.find((value) => value.includes(name));

  if (!result) {
    return null;
  }

  const split = result.split("=");
  const value = split[1].trim() || null;
  return value;
};

export const clearCookie = (name: string): void => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
