import { ReactElement, useContext } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  notification,
  Typography,
} from "antd";
import {
  MeDocument,
  useChangeSubscriptionPlanMutation,
  AccountSubscriptionDocument,
} from "codegen/generated/graphql";
import { AuthContext } from "utils/context/auth";
import Confirmation from "./Confirmation";
import { ApolloError } from "@apollo/client";
/*
 * @archived
 * import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";
 */

const { TextArea } = Input;

const { Link: TextLink } = Typography;
const SendEmail = ({
  setModalContent,
  planData,
  action,
}: {
  setModalContent: any;
  planData: any;
  action: string;
}): ReactElement => {
  const { user } = useContext(AuthContext);
  const [changeSubscription] = useChangeSubscriptionPlanMutation();
  const formInitialValues = {
    from: user?.email,
    to: "howdy@hellolluna.com",
    teamsize: 2,
    message: "",
  };
  const onFinish = async (values: any) => {
    changeSubscription({
      variables: {
        input: {
          additionalInfo: values?.message,
          estimatedTeamSize: values?.teamsize,
          planId: planData?.id,
          planType: planData?.name,
        },
      },
      refetchQueries: [MeDocument, AccountSubscriptionDocument],
      awaitRefetchQueries: true,
    })
      .then((res: any) => {
        setModalContent(<Confirmation action={action} plan={planData} />);
      })
      .catch((err: ApolloError) => {
        notification.error({
          type: "warning",
          message: err.message.split("[")[0],
          placement: "bottomLeft",
        });
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Form
      name="basic"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 22 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={formInitialValues}
    >
      <TextLink
        style={{
          display: "flex",
          justifyContent: "end",
          marginBottom: "2rem",
        }}
        strong
        onClick={() => {
          setModalContent("");
        }}
      >
        {/* <ArrowLeftOutlined style={{ fontSize: 10 }} /> Back to Select Plan */}
      </TextLink>
      <Form.Item label="From" name="from">
        <Input disabled={true} />
      </Form.Item>

      <Form.Item
        label="Estimated Team Size"
        name="teamsize"
        rules={[
          {
            required: true,
            type: "number",
            message: "Please enter valid number",
          },
        ]}
      >
        <InputNumber min={1} />
      </Form.Item>

      <Form.Item
        label="Message"
        name="message"
        rules={[{ required: true, message: "Please enter your message" }]}
      >
        <TextArea rows={5} placeholder="Enter your requirement..." />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 22 }}>
        <Button type="primary" htmlType="submit">
          Send
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SendEmail;
