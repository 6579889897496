import React, { useState } from "react";
import Dimension from "./components/Dimension";
import Choices from "./components/Choices";
import Question from "./components/Question";
import Preview from "./components/Preview";
import { Icon } from "components/Icon";
import {
  FeatureCreateInput,
  FeatureUpdateInput,
  useDimensionsQuery,
} from "codegen/generated/graphql";
import * as S from "./components/styles";
import { ButtonTertiary } from "components/Button";
import { BackLink } from "pages/frameworks/dimensions/new-dimension/NewDimension.styles";
import { Typography } from "antd";
import { Routes } from "router/routes";
import ContentLayout from "layout/ContentLayout";
import { ContentType } from "layout/ContentLayout/ContentLayout";
import { useFeatureContext, ActionType } from "./context";
import { useHistory } from "react-router-dom";

interface Props {
  onSubmit?: (values: FeatureCreateInput | FeatureUpdateInput) => Promise<any>;
  id?: string;
}

export const UpsertFeature: React.FC<Props> = ({ id, onSubmit }) => {
  // TODO: add validation, consider using formik

  const {
    state: { data, activeTab },
    dispatch,
    onChange,
  } = useFeatureContext();

  const { data: dimensionList, loading: dimensionsLoading } =
    useDimensionsQuery();

  const [inProgress, setInProgress] = useState(false);
  const history = useHistory();

  const selectedDimension = dimensionList?.dimensions.filter(
    (res) => res.id === data.dimensionId
  )[0];

  const DimensionContent = (
    <Dimension
      onChange={onChange("dimensionId")}
      dimensionsData={dimensionList}
      loading={dimensionsLoading}
    />
  );
  const ChoicesContent = (
    <Choices
      onChange={onChange("choices")}
      selectedDimension={selectedDimension}
    />
  );
  const QuestionContent = (
    <Question
      onChangeText={onChange("text")}
      selectedDimension={selectedDimension}
    />
  );
  /*
   * @archived
   * const AssignmentContent = (
   *   <Choices
   *     onChange={onChange("choices")}
   *     selectedDimension={selectedDimension}
   *   />
   * );
   */
  const PreviewContent = <Preview selectedDimension={selectedDimension} />;

  const tabs = [
    {
      tab: "Dimension",
      key: "dimension",
      tabsContent: DimensionContent,
    },
    {
      tab: "Question",
      key: "question",
      tabsContent: QuestionContent,
    },
    {
      tab: "Choices",
      key: "choices",
      tabsContent: ChoicesContent,
    },
    /*
     * @archived
     * {
     *   tab: "Assign to",
     *   key: "assignment",
     *   tabsContent: AssignmentContent,
     * },
     */
    {
      tab: "Preview",
      key: "preview",
      tabsContent: PreviewContent,
    },
  ];

  const onSaveFeature = () => {
    setInProgress(true);
    const featureData = data;
    onSubmit &&
      onSubmit({ ...featureData, id }).finally(() => {
        setInProgress(false);
        history.push(Routes.FRAMEWORKS_FEATURES.path);
      });
    //
  };

  const headerConfig = {
    title: !id ? "New Feature" : "Update Feature",
    actions:
      activeTab === "preview" ? (
        <ButtonTertiary
          disabled={inProgress}
          icon={<Icon name="checkmark" />}
          onClick={onSaveFeature}
        >
          <Typography.Text strong>Save Feature</Typography.Text>
        </ButtonTertiary>
      ) : (
        <BackLink to={Routes.FRAMEWORKS_FEATURES.path}>
          Back to Collection
        </BackLink>
      ),
  };

  return (
    <ContentLayout header={headerConfig} typeContent={ContentType.FullWidth}>
      <S.TabBar
        tabsOptions={tabs}
        onChange={(key) => dispatch({ type: ActionType.CHANGE_TAB, key })}
        style={{ height: "100%", flex: 1, display: "flex" }}
      />
    </ContentLayout>
  );
};

export default UpsertFeature;
