import DashboardMember from "./member";
import DashboardAdmin from "./admin";
import { UserRole } from "codegen/generated/graphql";
import { ReactElement, useContext } from "react";
import { AuthContext } from "utils/context/auth";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
/*
 * @archived
 * import { selectedRoleVar } from "store/app";
 * import { useReactiveVar } from "@apollo/client";
 * import DashboardManager from "./manager";
 */

export const Dashboard = (): ReactElement => {
  const { user } = useContext(AuthContext);

  /*
   * @archived
   * const userIsPrimaryAdmin = user?.roles.includes(UserRole.PrimaryAdmin);
   * const userRoleIsAdmin = user?.roles.includes(UserRole.Admin);
   */
  const userIsAdmin = user?.roles.includes(UserRole.LlunaAdmin);
  // const userIsTeamManager = user?.roles.includes(UserRole.TeamManager);
  const { xs } = useBreakpoint();

  /*
   * @archived
   * const selectedRole = useReactiveVar(selectedRoleVar);
   * console.log("Selected Role ==>>", selectedRole);
   *  const shouldShowPrimaryAdmin =
   *    userIsPrimaryAdmin && selectedRole?.id === UserRole.PrimaryAdmin;
   *  const shouldShowAdmin =
   *    userRoleIsAdmin && selectedRole?.id === UserRole.Admin;
   */
  /*
   * @archived
   * const shouldShowTeamManager =
   *   userIsTeamManager && selectedRole?.id === UserRole.TeamManager;
   */

  // if (shouldShowPrimaryAdmin || shouldShowAdmin) return <DashboardManager />;
  if (userIsAdmin) return <DashboardAdmin />;
  return <DashboardMember xs={xs} />;
};
