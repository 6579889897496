import styled from "styled-components";
import {
  LinkedinOutlined,
  TwitterOutlined,
  FacebookOutlined,
  MailOutlined,
  LinkOutlined,
} from "@ant-design/icons";
//import { ButtonTertiary } from "components";
import theme from "theme";

export const SocialButton = styled.button`
  background: ${theme.colors.blue_20};
  width: 30px;
  height: 30px;
  outline: none;
  padding: none;
  border: none;
  cursor: pointer;
`;

export const LinkedInIcon = styled(LinkedinOutlined)`
  :first-child {
    font-size: 20px;
    color: gray;
    :hover {
      color: black;
    }
  }
`;

export const TwitterIcon = styled(TwitterOutlined)`
  :first-child {
    font-size: 20px;
    color: gray;
    :hover {
      color: black;
    }
  }
`;
export const FacebookIcon = styled(FacebookOutlined)`
  :first-child {
    font-size: 20px;
    color: gray;
    :hover {
      color: black;
    }
  }
`;
export const MailIcon = styled(MailOutlined)`
  :first-child {
    font-size: 20px;
    color: gray;
    :hover {
      color: black;
    }
  }
`;
export const LinkIcon = styled(LinkOutlined)`
  :first-child {
    font-size: 20px;
    color: gray;
    :hover {
      color: black;
    }
  }
`;
