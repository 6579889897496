import { ReactElement, useState } from "react";
import { Modal, Col, Row, Typography, List, Spin, notification } from "antd";
import { ButtonTertiary } from "components";
import { Checkout } from "pages/plan/components/Payment";
import image from "../../../assets/images/scrollarrow.png";
import * as S from "../../plan/components/Subscribe.styles";
import { CloseOutlined } from "@ant-design/icons";
import {
  AccountSubscriptionDocument,
  CustomerPaymentMethodsDocument,
  MeDocument,
  useChangeSubscriptionPaymentMethodMutation,
} from "codegen/generated/graphql";
import { ApolloError } from "@apollo/client";
const { Text } = Typography;

const ChangePaymentMethodModal = ({
  isStripeModal,
  subscription,
  setModalVisible,
  paymentData,
}: {
  isStripeModal: boolean;
  subscription: boolean;
  setModalVisible: any;
  paymentData: any;
}): ReactElement => {
  const [addPayment, setAddPayment] = useState(false);
  const [isEnable, setIsEnable] = useState(false);
  const [updatePayment] = useChangeSubscriptionPaymentMethodMutation();

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleModal = (val: any) => {
    setModalVisible(val);
  };

  const handleAddPayment = () => {
    setAddPayment(true);
  };

  const handleDefault = (item: any) => {
    setIsEnable(true);
    const input: any = {
      striptPaymentMethodId: item?.paymentMethodId,
    };

    updatePayment({
      variables: { input: input },
      refetchQueries: [
        AccountSubscriptionDocument,
        MeDocument,
        CustomerPaymentMethodsDocument,
      ],
    })
      .then((res) => {
        if (res.data) {
          handleModal(false);
          setIsEnable(false);
          notification.success({
            type: "success",
            message: "Payment method added successfully",
            placement: "bottomLeft",
          });
        }
      })
      .catch((err: ApolloError) => {
        setIsEnable(false);
        notification.error({
          type: "warning",
          message: err.message.split("[")[0],
          placement: "bottomLeft",
        });
      });
  };

  return (
    <Modal
      maskClosable={false}
      destroyOnClose={true}
      open={isStripeModal}
      footer={null}
      style={{
        overflow: "hidden",
        borderRadius: "1rem",
        padding: "0",
        position: "relative",
      }}
      onCancel={handleCancel}
      closeIcon={
        <CloseOutlined style={{ position: "relative", bottom: 10, left: 10 }} />
      }
      closable={true}
      width={"43%"}
    >
      <>
        {addPayment ? (
          <Checkout
            planData={""}
            quantity={""}
            priceData={""}
            setModalContent={""}
            subscription={subscription}
            handleModal={(val: any) => handleModal(val)}
          />
        ) : (
          <>
            <Spin tip="Loading..." spinning={isEnable}>
              <Col>
                <Col span={24} style={{ display: "flex", marginTop: 5 }}>
                  <Text
                    style={{ fontSize: 20, fontWeight: "bold", marginLeft: 0 }}
                  >
                    Payment methods
                  </Text>
                  <div
                    style={{
                      marginLeft: "auto",
                      marginTop: 5,
                      marginRight: 20,
                    }}
                  >
                    <Text
                      style={{
                        color: "#2199e8",
                        cursor: "pointer",
                        position: "relative",
                        fontWeight: "600",
                        textAlign: "center",
                        fontSize: 14,
                      }}
                      onClick={() => handleAddPayment()}
                    >
                      {" "}
                      Add payment method
                    </Text>
                  </div>
                </Col>
              </Col>

              <Row
                style={{ marginTop: "2rem", position: "relative", right: 6 }}
              >
                <Col span={24}>
                  <List
                    dataSource={paymentData}
                    renderItem={(item: any) => (
                      <li>
                        <Row wrap={false} align="top">
                          {/* <S.CheckIcon /> */}
                          <img
                            src={image}
                            alt="test"
                            className="bg-img"
                            style={{
                              width: "13px",
                              height: "13px",
                              marginLeft: 2,
                              marginTop: 1,
                            }}
                          />
                          <S.Text
                            style={{
                              fontWeight: "600",
                              color: "#000",
                              marginLeft: 5,
                            }}
                          >
                            {item?.name.toUpperCase()}{" "}
                            {" *****" + item?.last4Digits}{" "}
                            {item?.isDefaultPaymentMethod ? (
                              <S.Text
                                style={{
                                  marginLeft: "10px",
                                  border: "1.5px solid #edebf2",
                                  borderRadius: 6,
                                  backgroundColor: "#f1f2ff",
                                }}
                              >
                                {" "}
                                <text
                                  style={{
                                    color: "#2199e8",
                                    cursor: "pointer",
                                    fontSize: 10,
                                    textAlign: "center",
                                    marginLeft: 5,
                                    marginRight: 5,
                                  }}
                                >
                                  {" "}
                                  Default
                                </text>
                              </S.Text>
                            ) : (
                              ""
                            )}
                          </S.Text>
                          <div
                            style={{
                              display: "flex",
                              marginLeft: "auto",
                              marginRight: 12,
                              position: "relative",
                              bottom: 5,
                            }}
                          >
                            {!item?.isDefaultPaymentMethod && (
                              <ButtonTertiary
                                style={{
                                  background: "#01083A",
                                  cursor: "pointer",
                                  height: 25,
                                  borderRadius: 5,
                                  border: "none",
                                  outline: "none",
                                  margin: "0 auto",
                                }}
                                onClick={() => handleDefault(item)}
                              >
                                <Text style={{ fontSize: 10 }}>
                                  {"Set as default"}
                                </Text>
                              </ButtonTertiary>
                            )}
                          </div>
                        </Row>
                      </li>
                    )}
                  ></List>
                </Col>
              </Row>
            </Spin>
          </>
        )}
      </>
    </Modal>
  );
};
export default ChangePaymentMethodModal;
