import styled, { css } from "styled-components";
import { Col } from "antd";
import { Icon } from "components";

const isFeatureStyles = css`
  justify-content: space-between;
`;

export const Wrapper = styled(Col)<{ isFeature?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({ isFeature }) => (isFeature ? isFeatureStyles : null)}
  background: linear-gradient(
    109.04deg,
    rgba(255, 255, 255, 0.63) -23.19%,
    rgba(255, 255, 255, 0.09) 101.71%
  );
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  box-shadow: 0px 11px 18px -5px rgba(1, 8, 58, 0.04);
  backdrop-filter: blur(32px);
  /* Note: backdrop-filter has minimal browser support */
  padding: 5px 20px 0 20px;
  border-radius: 20px;
  overflow: hidden;
  height: 100%;
`;

export const HeaderSection = styled.div<{ titlePadding?: number }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex: 0 auto;
  /* margin-bottom: 20px; */
  padding: 10px 0px
    ${({ titlePadding }) => (titlePadding ? `${titlePadding}px` : "0")};
`;

export const Header = styled.div<{ titlePadding?: number }>`
  display: flex;
  align-items: center;
`;

export const CardIcon = styled(Icon)`
  font-size: 22px;
  margin: 0 3px 5px 0;
`;
