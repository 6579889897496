import React, { ImgHTMLAttributes } from "react";

import logoDark from "./lluna-logo.svg";
import logoLight from "./lluna-logo-light.svg";

import { Image } from "./logo.styles";

type Props = {
  light?: boolean;
} & ImgHTMLAttributes<HTMLImageElement>;

const Logo = ({ light, ...props }: Props): React.ReactElement => (
  <Image src={light ? logoLight : logoDark} {...props} />
);

export default Logo;
