import { Placeholder, FeatureType } from "codegen/generated/graphql";
import { ChoicesPlaceholders } from "constants/miscellaneous";

export const featureTypeString = (type: FeatureType): string => {
  switch (type) {
    case FeatureType.TextSingleLine:
      return "Text-Single-Line";
    case FeatureType.TextMultiLine:
      return "Text-Multi-Line";
    case FeatureType.SingleSelectRadio:
      return "Single-Select-Radio";
    case FeatureType.MultipleSelectCheckboxes:
      return "Multiple-Select-Checkboxes";
    case FeatureType.SingleSelectDropdown:
      return "Single-Select-Dropdown";
    case FeatureType.MultipleSelectDropdown:
      return "Multiple-Select-Dropdown";

    default:
      return "";
  }
};
export type FeaturePlaceholder = {
  id: Placeholder;
  placeholderValue: ChoicesPlaceholders;
};

export const functionListForProfile = [
  {
    label: "HR",
    value: "HR",
  },
  {
    label: "R&D",
    value: "R&D",
  },
  {
    label: "Sales",
    value: "Sales",
  },
  {
    label: "IT",
    value: "IT",
  },
  {
    label: "Finance",
    value: "Finance",
  },
  {
    label: "Engineering",
    value: "Engineering",
  },
  {
    label: "Strategy",
    value: "Strategy",
  },

  {
    label: "Marketing",
    value: "Marketing",
  },
  {
    label: "Product",
    value: "Product",
  },
  {
    label: "Design",
    value: "Design",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const timeZoneListForProfile = [
  {
    label: "Eastern Time (UTC -4:00)",
    value: "Eastern Time (UTC -4:00)",
  },
  {
    label: "Central Time (UTC -5:00)",
    value: "Central Time (UTC -5:00)",
  },
  {
    label: "Mountain Time (UTC -6:00)",
    value: "Mountain Time (UTC -6:00)",
  },
  {
    label: "Pacific Time (UTC -7:00)",
    value: "Pacific Time (UTC -7:00)",
  },
  {
    label: "Alaska Time (UTC -8:00)",
    value: "Alaska Time (UTC -8:00)",
  },
  {
    label: "GMT Standard Time (UTC)",
    value: "GMT Standard Time (UTC)",
  },
  {
    label: "South America Standard Time (UTC -2:00)",
    value: "South America Standard Time (UTC -2:00)",
  },

  {
    label: "Other Time Zone",
    value: "Other Time Zone",
  },
];

export const industryListForProfile = [
  {
    label: "Aerospace",
    value: "Aerospace",
  },
  {
    label: "Agriculture",
    value: "Agriculture",
  },
  {
    label: "Biotech/Pharma",
    value: "Biotech/Pharma",
  },
  {
    label: "Education",
    value: "Education",
  },
  {
    label: "Energy",
    value: "Energy",
  },
  {
    label: "Entertainment",
    value: "Entertainment",
  },
  {
    label: "Financial services",
    value: "Financial services",
  },

  {
    label: "Food and beverage",
    value: "Food and beverage",
  },
  {
    label: "Hospitality",
    value: "Hospitality",
  },
  {
    label: "Manufacturing",
    value: "Manufacturing",
  },
  {
    label: "Technology",
    value: "Technology",
  },
  {
    label: "Telecom",
    value: "Telecom",
  },
  {
    label: "Transportation",
    value: "Transportation",
  },
  {
    label: "Supply chain/logistics",
    value: "Supply chain/logistics",
  },
];

export const userName = (
  name: string
): { firstName: string; lastName: string } => {
  const userName: any = name.split(" ");
  const obj: any = {
    firstName: userName[0],
    lastName:
      userName.length > 2
        ? userName.slice(1, userName.length).join(" ")
        : userName[1],
  };
  return obj;
};
