import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { AdminMenu, TeamMemberMenu } from "config/menu";
import { RightOutlined, LeftOutlined, MenuOutlined } from "@ant-design/icons";
import * as S from "./sidebar.styles";
import { AuthContext } from "utils/context/auth";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { Popover } from "antd";
import { useMeQuery, UserRole } from "codegen/generated/graphql";
import { Icon } from "../Icon";
import { generatePath } from "react-router";
import { Routes } from "router/routes";
import { PREVIEW_LINK } from "config/menu/common";
import { getCookie } from "utils/cookie";

const Sidebar: React.FC = () => {
  /*
   * @archived
   * While adding new menu items, be careful about key numbers, they must be incremented by the one compared to
   * previous menu item in order to follow AntdDesign api rules(even in submenu)
   */

  const [selectedKey, setSelectedKey] = useState("Home");
  const [collapsed, setCollapsed] = useState(false);
  const [isvisible, setVisible] = useState<any>(false);
  const showPreviewLink = useMemo(() => getCookie("inPreview") === "true", []);

  const history = useHistory();
  // const { data, loading } = useMyWorkDesignCollectionQuery();

  /*
   * @archived
   * const pendingWorkDesign = data?.me?.workDesigns.some((wd) =>
   *   wd.status.includes(WdStatus.Pending)
   * );
   */

  const { user } = useContext(AuthContext);

  const userIsAdmin = user?.roles.includes(UserRole.LlunaAdmin);
  const userRoleIsAdmin = user?.roles.includes(UserRole.Admin);
  const userIsPrimaryAdmin = user?.roles.includes(UserRole.PrimaryAdmin);
  /*
   * @archived
   * const userIsTeamManager = user?.roles.includes(UserRole.TeamManager);
   * const userIsTeamMember = user?.roles.includes(UserRole.Member);
   */
  const { data: meQuery } = useMeQuery();

  const createWDDisabled = (isCreateWorkDesignItem: boolean) => {
    /*
     * @archived
     * if (loading) return true;
     * if (!pendingWorkDesign && isCreateWorkDesignItem) return true;
     */
    if (userIsPrimaryAdmin || userIsAdmin || userRoleIsAdmin) return false;
    // if ((!userIsTeamManager && pendingWorkDesign) || isCreateWorkDesignItem)
    return false;
    return true;
  };

  //const selectedRole = useReactiveVar(selectedRoleVar);

  const getMenuConfig = useCallback(() => {
    if (userIsAdmin) return AdminMenu;
    /*
     * @archived
     * if (
     *   userIsPrimaryAdmin ||
     *   userIsTeamManager ||
     *   userIsTeamMember ||
     *   userRoleIsAdmin
     * ) {
     *   return selectedRole?.id === UserRole.TeamMember
     *     ? TeamMemberMenu()
     *     : TeamManagerMenu;
     * }
     */
    return TeamMemberMenu({ meQuery });
  }, [userIsAdmin, meQuery]);

  const menu = getMenuConfig().filter((item) => item !== undefined);

  if (showPreviewLink) {
    menu.push(PREVIEW_LINK);
  }

  const { pathname } = useLocation();
  const { xs } = useBreakpoint();

  useEffect(() => {
    menu.some((value: any) => {
      const { path, key, submenu } = value;
      const checkSubmenu = () =>
        submenu?.some((subItem: any) => {
          if (subItem.path === pathname) {
            setSelectedKey(subItem.key);
            return true;
          }
          return false;
        });
      if (typeof path !== "string") return false;
      const rootPath = (path as string).replace("/:id", "");

      if (
        (!!path && path === pathname) ||
        (!!path && pathname.includes(rootPath))
      ) {
        if (checkSubmenu()) return true;
        setSelectedKey(key);
        return true;
      } else return checkSubmenu();
    });
  }, [menu, pathname]);

  const handleMenuClick = (path: string) => {
    history.replace(path);
  };

  window.addEventListener("click", (e: any) => {
    setVisible(false);
  });

  if (xs) {
    return (
      <Popover
        placement="bottom"
        trigger="hover | click"
        color="#fff"
        visible={isvisible}
        overlayInnerStyle={{ width: "fit-content" }}
        title={
          <>
            <S.UIMenu
              visible={collapsed}
              mode="inline"
              defaultSelectedKeys={["home"]}
              selectedKeys={[selectedKey]}
            >
              {menu.map(
                ({
                  key,
                  title,
                  path,
                  activeIcon,
                  inactiveIcon,
                  submenu,
                  target,
                }: any) => {
                  const isSelected = selectedKey.split("/")?.[0] === key;
                  // Logic below because we want to be redirected on profile member page from sidebar (id in url needed)
                  let myProfilePath: string | null = null;
                  if (key === "my-profile" && user) {
                    myProfilePath = generatePath(path as string, {
                      id: user?.id,
                    });
                  }
                  if (!submenu)
                    return (
                      <S.UIMenuItem
                        onClick={() => setVisible(false)}
                        key={key}
                        $active={isSelected}
                        icon={
                          <Icon name={isSelected ? activeIcon : inactiveIcon} />
                        }
                      >
                        <Link to={myProfilePath ?? path} target={target}>
                          {title}
                        </Link>
                      </S.UIMenuItem>
                    );
                  return (
                    <S.UISubMenu
                      key={title}
                      icon={
                        <Icon name={isSelected ? activeIcon : inactiveIcon} />
                      }
                      title={title}
                      onTitleClick={() =>
                        !!path && handleMenuClick(path as string)
                      }
                    >
                      {submenu.map((subItem: any) => {
                        return (
                          <S.UIMenuItem
                            key={subItem.key}
                            disabled={createWDDisabled(
                              subItem.path === Routes.WORK_DESIGN.path
                            )}
                          >
                            <Link to={subItem.path}>{subItem.title}</Link>
                          </S.UIMenuItem>
                        );
                      })}
                    </S.UISubMenu>
                  );
                }
              )}
            </S.UIMenu>
          </>
        }
      >
        <MenuOutlined
          style={{
            position: "fixed",
            left: "0.5rem",
            zIndex: "99",
            top: "70px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setVisible(!isvisible);
          }}
        />
      </Popover>
    );
  }
  return (
    <S.UISidebar trigger={false} collapsed={collapsed}>
      <div
        style={{
          display: "flex",
          paddingLeft: "1rem",
        }}
      >
        <S.IconBackground>
          {React.createElement(collapsed ? RightOutlined : LeftOutlined, {
            className: "trigger",
            onClick: () => setCollapsed(!collapsed),
          })}
        </S.IconBackground>
      </div>
      <S.UIMenu
        visible={collapsed}
        mode="inline"
        defaultSelectedKeys={["home"]}
        selectedKeys={[selectedKey]}
      >
        {menu.map(
          ({
            key,
            title,
            path,
            activeIcon,
            inactiveIcon,
            submenu,
            target,
          }: any) => {
            const isSelected = selectedKey.split("/")?.[0] === key;

            // Logic below because we want to be redirected on profile member page from sidebar (id in url needed)
            let myProfilePath: string | null = null;
            if (key === "my-profile" && user) {
              myProfilePath = generatePath(path as string, { id: user?.id });
            }
            if (!submenu)
              return (
                <S.UIMenuItem
                  key={key}
                  $active={isSelected}
                  icon={<Icon name={isSelected ? activeIcon : inactiveIcon} />}
                >
                  <Link to={myProfilePath ?? path} target={target}>
                    {title}
                  </Link>
                </S.UIMenuItem>
              );
            return (
              <S.UISubMenu
                key={title}
                icon={<Icon name={isSelected ? activeIcon : inactiveIcon} />}
                title={title}
                onTitleClick={() => !!path && handleMenuClick(path as string)}
              >
                {submenu.map((subItem: any) => {
                  return (
                    <S.UIMenuItem
                      key={subItem.key}
                      disabled={createWDDisabled(
                        subItem.path === Routes.WORK_DESIGN.path
                      )}
                    >
                      <Link to={subItem.path}>{subItem.title}</Link>
                    </S.UIMenuItem>
                  );
                })}
              </S.UISubMenu>
            );
          }
        )}
      </S.UIMenu>
      {/* TODO: Will be part of next versions */}
      {/* {userIsTeamManager && <PlanProgress />} */}
    </S.UISidebar>
  );
};

export default Sidebar;
