import styled from "styled-components";

export const Container = styled.div`
  overflow: auto;
  width: 100%;
  display: inline-grid;
  padding: 0 10px 10px 10px;

  @media (max-width: 640px) {
    padding: 0 20px 10px 10px;
    max-height: 100%;
  }
`;
