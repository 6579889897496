import { Row, Col } from "antd";
import { useState } from "react";
import { Plan } from "types/plan";
import { PlanItem } from "./PlanItem";

type SelectPlanProps = {
  plans: any;
  action: string;
  accountSubscription: any;
};
export const SelectPlan: React.FC<SelectPlanProps> = ({
  plans,
  action,
  accountSubscription,
}: {
  plans: any;
  action: string;
  accountSubscription: any;
}) => {
  const [modalContent, setModalContent] = useState<any>("");
  const [active, setActive] = useState<Plan | null>(
    plans?.subscriptionPlans.find((plan: any) => plan.isActive) || null
  );

  const defaultContent = (
    <Row gutter={[18, 18]}>
      {plans?.subscriptionPlans?.map((plan: any, idx: any) => (
        <Col xs={24} lg={8} style={{ display: "flex" }} key={idx}>
          <PlanItem
            plan={plan}
            onPlanSelect={setActive}
            isActive={plan === active}
            index={idx}
            previousPlan={plans?.subscriptionPlans[idx - 1]?.name || ""}
            setModalContent={setModalContent}
            action={action}
            selectedPlan={plan.id === accountSubscription?.plan?.plan?.id}
          />
        </Col>
      ))}
    </Row>
  );
  return <>{modalContent || defaultContent}</>;
};
