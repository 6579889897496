import { useState, useMemo, ReactElement } from "react";
import { List, Row, Space, notification } from "antd";
import Button from "components/Button";
import { RadioGroup } from "components/Radio";
import Wrapper from "components/Wrapper";
import * as S from "./Subscribe.styles";
import { Plan } from "types/plan";
import { planMutations } from "store/plan";
import { Routes } from "router/routes";
import { Icon } from "../../../components";
import { PlanSummary } from "./PlanSummary";
import { history } from "router/history";
import Premier from "./Premier";
import {
  useCreateSubscriptionForAccountMutation,
  MeDocument,
  useChangeSubscriptionPlanMutation,
  AccountSubscriptionDocument,
} from "codegen/generated/graphql";
import SendEmail from "./SendEmail";
import Confirmation from "./Confirmation";
import { ApolloError } from "@apollo/client";
//const { Panel } = Collapse;
type PlanItemProps = {
  plan: any;
  onPlanSelect: (plan: Plan) => void;
  isActive: boolean;
  index: number;
  previousPlan: any;
  setModalContent: any;
  action: string;
  selectedPlan: boolean;
};

export const PlanItem = ({
  plan,
  isActive,
  index,
  previousPlan,
  setModalContent,
  action,
  selectedPlan,
}: PlanItemProps): ReactElement => {
  const { updatePlan } = planMutations;
  const [createSubscriptionForAccount] =
    useCreateSubscriptionForAccountMutation();
  const [changeSubscription] = useChangeSubscriptionPlanMutation();

  const [frequency] = useState(0);

  /*
   * @archived
   * const priceId: any = {
   *   Free: "",
   *   "Annual-plus": "Anually",
   *   "Monthly-plus": "Monthly",
   *   "Luminary-plus": "Luminary",
   *   "Annual-Premier": "Anually",
   * };
   */
  const onFreePlanHandler = async () => {
    await createSubscriptionForAccount({
      variables: {
        input: {
          planType: "Free",
          paymentMethodId: "",
          planPriceId: "",
          quantity: 0,
        },
      },
      refetchQueries: [MeDocument],
      awaitRefetchQueries: true,
    });
    history.push(Routes.DASHBOARD.path);
  };
  const options = useMemo(() => {
    return plan?.prices
      ?.filter((item: any) => item?.isActive)
      .map((item: any, i: number) => ({
        value: i,
        label:
          plan?.prices?.length < 2 ? (
            <div
              style={{ display: "flex", justifyContent: "center", gap: "2px" }}
            >
              <span style={{ fontSize: "42px" }}>Free</span>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "42px", position: "relative" }}>
                ${item?.pricePerUnit}
                <sub
                  style={{
                    fontSize: "13px",
                    position: "absolute",
                    bottom: "0px",
                    lineHeight: "13px",
                    width: "max-content",
                    marginLeft: "0.5rem",
                    textAlign: "left",
                    height: "max-content",
                  }}
                >
                  {item?.isActive === true ? (
                    <span>
                      {item?.meta?.intervalText.split("/")[0] + "/"} <br />
                      {item?.meta?.intervalText.split("/")[1]}
                    </span>
                  ) : null}
                </sub>
              </span>{" "}
              <span
                style={{
                  fontSize: "12px",
                  position: "absolute",
                  bottom: "-5px",
                }}
              >
                <i>{item?.meta?.billingText}</i>
              </span>
            </div>
          ),
      }));
  }, [plan]);

  /*
   * @archived
   * const onFrequencyChange = (e: RadioChangeEvent) => {
   *   setFrequency(e.target.value);
   *   onPlanSelect(plan);
   * };
   */

  const onPlanSelectHandler = (plan: Plan) => {
    updatePlan(plan, frequency);
    setModalContent(
      <PlanSummary planData={plan} setModalContent={setModalContent} />
    );
  };

  const onSelectFreePlan = (plan: any) => {
    changeSubscription({
      variables: {
        input: {
          additionalInfo: "",
          estimatedTeamSize: 2,
          planId: plan?.id,
          planType: plan?.name,
        },
      },
      refetchQueries: [MeDocument, AccountSubscriptionDocument],
      awaitRefetchQueries: true,
    })
      .then((res: any) => {
        setModalContent(<Confirmation action={action} plan={plan} />);
      })
      .catch((err: ApolloError) => {
        notification.error({
          type: "warning",
          message: err.message.split("[")[0],
          placement: "bottomLeft",
        });
      });
  };

  return (
    <S.Card
      style={{
        backgroundColor:
          selectedPlan || (action === "Upgrade" && index === 0)
            ? "#e1e1e1"
            : "none",
        marginTop: 10,
      }}
    >
      {index === 1 && (
        <p
          style={{
            display: "flex",
            justifyContent: "flex-end",
            background: "#FFC05B",
            position: "absolute",
            top: 1,
            right: 1,
            padding: "0.5rem 1rem",
            borderTopRightRadius: "1rem",
            borderBottomLeftRadius: "1rem",
          }}
        >
          Most purchased plan
        </p>
      )}
      <S.Title level={3}>
        {plan.name === "Free" ? "Individual" : plan.name}
      </S.Title>
      <S.Paragraph>
        {plan?.meta?.summary ||
          "Ideal for those who are quickly looking to create and share their own readme"}
      </S.Paragraph>
      <Wrapper margin="0 0 1.75rem 0">
        {options.length > 0 ? (
          <>
            <Wrapper margin="0.75rem 0 1.25rem 0">
              <RadioGroup
                name="frequency"
                options={options}
                value={isActive}
                // onChange={onFrequencyChange}
                optionType="button"
              ></RadioGroup>
            </Wrapper>
          </>
        ) : (
          <Space direction="vertical" size="small">
            <S.TextTitle strong>Custom Pricing</S.TextTitle>
            <S.Text style={{ fontSize: 15 }}>
              Based on the total size of the company
            </S.Text>
          </Space>
        )}
      </Wrapper>
      <>
        <p style={{ display: "flex", gap: "4px", alignItems: "center" }}>
          {index > 0 ? (
            <>
              <Icon name={"leftArrowBlue"} />
              Everything From {previousPlan}
            </>
          ) : (
            ""
          )}
        </p>
        <List
          dataSource={plan?.meta?.features}
          renderItem={(item: string) => (
            <li>
              <Row wrap={false} align="top">
                <S.CheckIcon />
                <S.Text>{item}</S.Text>
              </Row>
            </li>
          )}
        ></List>
      </>
      <div style={{ flexGrow: 1, marginBottom: "20px" }}></div>
      <Button
        disabled={selectedPlan || (action === "Upgrade" && index === 0)}
        type="primary"
        $bgColor={true ? "#01083A" : "#62798D"}
        size="large"
        $height="48px"
        onClick={() => {
          if (index === 0 && action === "Subscription") {
            onSelectFreePlan(plan);
          } else if (index === 0) {
            onFreePlanHandler();
          }
          if (index === 1) {
            onPlanSelectHandler(plan);
          }
          if (index === 2) {
            setModalContent(
              <Premier
                setModalContent={setModalContent}
                planData={plan}
                action={action}
              />
            );
          }
          if (action === "Subscription" && !selectedPlan && index === 2) {
            setModalContent(
              <SendEmail
                setModalContent={setModalContent}
                planData={plan}
                action={action}
              />
            );
          }
        }}
      >
        {(index === 0 && action === "Subscription") || index === 2
          ? "Contact LLUNA"
          : selectedPlan || (action === "Upgrade" && index === 0)
          ? "Current Plan"
          : "Select Plan"}
      </Button>
    </S.Card>
  );
};
