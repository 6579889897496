import { FC, SVGProps, useEffect, useState } from "react";

import { IconName } from "./generated";
import { useTheme } from "styled-components";
import { Theme } from "theme/styled";

function useDynamicSVGImport(name: string) {
  const [Icon, setIcon] = useState<FC<SVGProps<SVGSVGElement>>>();

  useEffect(() => {
    let shouldSet = true;
    const importIcon = () => {
      import(`!!@svgr/webpack?-svgo,+titleProp,+ref!assets/icons/${name}.svg`)
        .then((svg) => {
          if (shouldSet) setIcon(svg.default);
        })
        .catch(console.warn);
    };
    importIcon();

    return () => void (shouldSet = false);
  }, [name]);

  return { SvgIcon: Icon };
}

export type IconProps = {
  name: IconName;
  themeColor?: keyof Theme["colors"];
} & SVGProps<SVGSVGElement>;

export const Icon: FC<IconProps> = ({ name, themeColor, ...props }) => {
  const { SvgIcon } = useDynamicSVGImport(name);
  // eslint-disable-next-line
  // @ts-ignore
  const { colors } = useTheme();

  const color = themeColor ? colors[themeColor] : props.color ?? "";

  if (!SvgIcon) return null;
  return <SvgIcon color={color} width="1em" height="1em" {...props} />;
};
