// @TODO Technical Debt: Commented out old menu tabs (frameworks, work design, my profile, reports)

/*
 * @archived
 * import { Routes } from "router/routes";
 * import { IAuthContext } from "utils/context/auth";
 * import { useMeQuery } from "codegen/generated/graphql";
 */

import { DASHBOARD, HELP_CENTER, MY_POP, BOARDS, SETTINGS } from "./common";

/*
 * @archived
 * const FRAMEWORKS = menuItem({
 *   key: "frameworks",
 *   title: "Team Frameworks",
 *   activeIcon: "eclipseYellow",
 *   inactiveIcon: "eclipseGrey",
 *   path: Routes.FRAMEWORKS_COLLECTION.path,
 * });
 */

/*
 * @archived
 * const WORK_DESIGN = menuItem({
 *   key: "work-design",
 *   title: "Work Design",
 *   activeIcon: "eclipseYellow",
 *   inactiveIcon: "eclipseGrey",
 *   path: Routes.WORK_DESIGNS.path,
 * });
 */

/*
 * @archived
 * const MY_PROFILE = menuItem({
 *   key: "my-profile",
 *   title: "My Profile",
 *   activeIcon: "profileYellow",
 *   inactiveIcon: "profileGrey",
 *   path: Routes.MY_PROFILE.path,
 * });
 */

/*
 * @archived
 * const REPORTS = menuItem({
 *   key: "reports",
 *   title: "Reports",
 *   activeIcon: "reportsYellow",
 *   inactiveIcon: "reportsGrey",
 *   path: Routes.REPORTS.path,
 * });
 */

export const TeamMemberMenu = (props: { meQuery: any }): Array<any> => {
  const { meQuery } = props;

  return [
    DASHBOARD,
    /*
     * @archived
     * README,
     * user?.roles[0] === UserRole.TeamMember &&
     * user?.additionalPermissions.includes(
     *   UserAdditionalPermissions.FrameworkManager
     * )
     *   ? {
     *       ...FRAMEWORKS,
     *       submenu: [
     *         {
     *           key: Routes.FRAMEWORKS_CREATE_NEW.path,
     *           title: "New Framework",
     *           path: Routes.FRAMEWORKS_CREATE_NEW.path,
     *         },
     *         {
     *           key: Routes.FRAMEWORKS_COLLECTION.path,
     *           title: "Collection",
     *           path: Routes.FRAMEWORKS_COLLECTION.path,
     *         },
     *       ],
     *     }
     *   : undefined,
     * {
     *   ...WORK_DESIGN,
     *   submenu: [
     *     {
     *       key: Routes.WORK_DESIGN.path,
     *       title: "Personalize",
     *       path: Routes.WORK_DESIGN.path,
     *     },
     *     {
     *       key: Routes.WORK_DESIGNS.path,
     *       title: "History",
     *       path: Routes.WORK_DESIGNS.path,
     *     },
     *   ],
     * },
     * MY_PROFILE,
     * user?.roles[0] === UserRole.TeamMember &&
     * user?.additionalPermissions.includes(UserAdditionalPermissions.ReportViewer)
     *   ? REPORTS
     *   : undefined,
     */
    MY_POP,
    !meQuery?.me?.onFreePlan ? BOARDS : undefined,
    // BOARDS,
    SETTINGS,
    HELP_CENTER,
  ];
};
