import { Wrapper, Icon, ButtonTertiary } from "components";
import { FC, useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import theme from "theme";
import { Tabs, Typography } from "antd";
import { AccountPasswordChange } from "pages/my-team/profile";
import MemberProfile from "./components/MemberProfile";
import {
  useAccountSubscriptionQuery,
  useMeQuery,
  UserRole,
} from "codegen/generated/graphql";
import { AuthContext } from "utils/context/auth";
import Integrations from "pages/my-team/profile/Integrations";
import ManageSubscription from "pages/my-team/profile/ManageSubscription";
import * as S from "../../../boards/styles";
import TeamMembers from "../TeamMembers.page";
import StripeModal from "pages/plan/stripeModal";
const { Title, Text } = Typography;
//const { Link } = Anchor;

const Member: FC = (props: any) => {
  const { data: meQuery } = useMeQuery();
  //const history = useHistory();
  const [planModal, setPlanModal] = useState(false);
  const [activeTab, setActiveTab] = useState(
    props?.location?.state?.data ? props?.location.state.data : "0"
  );
  const { data: subsData } = useAccountSubscriptionQuery();
  const { id } = useParams<{ id: string; state: any }>();
  const { user } = useContext(AuthContext);
  const isMeMember = useMemo(() => id === user?.id, [id, user]);
  const isPrimaryAdmin = user?.roles.includes(UserRole.PrimaryAdmin);
  const isAdmin = user?.roles.includes(UserRole.Admin);
  const accountSubsData = subsData?.accountSubscription;

  const items = [
    {
      itemId: "0",
      title: "My Profile",
      icon: <Icon name={"profileBlue"} />,
      activeIcon: "profileBlue",
      inactiveIcon: "profileGrey",
      isDisabled: false,
      path: (
        <MemberProfile
          isManager={isPrimaryAdmin || isAdmin}
          isMeMember={isMeMember}
          selectedUser={""}
          setShowProfile
        />
      ),
    },
    {
      itemId: "1",
      title: "Account & Password",
      icon: <Icon name={"accountDark"} />,
      activeIcon: "accountDark",
      inactiveIcon: "accountLight",
      isDisabled: false,
      path: <AccountPasswordChange />,
    },

    {
      itemId: "2",
      title: "User Management",
      icon: <Icon name={"notification"} />,
      activeIcon: "accountDark",
      inactiveIcon: "accountLight",
      isDisabled: false,
      path: <TeamMembers />,
    },
    {
      itemId: "3",
      title: "Integrations",
      icon: <Icon name={"notification"} />,
      activeIcon: "accountDark",
      inactiveIcon: "accountLight",
      isDisabled: false,
      path: <Integrations />,
    },
    {
      itemId: "4",
      title: "Subscription & Billing",
      icon: <Icon name={"billing"} />,
      activeIcon: "accountDark",
      inactiveIcon: "accountLight",
      isDisabled: isPrimaryAdmin || isAdmin ? false : true,
      path: <ManageSubscription />,
    },
  ];

  const freePlanItems = [
    {
      itemId: "0",
      title: "My Profile",
      icon: <Icon name={"profileBlue"} />,
      activeIcon: "profileBlue",
      inactiveIcon: "profileGrey",
      isDisabled: false,
      path: (
        <MemberProfile
          isManager={isPrimaryAdmin || isAdmin}
          isMeMember={isMeMember}
          selectedUser={""}
          setShowProfile
        />
      ),
    },
    {
      itemId: "1",
      title: "Account & Password",
      icon: <Icon name={"accountDark"} />,
      activeIcon: "accountDark",
      inactiveIcon: "accountLight",
      isDisabled: false,
      path: <AccountPasswordChange />,
    },
  ];

  const handleUpgrade = () => {
    return (
      <div
        style={{
          display: "contents",
        }}
      >
        <ButtonTertiary
          htmlType="default"
          style={{
            background: "#01083A",
            width: "155px",
            height: "Hug (36px)",
            border: "none",
            outline: "none",
            margin: "0 auto",
            marginTop: 10,
          }}
          onClick={() => handlePlanModal()}
        >
          <Text strong>Upgrade plan </Text>
        </ButtonTertiary>
      </div>
    );
  };

  const handleReturn = () => {
    if (meQuery?.me?.onFreePlan || (!isAdmin && !isPrimaryAdmin)) {
      return freePlanItems.map((item, i) => (
        <Tabs.TabPane
          tab={
            <span style={{ color: "#62798d" }}>
              {
                <span
                  style={{
                    marginLeft: 5,
                    marginRight: 5,
                  }}
                >
                  {item.icon}
                </span>
              }
              {
                <span
                  style={{
                    marginLeft: 5,
                    marginRight: 5,
                  }}
                >
                  {item.title}{" "}
                </span>
              }
            </span>
          }
          key={item.itemId}
          disabled={item.isDisabled}
        >
          <div
            style={{
              display: "flex",
              marginLeft: "auto",
            }}
          >
            <Title
              style={{
                fontWeight: "400",
                fontSize: 26,
                marginTop: "1rem",
                marginBottom: "3rem",
              }}
            >
              {item.title}
            </Title>
            {item.itemId === "0" && meQuery?.me?.onFreePlan && handleUpgrade()}
          </div>
          {item.path}
        </Tabs.TabPane>
      ));
    } else {
      return items.map((item, i) => (
        <Tabs.TabPane
          tab={
            <span style={{ color: "#62798d" }}>
              <span
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                {item.icon}
              </span>

              <span
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                {item.title}
              </span>
            </span>
          }
          key={item.itemId}
          disabled={item.isDisabled}
        >
          <div
            style={{
              display: "flex",
              marginLeft: "auto",
            }}
          >
            <Title
              style={{
                fontWeight: "400",
                fontSize: 26,
                marginTop: "1rem",
                marginBottom: "3rem",
              }}
            >
              {item.title}
            </Title>
            {item.itemId === "0" && meQuery?.me?.onFreePlan && handleUpgrade()}
          </div>
          {item.path}
        </Tabs.TabPane>
      ));
    }
  };

  const handlePlanModal = () => {
    setPlanModal(true);
  };

  const handleTabClick = (key: any) => {
    if (key === "2") {
      // history.push(`/my-team/members`);
    }
    setActiveTab(key);
  };

  return meQuery?.me ? (
    <Wrapper
      height="84.5vh"
      width="100%"
      // gradientBg="linear-gradient(108.84deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.37) 101.71%);"
      radius={theme.radius.commonRadius}
    >
      <Tabs
        defaultActiveKey="0"
        activeKey={activeTab}
        onChange={(key) => {
          handleTabClick(key);
        }}
      >
        {handleReturn()}
      </Tabs>
      {planModal && (
        <StripeModal
          isStripeModal={planModal}
          setPlanModal={setPlanModal}
          accountSubscription={accountSubsData}
          action={"Upgrade"}
        />
      )}
    </Wrapper>
  ) : (
    <S.Spinner size="large" />
  );
};

export default Member;
