import React from "react";
import { Space } from "antd";
import Text from "antd/lib/typography/Text";

import TabsBar from "components/TabsBar";
import { Radio } from "components/Radio";
import { Input, Wrapper, Checkbox } from "components";

import * as S from "./styles";
import { FeatureType, Maybe } from "codegen/generated/graphql";
import { featureTypeString } from "utils/helpers";
import { useFeatureContext } from "../context";

const options = [
  FeatureType.SingleSelectRadio,
  FeatureType.MultipleSelectCheckboxes,
  FeatureType.SingleSelectDropdown,
  FeatureType.TextSingleLine,
  FeatureType.TextMultiLine,
  FeatureType.MultipleSelectDropdown,
];

/*
 * @archived
 * const QuestionReportConfigContent = () => {
 *   const {
 *     onChange,
 *     state: {
 *       data: { hasReport, reportKey = "" },
 *     },
 *   } = useFeatureContext();
 */

/*
 * @archived
 *   return (
 *     <>
 *       <Wrapper margin="0.5rem 0 1.5rem 0">
 *         <Text strong>Reporting*</Text>
 *       </Wrapper>
 *       <Space direction="vertical">
 *         <Checkbox
 *           label="Is reportable?"
 *           checked={!!hasReport}
 *           onChange={(e) => onChange("hasReport")(e.target.checked)}
 *         />
 *         <Input
 *           hidden={!hasReport}
 *           name="reportKey"
 *           placeholder="Report key"
 *           value={reportKey ?? ""}
 *           onChange={(e) => onChange("reportKey")(e.target.value)}
 *         />
 *       </Space>
 *     </>
 *   );
 * };
 */

const QuickStartCheckbox = () => {
  const {
    onChange,
    state: {
      data: { quickStart },
    },
  } = useFeatureContext();

  return (
    <>
      <Wrapper margin="0.5rem 0 0.5rem 0">
        <Text strong>Quick Start</Text>
      </Wrapper>
      <Space direction="vertical">
        <Checkbox
          label="Is quick start question?"
          checked={!!quickStart}
          onChange={(e) => onChange("quickStart")(e.target.checked)}
        />
      </Space>
    </>
  );
};

const ShowAsTagCheckbox = () => {
  const {
    onChange,
    state: {
      data: { showAsTagTF },
    },
  } = useFeatureContext();

  return (
    <>
      <Wrapper margin="0.5rem 0 0.5rem 0">
        <Text strong>Show As Tag</Text>
      </Wrapper>
      <Space direction="vertical">
        <Checkbox
          label="Show as tag on public POP?"
          checked={!!showAsTagTF}
          onChange={(e) => onChange("showAsTagTF")(e.target.checked)}
        />
      </Space>
    </>
  );
};

const DefinitionBoolean = () => {
  const {
    onChange,
    state: {
      data: { definitionTF },
    },
  } = useFeatureContext();

  return (
    <>
      <Space direction="vertical">
        <Checkbox
          label="Should the definition be displayed in the POP?"
          checked={!!definitionTF}
          onChange={(e) => onChange("definitionTF")(e.target.checked)}
        />
      </Space>
    </>
  );
};

/*
 * @archived
 * interface Props {
 *   onChangeText: (value: string) => void;
 * }
 */

const DefinitionQuestionContent: React.VFC<{
  value?: Maybe<string>;
  onChange: (val: string) => void;
}> = ({ value, onChange }) => (
  <>
    <Wrapper margin="0.5rem 0 0.5rem 0">
      <Text strong>Definition Question</Text>
    </Wrapper>
    <Input
      name="definitionQuestion"
      value={value || ""}
      onChange={(e) => onChange(e.target.value)}
    />
  </>
);

const DefinitionAnswerContent: React.VFC<{
  value?: Maybe<string>;
  onChange: (val: string) => void;
}> = ({ value, onChange }) => (
  <>
    <Wrapper margin="0.5rem 0 0.5rem 0">
      <Text strong>Definition Answer</Text>
    </Wrapper>
    <Input
      name="definitionAnswer"
      value={value ?? ""}
      onChange={(e) => onChange(e.target.value)}
    />
  </>
);

const PurposeBoolean = () => {
  const {
    onChange,
    state: {
      data: { purposeTF },
    },
  } = useFeatureContext();

  return (
    <>
      <Space direction="vertical">
        <Checkbox
          label="Should the purpose be displayed in the POP?"
          checked={!!purposeTF}
          onChange={(e) => onChange("purposeTF")(e.target.checked)}
        />
      </Space>
    </>
  );
};

const PurposeQuestionContent: React.VFC<{
  value?: Maybe<string>;
  onChange: (val: string) => void;
}> = ({ value, onChange }) => (
  <>
    <Wrapper margin="0.5rem 0 0.5rem 0">
      <Text strong>Purpose Question</Text>
    </Wrapper>
    <Input
      name="purposeQuestion"
      value={value ?? ""}
      onChange={(e) => onChange(e.target.value)}
    />
  </>
);

const PurposeAnswerContent: React.VFC<{
  value?: Maybe<string>;
  onChange: (val: string) => void;
}> = ({ value, onChange }) => (
  <>
    <Wrapper margin="0.5rem 0 0.5rem 0">
      <Text strong>Purpose Answer</Text>
    </Wrapper>
    <Input
      name="purposeAnswer"
      value={value ?? ""}
      onChange={(e) => onChange(e.target.value)}
    />
  </>
);

const LearnMoreBoolean = () => {
  const {
    onChange,
    state: {
      data: { learnMoreTF },
    },
  } = useFeatureContext();

  return (
    <>
      <Space direction="vertical">
        <Checkbox
          label="Should the Learn More link be displayed in the POP?"
          checked={!!learnMoreTF}
          onChange={(e) => onChange("learnMoreTF")(e.target.checked)}
        />
      </Space>
    </>
  );
};

const LearnMoreLabelContent: React.VFC<{
  value?: Maybe<string>;
  onChange: (val: string) => void;
}> = ({ value, onChange }) => (
  <>
    <Wrapper margin="0.5rem 0 0.5rem 0">
      <Text strong>Learn More Label</Text>
    </Wrapper>
    <Input
      name="learnMoreLabel"
      value={value ?? ""}
      onChange={(e) => onChange(e.target.value)}
    />
  </>
);

const LearnMoreUrlContent: React.VFC<{
  value?: Maybe<string>;
  onChange: (val: string) => void;
}> = ({ value, onChange }) => (
  <>
    <Wrapper margin="0.5rem 0 0.5rem 0">
      <Text strong>Learn More URL</Text>
    </Wrapper>
    <Input
      name="learnMoreUrl"
      value={value ?? ""}
      onChange={(e) => onChange(e.target.value)}
    />
  </>
);

const QuestionTypeContent: React.VFC<{
  value: string;
  onChange: (val: string) => void;
}> = ({ value, onChange }) => (
  <>
    <Wrapper margin="0.5rem 0 0.5rem 0">
      <Text strong>Question Type</Text>
    </Wrapper>
    <S.RadioGroup
      name="QuestionType"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      optionType="default"
    >
      {options.map((option) => (
        <Radio key={option} value={option}>
          {featureTypeString(option)}
        </Radio>
      ))}
    </S.RadioGroup>
  </>
);

const FeatureTitleContent: React.VFC<{
  value?: Maybe<string>;
  onChange: (val: string) => void;
}> = ({ value, onChange }) => (
  <>
    <Wrapper margin="0.5rem 0 0.5rem 0">
      <Text strong>Feature Title</Text>
    </Wrapper>
    <Input
      name="featureTitle"
      value={value ?? ""}
      onChange={(e) => onChange(e.target.value)}
    />
  </>
);

const ShortLabelContent: React.VFC<{
  value?: Maybe<string>;
  onChange: (val: string) => void;
}> = ({ value, onChange }) => (
  <>
    <Wrapper margin="0.5rem 0 0.5rem 0">
      <Text strong>Short Label</Text>
    </Wrapper>
    <Input
      name="shortLabel"
      value={value ?? ""}
      onChange={(e) => onChange(e.target.value)}
    />
  </>
);

const FeatureTextContent: React.VFC<{
  value?: Maybe<string>;
  onChange: (val: string) => void;
}> = ({ value, onChange }) => (
  <>
    <Wrapper margin="0.5rem 0 0.5rem 0">
      <Text strong>Feature Text</Text>
    </Wrapper>
    <Input
      name="featureText"
      value={value ?? ""}
      onChange={(e) => onChange(e.target.value)}
    />
  </>
);

const AdditionalCommentsContent: React.VFC<{
  value?: Maybe<string>;
  onChange: (val: string) => void;
}> = ({ value, onChange }) => (
  <>
    <Wrapper margin="0.5rem 0 0.5rem 0">
      <Text strong>Additional Comments yes/no</Text>
    </Wrapper>
    <Input
      name="additionalComments"
      value={value ?? ""}
      onChange={(e) => onChange(e.target.value)}
    />
  </>
);

const PlaceholderContent: React.VFC<{
  value?: Maybe<string>;
  onChange: (val: string) => void;
}> = ({ value, onChange }) => (
  <>
    <Wrapper margin="0.5rem 0 0.5rem 0">
      <Text strong>Placeholder Text</Text>
    </Wrapper>
    <Input
      name="placeholder"
      value={value ?? ""}
      onChange={(e) => onChange(e.target.value)}
    />
  </>
);

const ScatteredInsightContent: React.VFC<{
  value?: Maybe<string>;
  onChange: (val: string) => void;
}> = ({ value, onChange }) => (
  <>
    <Wrapper margin="0.5rem 0 0.5rem 0">
      <Text strong>Scattered Insight</Text>
    </Wrapper>
    <Input
      name="scatteredInsight"
      value={value ?? ""}
      onChange={(e) => onChange(e.target.value)}
    />
  </>
);

export const QuestionSidebar: React.VFC = () => {
  const {
    onChange,
    state: {
      data: {
        type: typeValue,
        title: titleValue,
        text: textValue,
        shortLabel: shortLabelValue,
        definitionQuestion: definitionQuestionValue,
        definitionAnswer: definitionAnswerValue,
        purposeQuestion: purposeQuestionValue,
        purposeAnswer: purposeAnswerValue,
        learnMoreLabel: learnMoreLabelValue,
        learnMoreUrl: learnMoreUrlValue,
        additionalComments: additionalCommentsValue,
        placeholder: placeholderValue,
        scatteredInsight: scatteredInsightValue,
      },
    },
  } = useFeatureContext();
  const tabs = [
    {
      tab: "Attributes",
      key: "attributes",
      disabled: false,
      tabsContent: (
        <>
          <FeatureTitleContent
            value={titleValue}
            onChange={onChange("title")}
          />
          <FeatureTextContent value={textValue} onChange={onChange("text")} />
          <ShortLabelContent
            value={shortLabelValue}
            onChange={onChange("shortLabel")}
          />
          <QuestionTypeContent value={typeValue} onChange={onChange("type")} />
          {/* <QuestionReportConfigContent /> */}
          <QuickStartCheckbox />
          <ShowAsTagCheckbox />
          <ScatteredInsightContent
            value={scatteredInsightValue}
            onChange={onChange("scatteredInsight")}
          />
        </>
      ),
    },
    /*
     * @archived
     * {
     * tab: "Tips",
     * key: "tips",
     * disabled: true,
     * tabsContent: <></>,
     * },
     */
    {
      tab: "Definition",
      key: "definition",
      disabled: false,
      tabsContent: (
        <>
          <DefinitionBoolean />
          <DefinitionQuestionContent
            value={definitionQuestionValue}
            onChange={onChange("definitionQuestion")}
          />
          <DefinitionAnswerContent
            value={definitionAnswerValue}
            onChange={onChange("definitionAnswer")}
          />
        </>
      ),
    },
    {
      tab: "Purpose",
      key: "purpose",
      disabled: false,
      tabsContent: (
        <>
          <PurposeBoolean />
          <PurposeQuestionContent
            value={purposeQuestionValue}
            onChange={onChange("purposeQuestion")}
          />
          <PurposeAnswerContent
            value={purposeAnswerValue}
            onChange={onChange("purposeAnswer")}
          />
        </>
      ),
    },
    {
      tab: "Learn More",
      key: "learnMore",
      disabled: false,
      tabsContent: (
        <>
          <LearnMoreBoolean />
          <LearnMoreLabelContent
            value={learnMoreLabelValue}
            onChange={onChange("learnMoreLabel")}
          />
          <LearnMoreUrlContent
            value={learnMoreUrlValue}
            onChange={onChange("learnMoreUrl")}
          />
        </>
      ),
    },
    {
      tab: "Additional Comments",
      key: "additionalComments",
      disabled: false,
      tabsContent: (
        <>
          <AdditionalCommentsContent
            value={additionalCommentsValue}
            onChange={onChange("additionalComments")}
          />
          <PlaceholderContent
            value={placeholderValue}
            onChange={onChange("placeholder")}
          />
        </>
      ),
    },
  ];

  return (
    <S.Wrapper>
      <TabsBar tabsOptions={tabs} />
    </S.Wrapper>
  );
};

export default QuestionSidebar;
