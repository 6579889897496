import React, { ReactElement } from "react";
import { ButtonTertiary } from "components";
import { history } from "router/history";
import { Routes } from "router/routes";
import * as S from "./Payment.styles";
import { useLocation } from "react-router-dom";
/*
 * @archived
 * import { AuthContext } from "utils/context/auth";
 */

const Confirmation = ({
  action,
  plan,
}: {
  action: string;
  plan: any;
}): ReactElement => {
  const location = useLocation();

  const handleCLick = () => {
    if (location?.pathname === "/app/dashboard") {
      window.location.reload();
    } else {
      history.push(Routes.DASHBOARD.path);
    }
  };
  return (
    <S.ConfirmationWrapper>
      {action === "Subscription" || action === "Upgrade" ? (
        <span>
          Thanks for your interest in a {plan?.name} plan. A member of the LLUNA
          team will be in touch shortly! In the meantime, you'll be on the
          existing plan.
        </span>
      ) : (
        <span>
          Thanks for your interest in a premier plan. A member of the LLUNA team
          will be in touch shortly! In the meantime, please explore creating
          your own POP while we get your account setup.
        </span>
      )}
      <ButtonTertiary onClick={() => handleCLick()}>OK </ButtonTertiary>
    </S.ConfirmationWrapper>
  );
};

export default Confirmation;
