import { Col, Row, Form, Radio, Space } from "antd";
import React from "react";
import { RadioOption } from "../../common/types";
import { chunk } from "lodash";
import * as S from "./styles";
import { Placeholder } from "..";
import Text from "antd/lib/typography/Text";

type Props = {
  options: RadioOption[];
  hasTwoColumnLayout?: boolean;
  formFieldName: string;
};

const getHalvedArray = (arr: RadioOption[]) => {
  const half = Math.ceil(arr.length / 2);
  return chunk(arr, half);
};

export const RadioWithBackground: React.FC<Props> = (props) => {
  const { hasTwoColumnLayout = false, options, formFieldName } = props;
  const [leftColumnOptions, rightColumnOptions] = getHalvedArray(options);
  return (
    <Form.Item name={formFieldName} style={{ margin: "0px", width: "100%" }}>
      <Radio.Group name="teamGoals" style={{ width: "100%" }}>
        <Row>
          {!hasTwoColumnLayout ? (
            options?.map(({ value, label, placeholder }, index) => (
              <Col span={24} key={index}>
                <S.Radio value={value}>
                  <Space size={10}>
                    {placeholder?.value && (
                      <Placeholder
                        value={placeholder.value}
                        text={placeholder.text}
                      />
                    )}
                    <Text type="secondary">{label}</Text>
                  </Space>
                </S.Radio>
              </Col>
            ))
          ) : (
            <>
              {leftColumnOptions.map(({ value, label }, index) => (
                <Col span={12} key={index}>
                  <S.Radio value={value}>{label}</S.Radio>
                </Col>
              ))}
              {rightColumnOptions.map(({ value, label }, index) => (
                <Col span={12} key={index}>
                  <S.Radio value={value}>{label}</S.Radio>
                </Col>
              ))}
            </>
          )}
        </Row>
      </Radio.Group>
    </Form.Item>
  );
};
