// @TODO Technical Debt: Don't need this menu in ReadMe as there is no manager role
import { Routes } from "router/routes";
import { DASHBOARD, HELP_CENTER, menuItem } from "./common";

/*
 * @archived
 * const FRAMEWORKS = menuItem({
 *   key: "frameworks",
 *   title: "Team Frameworks",
 *   activeIcon: "eclipseYellow",
 *   inactiveIcon: "eclipseGrey",
 *   path: Routes.FRAMEWORKS_COLLECTION.path,
 * });
 */

const MY_TEAM = menuItem({
  key: "my-team",
  title: "My Team",
  activeIcon: "teamYellow",
  inactiveIcon: "teamGrey",
  path: "",
});

/*
 * @archived
 * const REPORTS = menuItem({
 *   key: "reports",
 *   title: "Reports",
 *   activeIcon: "reportsYellow",
 *   inactiveIcon: "reportsGrey",
 *   path: Routes.REPORTS.path,
 * });
 */

/*
 * @archived
 * const README = menuItem({
 *   key: "readme",
 *   title: "Readme",
 *   activeIcon: "eclipseYellow",
 *   inactiveIcon: "eclipseGrey",
 *   path: Routes.README.path,
 * });
 */

export const TeamManagerMenu = [
  DASHBOARD,
  /*
   * @archived
   * README,
   * {
   *   // ...FRAMEWORKS,
   *   // submenu: [
   *   //   {
   *   //     key: Routes.FRAMEWORKS_CREATE_NEW.path,
   *   //     title: "New Framework",
   *   //     path: Routes.FRAMEWORKS_CREATE_NEW.path,
   *   //   },
   *   //   {
   *   //     key: Routes.FRAMEWORKS_COLLECTION.path,
   *   //     title: "Collection",
   *   //     path: Routes.FRAMEWORKS_COLLECTION.path,
   *   //   },
   *   // ],
   * },
   */
  {
    ...MY_TEAM,
    submenu: [
      {
        key: Routes.TEAM_PROFILE.path,
        title: "Profile",
        path: Routes.TEAM_PROFILE.path,
      },
      {
        key: Routes.TEAM_MEMBERS.path,
        title: "Members",
        path: Routes.TEAM_MEMBERS.path,
      },
    ],
  },
  // REPORTS,
  HELP_CENTER,
];
