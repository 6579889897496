import { ReactiveVar } from "@apollo/client";
import { PlanTuple, Plan } from "types/plan";

export const updatePlan = (planVar: ReactiveVar<PlanTuple | null>) => {
  return (plan: Plan | null, price: number): void => {
    planVar(plan ? [plan, price] : null);
  };
};

export const updateAmountOfSeats = (amounOfSeatsVar: ReactiveVar<number>) => {
  return (amount: number): void => {
    amounOfSeatsVar(amount);
  };
};
