import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "styled-components";
import { Router } from "react-router-dom";
import { client } from "apolloClient";

import { Router as AppRouter } from "./router";
import theme from "./theme";
import { history } from "./router/history";

import { ReactElement } from "react";

import "App.less";

const App = (): ReactElement => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <AppRouter />
        </Router>
      </ThemeProvider>
    </ApolloProvider>
  );
};
export default App;
