import React from "react";
import { Tabs, TabsProps } from "antd";

import * as S from "./TabsBar.styles";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import Header from "pages/work-design/Header";

export type TabsConfig = {
  tab: string;
  key?: string;
  disabled?: boolean;
  tabsContent: React.ReactNode;
};

type TabsBarProps<Tab extends string> = {
  tabsOptions: TabsConfig[];
  activeKey?: Tab;
  onChange?: (value: Tab) => void;
  baseHeight?: boolean;
} & Omit<TabsProps, "onChange" | "activeKey">;

const { TabPane } = Tabs;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const TabsBar = <Tab extends string>({
  tabsOptions,
  onChange,
  activeKey,
  ...props
}: TabsBarProps<Tab>) => {
  const { sm: isDesktop } = useBreakpoint();

  const handleOnChange = (key: string) => onChange?.(key as Tab);

  // Fix blink between the props init
  if (typeof isDesktop === "undefined") return null;

  if (!isDesktop) {
    // TODO: create mobile styles for S.Tabs instead
    return (
      <div style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <Header
          tabs={tabsOptions.map(({ tab }) => tab)}
          activeTab={activeKey}
          onTabPress={handleOnChange}
        />
        {tabsOptions.map(
          ({ tab, tabsContent }) => tab === activeKey && tabsContent
        )}
      </div>
    );
  }

  return (
    <S.Tabs
      activeKey={activeKey}
      onChange={(key) => onChange?.(key as Tab)}
      defaultActiveKey={tabsOptions[0].key}
      {...props}
    >
      {tabsOptions.map((item) => (
        <TabPane
          key={item.key ?? item.tab}
          tab={item.tab}
          disabled={item.disabled}
        >
          {item.tabsContent}
        </TabPane>
      ))}
    </S.Tabs>
  );
};

export default TabsBar;
