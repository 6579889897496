import * as S from "./styles";
import { UserRole } from "codegen/generated/graphql";
import theme from "theme";
import { FC } from "react";
import { useAuthContext } from "utils/context";
import Restricted from "../../components/Permissions/Restricted";
import Text from "antd/es/typography/Text";

//=================================================================================//

import Wrapper from "components/Wrapper";
import { ButtonTertiary } from "components/Button";
import ContentLayout from "layout/ContentLayout";
import { ContentType } from "layout/ContentLayout/ContentLayout";
/*
 * @archived
 * import { useDownload } from "hooks";
 * import { getFormattedDate } from "utils/formatters";
 * import { Form} from "antd";
 */

//=================================================================================

const Teams: FC = () => {
  /*
   * @archived
   * const [form] = Form.useForm();
   *  const [currentFrameworkId, setCurrentFrameworkId] = useState<string>();
   *  const defaultFrameworkIdx = 0;
   */
  const { user } = useAuthContext();
  /*
   * @archived
   * const [selectedFilter, setSelectedFilter] = useState("");
   * const [selectedOffice, setOfficeValue] = useState([]);
   * const [selectedManager, setSelectedManager] = useState("");
   * const [selectedJobTitle, setJobTitleValue] = useState([]);
   * const [userScope, setUserScope] = useState({});
   * const [selectedEmploymentType, setEmploymentTypeValue] = useState([]);
   * const [selectedFunction, setSelectedFunction] = useState([]);
   * const [selectedGender, setSelectedGender] = useState("");
   * const [selectedRace, setSelectedRace] = useState([]);
   * const [selectedRange, setSelectedRange] = useState("");
   * const [selectedTenure, setSelectedTenure] = useState([]);
   */

  /*
   * @archived
   * const { data, loading } = useFrameworksCollectionQuery({
   *   variables: {
   *     sharedOnly: true,
   *   },
   * });
   */

  /*
   * @archived
   * const sortedFws = useMemo(
   *   () => [...(data?.frameworks || [])].reverse(),
   *   [data]
   * );
   */

  /*
   * @archived
   * const selectOptions = sortedFws.map((framework) => {
   *   return {
   *     label: (
   *       <Row gutter={12}>
   *         <Col>{framework.title}</Col>
   *         <Col>
   *           <span style={{ fontSize: "smaller", color: "grey" }}>
   *             from {getFormattedDate(framework.startDate)}
   *           </span>
   *         </Col>
   *       </Row>
   *     ),
   *     value: framework.id,
   *   };
   * }) ?? [{ label: "", value: "" }];
   */

  /*
   * @archived
   * const handleChange = (value: any) => {
   *   setSelectedFilter(value);
   *   form.resetFields();
   * };
   */

  /*
   * @archived
   * const {
   *   data: userScopeData,
   *   loading: userScopeLoading,
   *   error: userScopeError,
   * } = useUserScopesQuery({});
   */

  /*
   * @archived
   * const jobTitles = userScopeData?.userScopes?.jobTitles || [];
   * const jobTitleOptions = jobTitles?.map((item: any) => {
   *   return { label: item, value: item };
   * });
   */

  /*
   * @archived
   * const offices = userScopeData?.userScopes?.offices || [];
   * const officeOptions = offices?.map((item: any) => {
   *   return { label: item, value: item };
   * });
   */

  /*
   * @archived
   * const employmentTypes = userScopeData?.userScopes?.employmentTypes || [];
   * const employmentTypeOptions = employmentTypes?.map((item: any) => {
   *   return { label: item, value: item };
   * });
   */

  /*
   * @archived
   * const functions = userScopeData?.userScopes?.functions || [];
   * const functionOptions = functions?.map((item: any) => {
   *   return { label: item, value: item };
   * });
   */

  /*
   * @archived
   * const genders = userScopeData?.userScopes?.genders || [];
   * const genderOptions = genders?.map((item: any) => {
   *   return { label: item, value: item };
   * });
   */

  /*
   * @archived
   * const raceEthinicities = userScopeData?.userScopes?.raceEthinicities || [];
   * const raceOptions = raceEthinicities?.map((item: any) => {
   *   return { label: item, value: item };
   * });
   */

  /*
   * @archived
   * const filterOptions: any = [
   *   {
   *     label: "Job Title",
   *     value: "jobTitle",
   *   },
   *   {
   *     label: "Office",
   *     value: "office",
   *   },
   *   {
   *     label: "Manager Yes/No",
   *     value: "manager",
   *   },
   *   {
   *     label: "Employment Type",
   *     value: "employmentType",
   *   },
   *   {
   *     label: "Function",
   *     value: "function",
   *   },
   *   {
   *     label: "Gender",
   *     value: "gender",
   *   },
   *   {
   *     label: "Race/ethnicity",
   *     value: "race",
   *   },
   *   {
   *     label: "Hire date",
   *     value: "hireDate",
   *   },
   *   {
   *     label: "Tenure",
   *     value: "tenure",
   *   },
   *   {
   *     label: "Salary",
   *     value: "salary",
   *   },
   * ];
   */

  /*
   * @archived
   * const selectFilterProps: any = {
   *   mode: "single",
   *   style: {
   *     width: "100%",
   *   },
   *   value: selectedFilter,
   *   options: filterOptions,
   *   onChange: (newValue: any) => {
   *     setSelectedFilter(newValue);
   *     form.resetFields();
   *   },
   *   placeholder: "Select Item...",
   *   maxTagCount: "responsive",
   * };
   */

  /*
   * @archived
   * const selectJobTitleProps: any = {
   *   mode: "multiple",
   *   style: {
   *     width: "100%",
   *   },
   *   value: selectedJobTitle,
   *   options: jobTitleOptions,
   *   onChange: (newValue: any) => {
   *     setJobTitleValue(newValue);
   *   },
   *   placeholder: "Select Item...",
   *   maxTagCount: "responsive",
   * };
   */

  /*
   * @archived
   * const selectOfficeProps: any = {
   *   mode: "multiple",
   *   style: {
   *     width: "100%",
   *   },
   *   value: selectedOffice,
   *   options: officeOptions,
   *   onChange: (newValue: any) => {
   *     setOfficeValue(newValue);
   *   },
   *   placeholder: "Select Item...",
   *   maxTagCount: "responsive",
   * };
   */

  /*
   * @archived
   * const managerOptions: any = [
   *   {
   *     label: "Yes",
   *     value: "YES",
   *   },
   *   {
   *     label: "No",
   *     value: "NO",
   *   },
   * ];
   */

  /*
   * @archived
   * const selectManagerProps: any = {
   *   mode: "single",
   *   style: {
   *     width: "100%",
   *   },
   *   value: selectedManager,
   *   options: managerOptions,
   *   onChange: (newValue: any) => {
   *     setSelectedManager(newValue);
   *   },
   *   placeholder: "Select Item...",
   *   maxTagCount: "responsive",
   * };
   */

  /*
   * @archived
   * const selectEmploymentTypeProps: any = {
   *   mode: "multiple",
   *   style: {
   *     width: "100%",
   *   },
   *   value: selectedEmploymentType,
   *   options: employmentTypeOptions,
   *   onChange: (newValue: any) => {
   *     setEmploymentTypeValue(newValue);
   *   },
   *   placeholder: "Select Item...",
   *   maxTagCount: "responsive",
   * };
   */

  /*
   * @archived
   * const selectFunctionProps: any = {
   *   mode: "multiple",
   *   style: {
   *     width: "100%",
   *   },
   *   value: selectedFunction,
   *   options: functionOptions,
   *   onChange: (newValue: any) => {
   *     setSelectedFunction(newValue);
   *   },
   *   placeholder: "Select Item...",
   *   maxTagCount: "responsive",
   * };
   */

  /*
   * @archived
   * const selectGenderProps: any = {
   *   mode: "single",
   *   style: {
   *     width: "100%",
   *   },
   *   value: selectedGender,
   *   options: genderOptions,
   *   onChange: (newValue: any) => {
   *     setSelectedGender(newValue);
   *   },
   *   placeholder: "Select Item...",
   *   maxTagCount: "responsive",
   * };
   */

  /*
   * @archived
   * const selectRaceProps: any = {
   *   mode: "multiple",
   *   style: {
   *     width: "100%",
   *   },
   *   value: selectedRace,
   *   options: raceOptions,
   *   onChange: (newValue: any) => {
   *     setSelectedRace(newValue);
   *   },
   *   placeholder: "Select Item...",
   *   maxTagCount: "responsive",
   * };
   */

  /*
   * @archived
   * const rangeOptions: any = [
   *   {
   *     label: "Less than",
   *     value: "LESS_THAN",
   *   },
   *   {
   *     label: "Greater than",
   *     value: "GREATER_THAN",
   *   },
   *   {
   *     label: "Equals To",
   *     value: "EQUALS_TO",
   *   },
   * ];
   */

  /*
   * @archived
   * const selectRangeProps: any = {
   *   mode: "single",
   *   style: {
   *     width: "100%",
   *   },
   *   value: selectedRange,
   *   options: rangeOptions,
   *   onChange: (newValue: any) => {
   *     setSelectedRange(newValue);
   *   },
   *   placeholder: "Select Item...",
   *   maxTagCount: "responsive",
   * };
   */

  /*
   * @archived
   * const hireDateRangeOptions: any = [
   *   {
   *     label: "Before",
   *     value: "LESS_THAN",
   *   },
   *   {
   *     label: "After",
   *     value: "GREATER_THAN",
   *   },
   *   {
   *     label: "On",
   *     value: "EQUALS_TO",
   *   },
   * ];
   */

  /*
   * @archived
   * const selectHireDateRangeProps: any = {
   *   mode: "single",
   *   style: {
   *     width: "100%",
   *   },
   *   value: selectedRange,
   *   options: hireDateRangeOptions,
   *   onChange: (newValue: any) => {
   *     setSelectedRange(newValue);
   *   },
   *   placeholder: "Select Item...",
   *   maxTagCount: "responsive",
   * };
   */

  /*
   * @archived
   * const tenureOptions: any = [
   *   {
   *     label: "Less than 1 year",
   *     value: "[0, 1]",
   *   },
   *   {
   *     label: "1-2 years",
   *     value: "[1, 2]",
   *   },
   *   {
   *     label: "2-5 years",
   *     value: "[2, 5]",
   *   },
   *   {
   *     label: "5-10 years",
   *     value: "[5, 10]",
   *   },
   *   {
   *     label: "10-20 years",
   *     value: "[10, 20]",
   *   },
   *   {
   *     label: "More than 20 years",
   *     value: "[21, 21]",
   *   },
   * ];
   */

  /*
   * @archived
   * const selectTenureProps: any = {
   *   mode: "multiple",
   *   style: {
   *     width: "100%",
   *   },
   *   value: selectedTenure,
   *   options: tenureOptions,
   *   onChange: (newValue: any) => {
   *     setSelectedTenure(newValue);
   *   },
   *   placeholder: "Select Item...",
   *   maxTagCount: "responsive",
   * };
   */

  /*
   * @archived
   * const handleApplyFilter = (values: any) => {
   *   setUserScope(values);
   * };
   */

  /*
   * @archived
   * useEffect(() => {
   *   if (!sortedFws.length) return;
   *   setCurrentFrameworkId(sortedFws[defaultFrameworkIdx]?.id);
   * }, [sortedFws]);
   */

  /*
   * @archived
   * const performXlsxExport = () => {
   *   if (!currentFrameworkId) return;
   *   download(
   *     `report_${currentFrameworkId}`,
   *     API_ENDPOINTS.exportXLSX
   *       .build()
   *       .appendQueryParam("frameworkId", currentFrameworkId)
   *       .end()
   *   );
   * };
   */

  /*
   * @archived
   * const onValueChange = (value: any) => {
   *   if (selectedFilter === "jobTitle") {
   *     form.setFieldsValue({
   *       jobTitles: value,
   *     });
   *   } else if (selectedFilter === "office") {
   *     form.setFieldsValue({
   *       offices: value,
   *     });
   *   } else if (selectedFilter === "manger") {
   *     form.setFieldsValue({
   *       isManager: value,
   *     });
   *   } else if (selectedFilter === "employmentType") {
   *     form.setFieldsValue({
   *       employmentTypes: value,
   *     });
   *   } else if (selectedFilter === "function") {
   *     form.setFieldsValue({
   *       functions: value,
   *     });
   *   } else if (selectedFilter === "gender") {
   *     form.setFieldsValue({
   *       genders: value,
   *     });
   *   } else if (selectedFilter === "race") {
   *     form.setFieldsValue({
   *       raceEthinicities: value,
   *     });
   *   } else if (selectedFilter === "tenure") {
   *     form.setFieldsValue({
   *       tenure: value,
   *     });
   *   }
   * };
   */

  /*
   * @archived
   * const resetFilter = () => {
   *   setSelectedFilter("");
   *   form.resetFields();
   *   setUserScope({});
   * };
   */

  return (
    <>
      <ContentLayout
        header={{
          title: "My ReadMe",
          actions: [
            <Restricted to={[UserRole.PrimaryAdmin, UserRole.Admin]}>
              <S.HeaderActions>
                <span>Cancel</span>
                {user?.account?.isStripeCustomer ? (
                  <ButtonTertiary
                    style={{
                      background: "#01083A",
                      width: "213px",
                      height: "36px",
                      borderRadius: "3px",
                      border: "none",
                      outline: "none",
                    }}
                  >
                    <Text strong>Save</Text>
                  </ButtonTertiary>
                ) : null}
              </S.HeaderActions>
            </Restricted>,
          ],
        }}
        typeContent={ContentType.WithSubmenu}
        sideContent={""}
      >
        <Wrapper
          height="84.5vh"
          width="100%"
          gradientBg="linear-gradient(108.84deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.37) 101.71%);"
          radius={theme.radius.commonRadius}
        >
          {/* <Dialog /> */}
        </Wrapper>
      </ContentLayout>
    </>
  );
};

export default Teams;
