import { Input } from "antd";
import React from "react";

const { TextArea } = Input;

const MultiLineInput: React.FC = () => (
  <>
    <TextArea rows={4} placeholder="Type your answer here..." maxLength={300} />
  </>
);

export default MultiLineInput;
