import { Modal, Space, Col, Row } from "antd";
import { ButtonTertiary } from "components";
import { ImgSvg } from "components/ImgSvg";
import Text from "antd/lib/typography/Text";
import close from "assets/icons/close.svg";
import tickmarkYellow from "assets/icons/tickmarkYellow.svg";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ReactElement } from "react";

const PrimaryAdminConfirmModal = ({
  isPrimaryAdminConfirmModalVisible,
  setShowPrimaryAdminConfirmModal,
  continuePrimaryRoleSelection,
}: {
  isPrimaryAdminConfirmModalVisible: boolean;
  setShowPrimaryAdminConfirmModal: (visible: boolean) => void;
  continuePrimaryRoleSelection: () => void;
}): ReactElement => {
  return (
    <Modal
      visible={isPrimaryAdminConfirmModalVisible}
      footer={null}
      style={{
        overflow: "hidden",
        borderRadius: "0.5rem",
        textAlign: "center",
        padding: 0,
      }}
      centered
      closable={false}
      width={"50%"}
    >
      <Row style={{ marginTop: "2%" }}>
        <Col span={24}>
          <Text>
            <ExclamationCircleOutlined
              style={{ color: "#faad14", marginRight: "1rem" }}
            />
            You can only have 1 primary admin per account. If you continue with
            this role assignment, you will remain an admin.
          </Text>
        </Col>
        <Col span={24}>
          <Space style={{ width: "100%", marginTop: "3%" }}>
            <ButtonTertiary onClick={() => continuePrimaryRoleSelection()}>
              <ImgSvg
                src={tickmarkYellow}
                alt="Continue"
                style={{ marginRight: ".7rem", marginLeft: ".7rem" }}
              />
              <Text strong>Continue</Text>
            </ButtonTertiary>
            <ButtonTertiary
              outlined
              transparent
              onClick={() => setShowPrimaryAdminConfirmModal(false)}
            >
              <ImgSvg
                src={close}
                alt="Cancel"
                style={{ marginRight: ".7rem", marginLeft: ".7rem" }}
              />
              <Text strong>Cancel</Text>
            </ButtonTertiary>
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

export default PrimaryAdminConfirmModal;
