import React, { useContext, useEffect, useState } from "react";
import * as S from "./styles";
import { CheckCircleFilled } from "@ant-design/icons";
import {
  useDimensionsQuery,
  useGetReadMeQuery,
} from "codegen/generated/graphql";
import { List } from "antd";
import { AuthContext } from "utils/context/auth";

const percentageCalculator = (item: any, count: any): any =>
  ((item / count) * 100).toFixed();

export const Summary: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [, setinitialvalue] = useState<any>({});
  const [dataItems, setdataItems] = useState<any>([]);
  const [realtimeData, setRealTimeData] = useState<any>([]);
  const { data: readmeData } = useGetReadMeQuery({
    variables: {
      userId: user?.id || "",
    },
  });
  const { data: dimensionsData } = useDimensionsQuery();
  const dimensions: any = dimensionsData?.dimensions;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialResponse: any = {};
  const [quickstartProgress, setQuickstartprogress] = useState<any>({
    percent: 0,
    count: 0,
    quickStartIds: [],
    filled: 0,
  });

  useEffect(() => {
    if (dimensions && !!dimensions?.length) {
      const dataArr: any[] = dimensions?.map((dimension: any) => {
        return {
          name: dimension?.name,
          totalCount: dimension?.features.length,
          id: dimension.id,
          completedCount:
            readmeData?.readMe?.responses?.features?.map(
              (feature: any) => feature.id
            ) || [],
        };
      });

      const quickStartArr: any = dimensions?.reduce(
        (accum: any, current: any) => {
          const currentArray: any[] = current.features.filter(
            (feature: any) => feature?.quickStart
          );
          accum.count = accum.count + currentArray.length;
          accum.quickStartIds = [...accum.quickStartIds, ...currentArray];
          return accum;
        },
        { percent: 0, count: 0, quickStartIds: [], filled: 0 }
      );
      setdataItems(dataArr);
      setQuickstartprogress(quickStartArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensions]);

  useEffect(() => {
    if (dataItems && dataItems.length && !realtimeData.length) {
      readmeData?.readMe?.responses?.forEach((dimension: any) => {
        const indexOfSelectedFeature: any = dataItems?.findIndex(
          (item: any) => item.id === dimension.dimensionId
        );
        if (indexOfSelectedFeature !== -1) {
          dataItems[indexOfSelectedFeature].completedCount =
            dimension.features.map((a: any) => a.featureId);
        }
        dimension.features.forEach((f: any) => {
          initialResponse[f.featureId] = f.choices;
        });
      });

      let preFilledQuickStart: any = 0;
      quickstartProgress.quickStartIds.forEach((item: any, id: number) => {
        if (
          dataItems.find((data: any) => data.completedCount.includes(item.id))
        ) {
          preFilledQuickStart++;
        }
      });

      if (preFilledQuickStart) {
        setQuickstartprogress((value: any) => {
          const percent = percentageCalculator(
            preFilledQuickStart,
            value.count
          );
          return { ...value, percent, filled: preFilledQuickStart };
        });
      }

      setRealTimeData(Object.keys(initialResponse).map((a: any) => a));
      setinitialvalue({
        aboutMe: readmeData?.readMe?.aboutMe,
        ...initialResponse,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readmeData, dataItems]);

  const ListItem = (props: any) => {
    const { listItem } = props;
    const completion100 =
      +listItem.completedCount?.length === +listItem.totalCount ? true : false;
    return (
      <List.Item key={listItem.id} style={{ border: "none" }}>
        <S.FrameworkProgressTag isCompleted={completion100}>
          {`${listItem.name} (${listItem.completedCount?.length}/${listItem.totalCount})`}
          {completion100 ? (
            <CheckCircleFilled style={{ marginLeft: "10px" }} />
          ) : null}
        </S.FrameworkProgressTag>
      </List.Item>
    );
  };
  return (
    <List
      size="small"
      header={
        <>
          <List.Item
            key={1}
            style={{
              paddingBottom: "0px",
            }}
          >
            <S.FrameworkProgressTag
              isCompleted={
                quickstartProgress.filled === quickstartProgress.count
              }
            >
              QuickStart{" "}
              {` (${quickstartProgress.filled}/${quickstartProgress.count})`}
              {quickstartProgress.filled === quickstartProgress.count ? (
                <CheckCircleFilled style={{ marginLeft: "10px" }} />
              ) : null}
            </S.FrameworkProgressTag>
          </List.Item>
        </>
      }
    >
      {dataItems.length &&
        dataItems?.map((listItem: any, i: any) => (
          <ListItem key={i} listItem={listItem} />
        ))}
    </List>
  );
};
