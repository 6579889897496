import React, { FC } from "react";
import { Space } from "antd";
import { useField } from "formik";

import * as S from "./styles";

import { DIMENSION_COLORS } from "./colors";

type ColorsValue = {
  color: string;
  background: string;
};

type Props = {
  name: string;
};

export const ColorPicker: FC<Props> = ({ name }) => {
  const [{ value }, , { setValue }] = useField<ColorsValue>(name);

  return (
    <Space size={10}>
      {DIMENSION_COLORS.map(({ color, background }) => (
        <S.Container
          key={background}
          selected={background === value?.background}
          onClick={(e) => setValue({ color, background })}
        >
          <S.Left background={color} />
          <S.Right background={background} />
        </S.Container>
      ))}
    </Space>
  );
};
