import React from "react";
/*
 * @archived
 * import { Col, notification } from "antd";
 * import { filter } from "lodash";
 * import { Loader } from "components";
 * import { FrameworksSection } from "./Section";
 */

/*
 * @archived
 * import {
 *   FrameworkCollectionDataFragment,
 *   // useFrameworksCollectionQuery,
 *   FrameworkStatus,
 * } from "codegen/generated/graphql";
 */
/*
 * @archived
 * import ContentLayout from "layout/ContentLayout";
 * import { ContentType } from "layout/ContentLayout/ContentLayout";
 */

/*
 * @archived
 * const frameworkSections: [FrameworkStatus, string][] = [
 *   [FrameworkStatus.Created, "Created"],
 *   [FrameworkStatus.Shared, "Shared"],
 *   [FrameworkStatus.Active, "Active"],
 *   [FrameworkStatus.Ended, "Ended"],
 * ];
 */

/*
 * @archived
 * const getSectionData = (
 *   status: FrameworkStatus,
 *   data: FrameworkCollectionDataFragment[]
 * ) => {
 *   return filter(data, (framework) => framework.status === status);
 * };
 */

export const Collection: React.FC = () => {
  /*
   * @archived
   * const { data, loading } = useFrameworksCollectionQuery({
   *   onError: (e) => {
   *     notification.error({
   *       type: "error",
   *       message: "We weren't able to find frameworks. Please refresh.",
   *       placement: "bottomLeft",
   *     });
   *     console.warn(e);
   *   },
   * });
   */

  /*
   * @archived
   * if (!data && loading) {
   *   return <Loader fullScreen={false} />;
   * }
   */

  return (
    <></>
    /*
     * @archived
     * <ContentLayout
     *   header={{ title: "Team Frameworks" }}
     *   typeContent={ContentType.FullWidth}
     * >
     *   <Col
     *     span={20}
     *     style={{
     *       display: "flex",
     *       flex: 1,
     *       flexDirection: "column",
     *     }}
     *   >
     *     {frameworkSections.map(([status, title]) => (
     *       <FrameworksSection
     *         key={status}
     *         title={title}
     *         children={getSectionData(status, data?.frameworks ?? [])}
     *       />
     *     ))}
     *   </Col>
     * </ContentLayout>
     */
  );
};

export default Collection;
