import { useEffect, useState } from "react";
import skyBoySvg from "assets/images/signUp.svg";
import {
  BackgroundContainer,
  ContainerWithImage,
  DataWrapper,
  SignUpForm,
} from "components";
import { client } from "apolloClient";
import { useAuthContext } from "utils/context";
import {
  newSignUp,
  newSignInWithProvider,
  linkDifferentCredential,
  getAuthErrorMessage,
} from "utils/firebase";
import {
  InvitationStatus,
  useAccountUserByEmailQuery,
  useCreateAccountMutation,
  useUpdateSignUpMutation,
  CheckInvitationDocument,
  CheckInvitationQuery,
  CheckInvitationQueryVariables,
  MeDocument,
  useSyncFirebaseUserMutation,
  SyncFirebaseUserMutationOptions,
} from "codegen/generated/graphql";
import { Routes } from "router/routes";
import { useHistory } from "react-router-dom";
// import { Me } from "store/app/types";
import { InviteSignupForm } from "components/Forms/InviteSignupForm";
import { ACTION } from "utils/constValues";
import { userName } from "utils/helpers";
import { appMutations } from "store/app";
import { useQuery } from "utils/router";
import { Modal, notification } from "antd";
import { ApolloError } from "@apollo/client";

const checkError = "Can't verify the invitation";
const checkNote = "Please try again later";
// eslint-disable-next-line no-restricted-globals
const checkRetry = () => location.reload();

const InvitationErrors = {
  [InvitationStatus.Active]: undefined,
  [InvitationStatus.Used]: "You have already registered",
  [InvitationStatus.Expired]: "Expired invitation",
  [InvitationStatus.Invalid]: "Invalid invitation",
};

const InvitationNotes = {
  [InvitationStatus.Active]: undefined,
  [InvitationStatus.Used]: "Please log in",
  [InvitationStatus.Expired]: "Please contact Your manager",
  [InvitationStatus.Invalid]: "Please contact Your manager",
};

/**
 * @description Signup page component
 */
export const NewSignUp: React.FC = () => {
  const history = useHistory();
  const query = useQuery();
  const { invitation } = useAuthContext();
  const { loading, error, status } = invitation || {};
  const [loader, setLoader] = useState(false);
  const [createAccount] = useCreateAccountMutation();
  const [updateSignUp] = useUpdateSignUpMutation();
  const [syncFirebaseUser] = useSyncFirebaseUserMutation();
  const { refetch } = useAccountUserByEmailQuery({
    variables: {
      email: "",
    },
  });
  const invitationCode = query.get("invitationCode") || "";
  // let me: Me;

  useEffect(() => {
    getInvitationInfo(invitationCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @description Method to get invitation information by invitation code
   * @param invitationCode invitation code which coming as a url parameters
   * @returns Object that contains invitation information
   */
  const getInvitationInfo = async (invitationCode: string): Promise<any> => {
    if (!invitationCode) return;
    const { data } = await client.query<
      CheckInvitationQuery,
      CheckInvitationQueryVariables
    >({
      query: CheckInvitationDocument,
      variables: {
        invitationCode,
      },
    });
    appMutations.setInvitation(data.checkInvitation);
  };

  /**
   * @description Method to handle signup with SSO
   * @param providerName name of the provide eg. linkedin, google, outlook
   */
  const handleProviderSignup = (providerName: any) => {
    newSignInWithProvider(providerName)
      .then(async (res: any) => {
        if (res) {
          const { user, _tokenResponse } = res;
          const variables: any = {
            email: user.email,
          };
          refetch(variables).then((result: any) => {
            if (result?.data?.accountUserByEmail !== null) {
              // Synchronize Firebase and LLUNA shared fields
              const options: SyncFirebaseUserMutationOptions = {
                variables: {
                  email: user.email,
                  sso: true,
                },
              };
              syncFirebaseUser(options).then(({ data }) => {
                const isEmailVerified = Boolean(
                  data?.syncFirebaseUser.isVerified
                );

                if (isEmailVerified) {
                  history.push(Routes.DASHBOARD.path);
                } else {
                  appMutations.reset();
                  history.push(Routes.ERROR.path);
                }
              });
            } else {
              const resultName: any = userName(user.displayName);
              const userObj: any = {
                firebaseProvider: providerName,
                firebaseId: user?.uid,
                email: user?.email,
              };
              const inputData: any = {
                name: _tokenResponse
                  ? _tokenResponse.displayName
                  : user.displayName,
                firstName: _tokenResponse
                  ? _tokenResponse.firstName
                  : resultName.firstName,
                lastName: _tokenResponse
                  ? _tokenResponse.lastName
                  : result.lastName,
                user: userObj,
              };

              createAccount({
                variables: {
                  input: inputData,
                },
              })
                .then((result) => {
                  // me = result?.data?.createAccount.users[0] as Me;
                  history.push("/plans");
                })
                .catch((err: ApolloError) => {
                  notification.error({
                    type: "warning",
                    message: err.message.split("[")[0],
                    placement: "bottomLeft",
                  });
                });
            }
          });
        }
      })
      .catch((e) => {
        if (
          getAuthErrorMessage(e) ===
          "Authentication failed: account exists with different credential"
        ) {
          Modal.error({
            title: `${getAuthErrorMessage(e)}. Please try with password`,
          });
        } else {
          Modal.error({
            title: `${getAuthErrorMessage(e)}`,
          });
        }
      })
      .catch((e) => {
        linkDifferentCredential(e).catch((differentCredentialError) => {
          Modal.error({
            title: ` ${differentCredentialError}`,
          });
        });
      });
  };

  /**
   * @description Method to handle generic signup process
   * @param email user email
   * @param password user password
   * @param action whether its coming through the invitation or register
   * @param name user first name
   * @param lastName user last name
   */
  const handleSignup = (
    email: string,
    password: string,
    action: string,
    name?: string,
    lastName?: string
  ) => {
    if (action === ACTION.INVITE) {
      setLoader(true);
      newSignUp(email, password, name)
        .then((res: any) => {
          if (res) {
            const { user } = res;
            /**
             * @comment if user coming through the invitation
             */
            updateSignUp({
              variables: {
                input: {
                  name: name || "",
                  surname: lastName || "",
                  email: user.email,
                },
              },
            })
              .then((result) => {
                appMutations.setInvitation(null);
                user.emailVerified = true;
                client
                  .watchQuery({ query: MeDocument })
                  .subscribe(({ data }: { data: any }) => {
                    appMutations.onAuthChange({
                      user: data.me,
                      firebaseUser: user,
                      isLoading: false,
                    });
                  });
                // me = result?.data?.updateUserSignup as Me;
              })
              .catch((err: ApolloError) => {
                notification.error({
                  type: "warning",
                  message: err.message.split("[")[0],
                  placement: "bottomLeft",
                });
              })
              .finally(() => {
                setLoader(false);
              });
            /**
             * @comment if user coming through the register
             */
          }
        })
        .catch((e: any) => {
          Modal.error({ title: getAuthErrorMessage(e) });
        })
        .finally(() => {
          setLoader(false);
        });
    } else if (action === ACTION.SIGN_UP) {
      setLoader(true);
      const variables: any = {
        email: email,
      };
      refetch(variables).then((result: any) => {
        if (result?.data?.accountUserByEmail === null) {
          newSignUp(email, password, name)
            .then((res: any) => {
              if (res) {
                const { user } = res;
                /**
                 * @comment if user coming through the invitation
                 */

                createAccount({
                  variables: {
                    input: {
                      name: name,
                      firstName: name || "",
                      lastName: lastName || "",
                      user: {
                        firebaseProvider: "password",
                        firebaseId: user.uid,
                        email: user.email,
                      },
                    },
                  },
                })
                  .then((result) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    // me = result?.data?.createAccount.users[0] as Me;
                    history.push(Routes.SIGN_UP_SUCCESS.path);
                  })
                  .catch((err: ApolloError) => {
                    notification.error({
                      type: "warning",
                      message: err.message.split("[")[0],
                      placement: "bottomLeft",
                    });
                  })
                  .finally(() => {
                    setLoader(false);
                  });
              }
            })
            .catch((e: any) => {
              Modal.error({ title: getAuthErrorMessage(e) });
            })
            .finally(() => {
              setLoader(false);
            });
        } else {
          setLoader(false);
          appMutations.reset();
          history.push(Routes.ERROR.path);
        }
      });
    }
  };

  /**
   * @description Method handle conditional rendering
   * @returns method ui element based on the condition
   */
  const handleRender = () => {
    if (invitation) {
      return (
        <DataWrapper
          loading={loading}
          loadingText="Verifying the invitation..."
          error={error ? checkError : status && InvitationErrors[status]}
          errorNote={error ? checkNote : status && InvitationNotes[status]}
          next={error ? checkRetry : Routes.LOGIN.path}
          nextText={error ? "Retry" : "Log in"}
        >
          <InviteSignupForm
            signUpWithPassword={handleSignup}
            signUpWithProvider={handleProviderSignup}
            invitedUser={invitation?.user}
          />
        </DataWrapper>
      );
    } else {
      return (
        <SignUpForm
          signUpWithPassword={handleSignup}
          signUpWithProvider={handleProviderSignup}
          invitedUser={null}
          loading={loader}
        />
      );
    }
  };

  return (
    <BackgroundContainer>
      <ContainerWithImage image={{ src: skyBoySvg, alt: "sky-boy" }}>
        {handleRender()}
      </ContainerWithImage>
    </BackgroundContainer>
  );
};

export default NewSignUp;
