import { Menu, Layout } from "antd";
import styled from "styled-components";
const { Sider } = Layout;

export const UISidebar = styled(Sider)`
  border-radius: ${({ theme }) => theme.radius.commonRadius};
  -webkit-box-shadow: ${({ theme }) => theme.shadows.commonShadow};
  box-shadow: ${({ theme }) => theme.shadows.commonShadow};
  font-weight: 600;
  padding-top: 1.5em;
  margin: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 218px;
  overflow: hidden;

  background: radial-gradient(
    96.28% 274.83% at 6.01% 5.14%,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  backdrop-filter: blur(32px);
  &.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
    padding: 0px !important;
  }
`;

export const UIMenu = styled(Menu)<{ visible: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  &&.ant-layout-sider
    .ant-menu.ant-menu-root
    .ant-menu-item
    .ant-menu-title-content {
    display: ${({ visible }) =>
      visible ? "block !important" : "none !important"};
  }
  &.ant-menu-inline {
    border-right: 0 !important;
  }

  &.ant-menu-inline .ant-menu-item,
  &.ant-menu-inline .ant-menu-submenu-title {
    width: 100%;
  }

  &.ant-menu > li.ant-menu-submenu.ant-menu-submenu-open {
    background: ${({ theme }) => theme.colors.siderOpen};
  }
  &.ant-menu
    > li.ant-menu-submenu.ant-menu-submenu-open
    .ant-menu.ant-menu-sub {
    background: ${({ theme }) => theme.colors.siderOpen};
  }
  &.ant-menu
    > li.ant-menu-submenu.ant-menu-submenu-open
    .ant-menu.ant-menu-sub
    > .ant-menu-item {
    background: ${({ theme }) => theme.colors.siderOpen};
  }

  &.ant-menu
    > li.ant-menu-submenu.ant-menu-submenu-open
    > .ant-menu-submenu-title
    > .ant-menu-title-content {
    color: ${({ theme }) => theme.colors.blue_100};
  }
  &.ant-menu > li.ant-menu-submenu {
    border-left: 3px solid transparent;
  }
  &.ant-menu-inline .ant-menu-item {
    padding-left: 16px !important;
  }
  &.ant-menu
    > li.ant-menu-submenu
    > .ant-menu-submenu-title
    > .ant-menu-title-content {
    color: ${({ theme }) => theme.colors.grey_70};
    font-weight: 600;
  }
  &.ant-menu
    > li.ant-menu-submenu
    > .ant-menu.ant-menu-sub
    > .ant-menu-item
    > .ant-menu-title-content {
    padding-left: 6px;
  }
  &.ant-menu
    > li.ant-menu-submenu
    > .ant-menu-submenu-title
    > .ant-menu-item-icon {
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.ant-menu > li.ant-menu-item > .ant-menu-item-icon {
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.ant-menu > li.ant-menu-item > span > a {
    color: ${({ theme }) => theme.colors.grey_70};
  }
  &.ant-menu
    > li.ant-menu-submenu
    > ul
    > li.ant-menu-item-only-child
    > span
    > a {
    color: ${({ theme }) => theme.colors.grey_70};
    font-weight: 600;
  }
  .ant-menu-item-selected {
    border-left: 3px solid ${({ theme }) => theme.colors.gold_60} !important;
    border-right: none;
    background-color: transparent !important;
  }
  .ant-menu-item-selected a {
    color: ${({ theme }) => theme.colors.blue_100} !important;
  }
  .ant-menu-item-active a {
    color: ${({ theme }) => theme.colors.gold_60} !important;
    border-right: none;
    background-color: transparent !important;
  }
  .ant-menu-item-selected::after,
  .ant-menu-item-active::after {
    border-right: none;
  }
  .ant-menu-item {
    border-left: 3px solid transparent;
    display: flex;
    align-items: center;

    &:active {
      background: ${({ theme }) => theme.colors.grey_20};
    }
  }
  &.ant-menu
    > li.ant-menu-submenu
    > .ant-menu-submenu-title
    > i.ant-menu-submenu-arrow {
    color: ${({ theme }) => theme.colors.grey_70} !important;

    &::after,
    ::before {
      width: 7px !important;
    }
  }
  &.ant-menu
    > li.ant-menu-submenu.ant-menu-submenu-open
    > .ant-menu-submenu-title
    > i.ant-menu-submenu-arrow {
    color: ${({ theme }) => theme.colors.blue_100} !important;

    &::after,
    ::before {
      width: 6px !important;
    }
  }
  &.ant-menu > li.ant-menu-submenu > .ant-menu-submenu-title:active {
    background: ${({ theme }) => theme.colors.grey_20};
  }
  &.ant-menu > li.ant-menu-item > .ant-menu-item-icon {
    height: 17px;
    min-width: 22px;
  }

  &.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
    padding: 0 16px;
  }
`;
export const UIMenuItem = styled(Menu.Item)<{ $active?: boolean }>`
  height: 26px;
  width: 100%;
  color: ${({ $active, theme }) =>
    $active ? theme.colors.grey_70 : theme.colors.blue_100} !important;

  &.ant-menu > li.ant-menu-item > .ant-menu-item-icon {
    height: 17px;
    min-width: 22px;
  }
`;

export const UISubMenu = styled(Menu.SubMenu)`
  color: ${({ theme }) => theme.colors.grey_70};
  &.ant-menu-submenu-active > div > span,
  i {
    color: ${({ theme }) => theme.colors.gold_60} !important;
  }
  &.ant-menu-submenu-selected > div > span,
  i {
    color: ${({ theme }) => theme.colors.blue_100} !important;
  }

  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
    padding: 0 !important;
  }
`;

export const IconBackground = styled.span`
  min-width: 1.5rem;
  min-height: 1.5rem;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background-color: #fc9e08;
  }
`;
