import { FC, useContext } from "react";
import { Typography, Row, Col, Divider } from "antd";

import Avatar from "components/Avatar";
import { Icon } from "components/Icon";
import Logo from "components/Logo";
import * as S from "./header.styles";
import NavTab from "./NavTab/NavTab";

import { AuthContext } from "utils/context/auth";
import { UserRole } from "codegen/generated/graphql";
import { Link, useHistory } from "react-router-dom";
import { Routes } from "router/routes";
import { useReactiveVar } from "@apollo/client";
import { selectedRoleVar } from "store/app";
import { generatePath } from "react-router";

import { Version } from "./version";

const { Text } = Typography;

const Header: FC = () => {
  const { logout, user } = useContext(AuthContext);
  const selectedRole = useReactiveVar(selectedRoleVar);

  const history = useHistory();

  const userIsPrimaryAdmin = user?.roles.includes(UserRole.PrimaryAdmin);
  const userIsAdmin = user?.roles.includes(UserRole.LlunaAdmin);
  const userRoleIsAdmin = user?.roles.includes(UserRole.Admin);

  const shouldDisplayNavbar =
    (!!userIsPrimaryAdmin && !userIsAdmin) ||
    (!!userRoleIsAdmin && !userIsAdmin);

  /*
   * @archived
   * const userName = `${user?.profile?.name ?? ""} ${
   *   user?.profile?.surname ?? ""
   * }`;
   * const teamName = `${user?.team?.name}`;
   */

  const id = user?.id;
  const showTeam =
    selectedRole?.id === UserRole.PrimaryAdmin ||
    selectedRole?.id === UserRole.Admin;

  // const name = showTeam ? teamName : userName;

  const handleGoToProfile = () => {
    // showTeam && history.push(Routes.TEAM_PROFILE.path);
    history.push(generatePath(Routes.MY_PROFILE.path, { id }));
  };

  return (
    <S.Wrapper>
      <div>
        <Link to={Routes.DASHBOARD.path}>
          <Logo />
        </Link>
        {process.env.NODE_ENV === "development" && <Version />}
      </div>
      <NavTab enabled={shouldDisplayNavbar} />
      <S.Right>
        <Avatar
          size={35}
          alt="profilePhoto"
          style={{ marginRight: 10, fontSize: "0.9em" }}
          // fallback={name}
          src={user?.profile?.avatar ? user?.profile?.avatar : ""}
          // disallowInitials={!showTeam}
        />
        {/* <Text strong>{name}</Text> */}
        <S.SPopover
          placement="bottomRight"
          content={
            <>
              <Row
                style={{
                  margin: "1rem 0",
                  cursor: "pointer",
                }}
                onClick={handleGoToProfile}
              >
                <Col span={5}>
                  <Avatar
                    // fallback={name}
                    alt="profilePhoto"
                    src={showTeam ? user?.profile?.avatar : ""}
                    size={50}
                    // disallowInitials={!showTeam}
                  />
                </Col>
                <Col>
                  <Link
                    to={
                      showTeam
                        ? Routes.TEAM_PROFILE.path
                        : Routes.MY_PROFILE_REDIRECT.path
                    }
                  >
                    <Row>
                      <S.Text style={{ marginTop: "0.1rem" }} strong>
                        {/* {name} */}
                      </S.Text>
                    </Row>
                    <Row>
                      <Text type="secondary">View My Profile</Text>
                    </Row>
                  </Link>
                </Col>
              </Row>
              <Divider />
              <Row
                align="middle"
                onClick={logout}
                style={{ cursor: "pointer" }}
              >
                <Icon
                  name="logout"
                  fontSize={35}
                  style={{
                    marginRight: "1rem",
                  }}
                />
                <S.Text strong>Log Out</S.Text>
              </Row>
            </>
          }
          trigger="click"
        >
          <S.AccountButton />
        </S.SPopover>
      </S.Right>
    </S.Wrapper>
  );
};

export default Header;
