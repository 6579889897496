import { Modal } from "antd";
import {
  useSubscriptionPlansQuery,
  useUpdateLastLoggedInMutation,
} from "codegen/generated/graphql";
import { SelectPlan } from "./components/SelectPlan";
import { CloseOutlined } from "@ant-design/icons";
import { ReactElement, useEffect } from "react";
const StripeModal = ({
  isStripeModal,
  setPlanModal,
  action,
  accountSubscription,
}: {
  isStripeModal: boolean;
  setPlanModal: any;
  action: string;
  accountSubscription: any;
}): ReactElement => {
  const { data: planData } = useSubscriptionPlansQuery();
  const [updateLastLogin] = useUpdateLastLoggedInMutation();

  useEffect(() => {
    const trialFunction = () => {
      updateLastLogin();
    };
    trialFunction();
  }, [planData, updateLastLogin]);

  const handleCancel = () => {
    setPlanModal(false);
  };

  return (
    <Modal
      maskClosable={false}
      destroyOnClose={true}
      visible={isStripeModal}
      footer={null}
      style={{
        overflow: "hidden",
        borderRadius: "1rem",
        padding: "0",
        position: "relative",
      }}
      onCancel={handleCancel}
      closable={
        action === "Subscription" || action === "Upgrade" ? true : false
      }
      closeIcon={
        <CloseOutlined style={{ position: "relative", bottom: 10, left: 10 }} />
      }
      width={1150}
    >
      <SelectPlan
        plans={planData}
        action={action}
        accountSubscription={accountSubscription}
      />
    </Modal>
  );
};
export default StripeModal;
