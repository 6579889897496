import { SelectProps } from "antd";
import { ReactElement } from "react";
import * as S from "./select.styles";

function Select<T extends any>({ ...props }: SelectProps<T>): ReactElement {
  return (
    // TODO: typing
    <S.Select<any> {...props} size="large">
      {props.options?.map((option, i) => (
        <S.Option key={i}>{option}</S.Option>
      ))}
    </S.Select>
  );
}

export default Select;
