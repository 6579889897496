import { ReactElement, useMemo, useState, useEffect } from "react";
import { Space, Col, Row, Button, Select, Alert, notification } from "antd";
import Title from "antd/lib/typography/Title";
import Text from "antd/es/typography/Text";
import { makeUseModal, ModalProps } from "lib/useModal";
import { Table, ButtonTertiary } from "components";
import { useColumns } from "hooks/table";

import {
  CurrentAccountUsersDocument,
  useInviteBoardQuery,
  useInviteToBoardMutation,
} from "codegen/generated/graphql";
import { getFormattedDate } from "utils/formatters";
import { LABEL } from "utils/constValues";
import { ApolloError } from "@apollo/client";
const { Option } = Select;

type PropsType = {
  id: any;
  selectedItems: any;
};

const InviteUserToBoard = ({
  id,
  selectedItems,
  setShow,
}: PropsType & ModalProps): ReactElement => {
  /*
   * @archived
   * const [assignWorkDesign] = useAssignWorkDesignMutation({
   *   onCompleted: () => {
   *     notification.success({
   *       type: "success",
   *       message: "Framework has been assigned.",
   *       placement: "bottomLeft",
   *     });
   *     setShow(false);
   *   },
   *   onError: (e) => {
   *     notification.error({
   *       type: "error",
   *       message: `Could not assign framework`,
   *       placement: "bottomLeft",
   *     });
   *   },
   *   refetchQueries: [{ query: MeDocument }],
   * });
   */

  const [error, setError] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [boardDataList, setBoardDataList] = useState<any>([]);
  const [memberList, setMemberList] = useState<any>([]);
  let initialData;

  if (selectedItems.length > 0) {
    initialData = {
      userId: "",
      accountId: selectedItems[0].accountId,
    };
  } else {
    initialData = {
      userId: id?.id ? id.id : "",
      accountId: id?.account?.id ? id?.account?.id : "",
    };
  }

  const { data: boardData } = useInviteBoardQuery({
    variables: {
      input: initialData,
    },
  });
  const boardList = boardData?.inviteBoard;

  useEffect(() => {
    const data: any = [];
    if (boardList) {
      boardList.forEach((item) => {
        data.push({
          id: item?.id,
          name: item?.name,
          status: item.status,
          boardMember: item?.boardMember,
          boardViewer: item?.boardViewer,
          editor: item?.editorUser,
        });
      });
      setBoardDataList(data);
    }
  }, [boardList]);

  const [inviteToBoard] = useInviteToBoardMutation({
    onCompleted: () => {
      notification.success({
        type: "success",
        message: "User invited to board successfully",
        placement: "bottomLeft",
      });
      setShow(false);
    },
    onError: (err: ApolloError) => {
      notification.error({
        type: "warning",
        message: err.message.split("[")[0],
        placement: "bottomLeft",
      });
    },

    refetchQueries: [
      {
        query: CurrentAccountUsersDocument,
        variables: {
          input: {
            search: "",
            take: 30,
          },
        },
      },
    ],
  });

  const handleOnInvite = async () => {
    if (selectedValue) {
      const input: any = [];
      if (selectedItems.length > 0) {
        const res = selectedItems.filter(
          (o2: any) => !memberList.find((o1: any) => o1.userId === o2.userId)
        );
        const result = res.filter(
          (o2: any) =>
            !selectedValue.boardViewer.find(
              (o1: any) => o1.userId === o2.userId
            )
        );
        result.forEach((item: any) => {
          input.push({
            userId: item.userId,
            boardId: selectedValue.id,
          });
        });
      } else {
        input.push({
          userId: id?.id,
          boardId: selectedValue.id,
        });
      }
      await inviteToBoard({
        variables: { input: input },
      });
    } else {
      setError(true);
    }
  };

  const handleBoardSelect = (val: any) => {
    const data: any = [];
    const memberData = boardDataList?.find(
      (item: { id: string }) => item.id === val
    );
    memberData?.boardMember.forEach((val: any) => {
      data.push({
        userId: val?.userId,
        boardId: val?.userId,
        name: val?.user?.profile.fullName,
        quickStartComplete:
          val?.user?.readMe.length > 0
            ? val?.user?.readMe[0].quickStartComplete
            : false,
        updatedAt:
          val?.user?.readMe.length > 0
            ? val?.user?.readMe[0].updatedAt
            : undefined,
      });
    });
    data.push({
      userId: memberData?.editor?.id,
      boardId: memberData?.editor?.id,
      name: memberData?.editor?.profile.fullName,
      quickStartComplete:
        memberData?.editor?.readMe.length > 0
          ? memberData?.editor?.readMe[0].quickStartComplete
          : false,
      updatedAt:
        memberData?.editor?.readMe.length > 0
          ? memberData?.editor?.readMe[0].updatedAt
          : undefined,
    });
    setSelectedValue(memberData);
    setMemberList(data);
  };

  const columns = useColumns(
    [
      {
        title: "Current board Members & editors",
        dataIndex: "name",
        // sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        sorter: false,
        defaultSortOrder: "ascend",
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Quick Start Status",
        dataIndex: "quickStartComplete",
        // @TODO this sort doesn't work now as status is derived value
        sorter: false,
        render: (quickStartComplete) => {
          return (
            <Button
              type="primary"
              shape="round"
              style={{
                borderWidth: 0,
                backgroundColor: quickStartComplete ? "#63AC62" : "#C02C5D",
              }}
            >
              <Text
                strong
                style={{
                  marginTop: 3,
                  fontSize: 13,
                  color: "#ffffff",
                }}
              >
                {quickStartComplete ? "Complete" : "Incomplete"}
              </Text>
            </Button>
          );
        },
      },
      {
        title: "POP Last Update",
        dataIndex: "updatedAt",
        formatter: getFormattedDate,
        sorter: false,
        render: (updatedAt) => (
          <Space size={12}>
            <>
              {updatedAt ? (
                <Text>{getFormattedDate(updatedAt)}</Text>
              ) : (
                <Text>{"NA"}</Text>
              )}
            </>
          </Space>
        ),
      },
    ],
    {
      sorter: true,
      Wrapper: ({ children }) => <Text type="secondary">{children}</Text>,
    }
  );

  //const currentDate = useMemo(() => new Date(), []);

  /*
   * @archived
   * const { tableProps, selected } = useTable(
   *   useFrameworksCollectionLazyQuery({
   *     variables: {
   *       filter: [
   *         {
   *           key: "endDate",
   *           operator: FilterOperatorType.Gt,
   *           valueDate: currentDate,
   *         },
   *       ],
   *     },
   *   }),
   *   {
   *     key: "frameworks",
   *     searchInputValue,
   *   }
   * );
   */

  const frameworksTable = useMemo(() => {
    /*
     * @archived
     * const handleSelectedData = (val: any) => {
     *   let test = false;
     *   const filterData = selectedValue.filter((item: any) => {
     *     if (item.boardId === val.id) {
     *       test = true;
     *     }
     *     return test;
     *   });
     *   if (filterData?.length) {
     *     const index = selectedValue.findIndex(
     *       (item: { id: string }) => item.id === val.id
     *     );
     *     selectedValue.splice(index, 1);
     *     setSelectedValue([...selectedValue]);
     *   } else {
     *     selectedValue.push({
     *       userId: selectedItems[0].userId,
     *       boardId: val.id,
     *     });
     *     setSelectedValue([...selectedValue]);
     *   }
     * };
     * const handleSearch = () => {
     *   if (searchInputValue) {
     *     return boardData?.inviteBoard.filter((item: any) => {
     *       return item.name.includes(searchInputValue);
     *     });
     *   }
     * };
     */

    return (
      <Table
        // {...tableProps}
        dataSource={memberList.length > 0 ? memberList : []}
        /*
         * @archived
         * rowSelection={{
         *   hideSelectAll: true,
         *   // getCheckboxProps: (record: any) => ({
         *   //   disabled: record.isEnabled,
         *   // }),
         *   // onSelect: (data: any) => {
         *   //   handleSelectedData(data);
         *   // },
         * }}
         */
        columns={columns}
        size="small"
        rowKey={"id"}
        locale={{
          emptyText: (
            <Text type="secondary">It seems there is no Board selected</Text>
          ),
        }}
      />
    );
  }, [
    columns,
    memberList,
    /*
     * @archived
     * searchInputValue,
     * selectedItems,
     * selectedValue,
     */
  ]);

  return (
    <>
      <Title level={3} style={{ marginTop: "20px" }}>
        Invite to a Board
      </Title>
      {error && (
        <Alert message="Please Select Board" type="error" showIcon closable />
      )}
      <Row>
        <Col span={16}>
          <Select
            showSearch
            allowClear
            size={"large"}
            showArrow={false}
            style={{ width: "100%", marginTop: 50, marginBottom: 10 }}
            placeholder={LABEL.SEARCH_BOARD}
            optionFilterProp="children"
            filterOption={(input, option) =>
              /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
              (option!.children as unknown as string).includes(
                input.charAt(0).toUpperCase() + input.slice(1)
              )
            }
            onChange={(val: any) => handleBoardSelect(val)}
          >
            {boardDataList?.length > 0 &&
              boardDataList.map((choice: any, i: any) => {
                return (
                  <Option key={choice.id} value={choice.id}>
                    {choice.name}
                  </Option>
                );
              })}
          </Select>
        </Col>
        <Col span={7} offset={1}>
          <ButtonTertiary
            onClick={handleOnInvite}
            style={{ width: "171px", height: 42, marginTop: 50 }}
          >
            <Text strong>Invite</Text>
          </ButtonTertiary>
        </Col>
      </Row>
      <div style={{ marginTop: 10 }}>{frameworksTable}</div>
    </>
  );
};

export const useInviteUserToBoardDialog = makeUseModal<PropsType>(
  InviteUserToBoard,
  { width: "900px" }
);

export default useInviteUserToBoardDialog;
