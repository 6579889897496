import React, { HTMLAttributes } from "react";
import Title from "antd/es/typography/Title";

import DimensionTag from "components/DimensionTag";
import { CheckboxWithBackground } from "components/CheckboxWithBackground";
import { RadioWithBackground } from "components/RadioWithBackground";
import Input from "components/Input";

import * as S from "./styles";
import { Form, Select } from "antd";
import {
  ChoiceUpdateInput,
  Dimension,
  FeatureType,
} from "codegen/generated/graphql";
import { FeatureTypeTag } from "../Tag";
import { sortChoices } from "../../utils/hooks";
import MultiLineInput from "components/Input/input.multiline";

type Props = {
  id: string;
  isMobile?: boolean;
  text: string;
  type: FeatureType;
  dimension?: Pick<Dimension, "name" | "meta">;
  choices?:
    | Pick<
        ChoiceUpdateInput,
        "text" | "id" | "placeholderValue" | "placeholderText"
      >[]
    | null;
  isFromCreateFrameWork?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const FeatureCard: React.FC<Props> = ({
  id,
  text,
  type,
  dimension,
  choices = [],
  isMobile,
  isFromCreateFrameWork,
  ...props
}) => (
  <S.Wrapper {...props}>
    <S.Header>
      <FeatureTypeTag type={type} />
      {dimension && (
        <DimensionTag
          color={dimension.meta.textColor}
          label={dimension.name ?? ""}
          background={dimension.meta.backgroundColor}
        />
      )}
    </S.Header>
    <Title level={3} style={{ margin: "20px 10px 25px" }}>
      {text}
    </Title>
    {type === FeatureType.SingleSelectRadio &&
      !!choices &&
      (isFromCreateFrameWork ? (
        <RadioWithBackground
          options={choices.map((val) => ({
            value: val.id,
            label: val.text,
            placeholder: {
              text: val.placeholderText,
              value: val.placeholderValue,
            },
          }))}
          formFieldName={id}
        />
      ) : (
        <RadioWithBackground
          options={sortChoices(choices).map((val) => ({
            value: val.id,
            label: val.text,
            placeholder: {
              text: val.placeholderText,
              value: val.placeholderValue,
            },
          }))}
          formFieldName={id}
        />
      ))}
    {type === FeatureType.MultipleSelectCheckboxes &&
      !!choices &&
      (isFromCreateFrameWork ? (
        <CheckboxWithBackground
          options={choices.map((val) => ({
            value: val.id,
            label: val.text,
            placeholder: {
              text: val.placeholderText,
              value: val.placeholderValue,
            },
          }))}
          formFieldName={id}
        />
      ) : (
        <CheckboxWithBackground
          options={sortChoices(choices).map((val) => ({
            value: val.id,
            label: val.text,
            placeholder: {
              text: val.placeholderText,
              value: val.placeholderValue,
            },
          }))}
          formFieldName={id}
        />
      ))}
    {type === FeatureType.TextSingleLine && (
      <Form.Item
        name={id}
        style={{
          padding: "0 15px",
        }}
      >
        <Input
          maxLength={100}
          label=""
          placeholder="Type your answer here..."
          name={text}
        />
      </Form.Item>
    )}
    {type === FeatureType.TextMultiLine && (
      <Form.Item
        name={id}
        style={{
          padding: "0 15px",
        }}
      >
        <MultiLineInput />
      </Form.Item>
    )}
    {type === FeatureType.SingleSelectDropdown && !!choices && (
      <Select defaultValue="Select One" style={{ width: 350 }}>
        {choices.map((choice) => (
          <Select.Option value={choice.text}>{choice.text}</Select.Option>
        ))}
      </Select>
    )}
    {type === FeatureType.MultipleSelectDropdown && !!choices && (
      <Select mode="multiple" placeholder="" allowClear style={{ width: 350 }}>
        {choices.map((choice: any) => {
          return <Select.Option value={choice.id}>{choice.text}</Select.Option>;
        })}
      </Select>
    )}
  </S.Wrapper>
);
