import React from "react";
import * as S from "./styles";

type Props = {
  src: string;
  alt: string;
  style?: React.CSSProperties;
  onClick?: VoidFunction;
};

export const ImgSvg: React.FC<Props> = ({ src, alt, style, onClick }) => {
  return <S.Img src={src} alt={alt} style={style} onClick={onClick} />;
};
