import { FC, useMemo, useState, useCallback } from "react";
import Text from "antd/es/typography/Text";
import {
  UserRole,
  CurrentAccountUsersDocument,
  useToggleUsersMutation,
  useSendUpdateRemainderMutation,
  useMeQuery,
  useAccountSubscriptionQuery,
} from "codegen/generated/graphql";
import { UserWithAttributes } from "common/types";
import { ButtonTertiary } from "components/Button";
import { Icon, Restricted } from "components";
import { useTable } from "hooks/table";
import { useLazyQuery, DocumentNode } from "@apollo/client";
import { Col, Row, Dropdown, Button, Menu } from "antd";
import { UserManagementList } from "./UserManagementList";
import useInviteUserToBoardkDialog from "../containers/InviteUserToBoard.dialog";
import NewMemberInviteModal from "../containers/NewMemberInviteModal";
/*
 * @archived
 * import ContentLayout from "layout/ContentLayout";
 * import { useHistory } from "react-router";
 */

const TeamMembers: FC<{
  dataKey?: string;
  documentNode?: DocumentNode;
  variables?: any;
}> = ({
  dataKey = "currentAccountUsers",
  documentNode = CurrentAccountUsersDocument,
  variables = {},
}) => {
  /*
   * @archived
   * const history = useHistory();
   * const handleOnRedirect = (to: string) => history.push(to);
   */
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [searchInputValue] = useState("");
  const [isPeopleInviteModal, setPeopleInviteModal] = useState<any>(false);
  const [error, setError] = useState<boolean>(false);
  const [action, setAction] = useState<any>("");
  const [isDisabledFilter] = useState(false);
  const [sendUpdateRemainder] = useSendUpdateRemainderMutation();
  const { data: meQuery } = useMeQuery();
  const { data: accountSubscriptionData } = useAccountSubscriptionQuery();

  const [toggleUsers] = useToggleUsersMutation({
    update: (cache) => {
      // Notify Apollo cache that `accountUsers` (with any set of vars) is outdated.
      cache.evict({ fieldName: "currentAccountUsers" });
    },
  });

  const makeToggleUsers = useCallback(
    (userIds: string[], isEnabled: boolean) => () => {
      if (userIds.length > 0) {
        toggleUsers({ variables: { userIds, isEnabled } });
      } else {
        setError(true);
      }
    },
    [toggleUsers]
  );

  const [InviteDialog, showInviteDialog] = useInviteUserToBoardkDialog();

  const handleInvite = () => {
    if (selectedItems.length > 0) {
      showInviteDialog(selectedItems);
    } else {
      setAction("invite");
      setError(true);
    }
  };

  const handleSendReminder = () => {
    if (selectedItems.length > 0) {
      const sendInput: any = [];
      selectedItems.forEach((item: any) => {
        sendInput.push(item.userId);
      });
      const input = {
        userId: sendInput,
      };
      sendUpdateRemainder({
        variables: { input: input },
      }).then((res) => {
        if (res.data) {
        }
      });
    } else {
      setAction("invite");
      setError(true);
    }
  };

  const fetch = useLazyQuery(documentNode, { variables: variables || {} });

  const { tableProps, data: { [dataKey]: data } = {} } =
    useTable<UserWithAttributes>(fetch, {
      searchInputValue,
      isDisabledFilter,
      key: dataKey + ".users",
    });

  const memberList = useMemo(() => {
    const handleSelectedRow = (val: any) => {
      let test = false;
      const filterData = selectedItems.filter((item: any) => {
        if (item.userId === val.id) {
          test = true;
        }
        return test;
      });
      if (filterData?.length) {
        const index = selectedItems.findIndex(
          (item: { id: string }) => item.id === val.id
        );
        selectedItems.splice(index, 1);
        setSelectedItems([...selectedItems]);
        setError(false);
      } else {
        selectedItems.push({
          userId: val.id,
          accountId: val.account.id,
        });
        setSelectedItems([...selectedItems]);
        setError(false);
      }
    };

    return (
      <UserManagementList<UserWithAttributes>
        tableProps={tableProps}
        data={data}
        selected={(val: any) => handleSelectedRow(val)}
        selectedItems={[]}
        error={error}
        action={action}
        setAlert={(val: any) => setError(val)}
      />
    );
  }, [data, tableProps, selectedItems, error, action]);

  const getSelectedId = () => {
    const data = selectedItems.filter(
      (item: any) => item.userId !== meQuery?.me?.id
    );
    const result = data.map((val: any) => {
      return val.userId;
    });
    return result;
  };

  const handlePeopleInviteModal = async (val: boolean) => {
    setPeopleInviteModal(val);
  };

  const handleInviteModal = () => {
    setPeopleInviteModal(true);
  };

  return (
    <Row>
      <Col>
        <Row
          gutter={[0, 24]}
          justify="space-between"
          style={{ marginRight: "2.5rem" }}
        >
          <Col
            span={24}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Restricted
              to={[UserRole.PrimaryAdmin, UserRole.Admin, UserRole.LlunaAdmin]}
            >
              <ButtonTertiary
                icon={<Icon fontSize={22} name="plus" />}
                onClick={() => handleInviteModal()}
              >
                <Text strong>New Member</Text>
              </ButtonTertiary>
              <div
                style={{
                  justifyContent: "flex-end",
                  marginLeft: "auto",
                  backgroundColor: "red",
                }}
              >
                <Dropdown
                  overlay={() => (
                    <Menu>
                      <Menu.Item key="1" onClick={() => handleInvite()}>
                        Invite to a Board
                      </Menu.Item>
                      <Menu.Item key="2" onClick={() => handleSendReminder()}>
                        Send update reminder
                      </Menu.Item>

                      <Menu.Item
                        key="4"
                        onClick={makeToggleUsers(getSelectedId(), false)}
                      >
                        Disable User
                      </Menu.Item>
                    </Menu>
                  )}
                  trigger={["click"]}
                >
                  <Button
                    style={{
                      height: 36,
                      width: "177px",
                      borderWidth: 1.5,
                      borderColor: "rgb(98, 121, 141)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 2,
                      }}
                    >
                      <span style={{ justifyContent: "flex-start" }}>
                        Bulk Actions
                      </span>
                      <span
                        style={{
                          justifyContent: "flex-end",
                          marginLeft: "auto",
                          marginTop: 1,
                        }}
                      >
                        <Icon fontSize={20} name="downArrow" />
                      </span>
                    </div>
                  </Button>
                </Dropdown>
              </div>
            </Restricted>
          </Col>

          <InviteDialog id={null} selectedItems={selectedItems} />
          <Col span={24}>{memberList}</Col>
        </Row>
      </Col>
      {meQuery && accountSubscriptionData && (
        <NewMemberInviteModal
          isPeopleInviteModal={isPeopleInviteModal}
          setShowPeopleInviteModal={(val: any) => handlePeopleInviteModal(val)}
          accountData={meQuery?.me}
          accountSubscriptionData={accountSubscriptionData}
        />
      )}
    </Row>
  );
};

export default TeamMembers;
