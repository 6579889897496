import { useState, useMemo, ReactElement } from "react";
import { Collapse, List, Row, Col, RadioChangeEvent, Space } from "antd";
import Button from "components/Button";
import { RadioGroup } from "components/Radio";
import Wrapper from "components/Wrapper";
import * as S from "./Subscribe.styles";
import { Plan } from "types/plan";
import { planMutations } from "store/plan";

const { Panel } = Collapse;

type PlanItemProps = {
  plan: Plan;
  onPlanSelect: (plan: Plan) => void;
  isActive: boolean;
};

export const PlanItem = ({
  plan,
  onPlanSelect,
  isActive,
}: PlanItemProps): ReactElement => {
  const { updatePlan } = planMutations;
  const [frequency, setFrequency] = useState(0);
  const options = useMemo(() => {
    return plan.pricing.map(({ label }, i: number) => ({
      value: i,
      label,
    }));
  }, [plan]);

  const onFrequencyChange = (e: RadioChangeEvent) => {
    setFrequency(e.target.value);
    onPlanSelect(plan);
  };

  const onPlanSelectHandler = (plan: Plan) => {
    updatePlan(plan, frequency);
  };

  return (
    <S.Card $isActive={isActive}>
      <S.Title level={3}>{plan.label}</S.Title>
      <S.Paragraph>{plan.summary}</S.Paragraph>
      <Wrapper margin="0 0 1.75rem 0">
        {options.length > 1 ? (
          <>
            <Wrapper margin="0.75rem 0 1.25rem 0">
              <RadioGroup
                name="frequency"
                options={options}
                value={isActive && frequency}
                onChange={onFrequencyChange}
                optionType="button"
              ></RadioGroup>
            </Wrapper>
            <Row align="middle" gutter={6}>
              <Col>
                <S.Text style={{ fontSize: 15 }}>Starting at</S.Text>
              </Col>
              <Col>
                <S.TextTitle strong>
                  $
                  {(
                    plan.pricing[frequency || 0]?.price /
                    plan.pricing[frequency || 0]?.months
                  ).toFixed(2)}
                  /mo for 5 users
                </S.TextTitle>
              </Col>
            </Row>
            <Row align="middle" gutter={6}>
              <Col>
                <S.Text style={{ fontSize: 15 }}>Billed in</S.Text>
              </Col>
              <Col>
                <S.TextTitle strong style={{ fontSize: 15 }}>
                  {plan.pricing[0]?.increment} seat increments
                </S.TextTitle>
              </Col>
            </Row>
          </>
        ) : (
          <Space direction="vertical" size="small">
            <S.TextTitle strong>Custom Pricing</S.TextTitle>
            <S.Text style={{ fontSize: 15 }}>
              Based on the total size of the company
            </S.Text>
          </Space>
        )}
      </Wrapper>
      <S.Collapse ghost defaultActiveKey="1">
        {plan.features.map((feature, i) => (
          <Panel header={feature.label} key={i + 1}>
            <List
              dataSource={feature.items}
              renderItem={(item: string) => (
                <li>
                  <Row wrap={false} align="top">
                    <S.CheckIcon />
                    <S.Text>{item}</S.Text>
                  </Row>
                </li>
              )}
            ></List>
          </Panel>
        ))}
      </S.Collapse>
      <div style={{ flexGrow: 1, marginBottom: "20px" }}></div>
      {plan.pricing.length > 0 ? (
        <Button
          type="primary"
          $bgColor={isActive ? "#01083A" : "#62798D"}
          $hoverBgColor="#01083A"
          size="large"
          $height="48px"
          onClick={() => onPlanSelectHandler(plan)}
        >
          Continue with this plan
        </Button>
      ) : (
        <Button
          type="primary"
          $bgColor="#62798D"
          $hoverBgColor="#01083A"
          size="large"
          $height="48px"
          onClick={() =>
            (window.location.href = "mailto: sales@hellolluna.com")
          }
        >
          Contact Sales
        </Button>
      )}
    </S.Card>
  );
};
