import React, { useCallback, useState, useLayoutEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  ChoiceUpdateInput,
  DimensionDataFragment,
  Placeholder,
  Maybe,
  FeatureType,
  ChoiceCreateInput,
} from "codegen/generated/graphql";
import Title from "antd/lib/typography/Title";
import { Input, Wrapper, DimensionTag } from "components";
import { ButtonCore } from "components/Button";
// @TODO - refactor - export all icons from one module
import plusBlue from "assets/icons/plusBlue.svg";
import { Img } from "components/ImgSvg/styles";
import * as S from "./styles";
import { Col, Row } from "antd";
/*
 * @archived
 * import ChoicesSidebar from "./ChoicesSidebar";
 * import AssignmentSidebar from "./AssignmentSidebar";
 * import { colors } from "theme";
 */
import { useFeatureContext } from "../context";
import { Choice } from "./Choice";

export type FeaturePlaceholderType = Maybe<Placeholder> | undefined;

interface ChoicesProps {
  selectedDimension?: DimensionDataFragment;
  onChange: (value: ChoiceUpdateInput[]) => void;
}

export const Choices: React.FC<ChoicesProps> = ({
  onChange,
  selectedDimension,
}) => {
  const {
    state: {
      data: { choices = [], type: featureType, text: questionValue },
      activeTab,
    },
  } = useFeatureContext();

  const [activeItemId, setActiveItemId] = useState(0);
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [idx, setidx] = useState(0);
  const canManageChoices = activeTab === "choices";

  useLayoutEffect(() => {
    ref?.scrollIntoView({ behavior: "smooth" });
  }, [idx, ref]);

  const handleChange = useCallback(
    (choiceIdx: number) => (props: any) => {
      const newChoices = [...(choices || [])];
      newChoices[choiceIdx] = { ...newChoices[choiceIdx], ...props };

      onChange(newChoices);
    },
    [choices, onChange]
  );

  const handleMove = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newChoices = [...(choices || [])];
      newChoices[dragIndex] = {
        ...newChoices[dragIndex],
        order: hoverIndex + 1,
      };
      newChoices[hoverIndex] = {
        ...newChoices[hoverIndex],
        order: dragIndex + 1,
      };

      onChange(newChoices);
    },
    [choices, onChange]
  );

  const renderChoice = useCallback(
    (choice: ChoiceCreateInput, choiceIdx: number) => {
      return (
        <Choice
          id={choiceIdx}
          data={choice}
          deletable={canManageChoices}
          onDelete={() =>
            choices &&
            onChange(
              choices.filter(
                (_prevChoice, prevChoiceIdx) => choiceIdx !== prevChoiceIdx
              )
            )
          }
          onFocus={() => setActiveItemId(choiceIdx)}
          onChange={handleChange(choiceIdx)}
          onMove={handleMove}
          isActive={choiceIdx === activeItemId}
        />
      );
    },
    [
      canManageChoices,
      handleChange,
      handleMove,
      activeItemId,
      choices,
      onChange,
    ]
  );

  return (
    <S.Wrapper>
      <Row gutter={[30, 0]}>
        <Col span={16}>
          <Title
            style={{
              fontWeight: "bold",
            }}
            level={4}
          >
            Dimension
          </Title>
          <Wrapper margin="0.5rem 0 0 0">
            <DimensionTag
              background={selectedDimension?.meta.backgroundColor}
              color={selectedDimension?.meta.textColor}
              label={selectedDimension?.name ?? ""}
            />
          </Wrapper>
          <Wrapper margin="3rem 0 0 0">
            <Input
              label="Question"
              name="question"
              value={questionValue}
              readOnly
            />
          </Wrapper>

          {featureType === FeatureType.Text ? (
            <Input label="Open Question" name="openQuestion" />
          ) : featureType === FeatureType.MultiLine ? (
            <Input
              label="Multi-Line Open Question"
              name="multiLineOpenQuestion"
            />
          ) : (
            <DndProvider backend={HTML5Backend}>
              {choices
                ?.sort((choice1, choice2) => choice1.order - choice2.order)
                .map(renderChoice)}
              <ButtonCore
                ref={setRef}
                hidden={!canManageChoices}
                style={{
                  width: "100%",
                  minHeight: "50px",
                }}
                icon={<Img src={plusBlue} />}
                onClick={() => {
                  onChange([
                    ...(choices || []),
                    {
                      text: "",
                      order:
                        Math.max(
                          ...(choices
                            ? choices.map((choice) => choice.order).concat(-1)
                            : [-1])
                        ) + 1,
                      placeholderValue: undefined,
                    },
                  ]);
                  setidx((idx) => ++idx);
                }}
              >
                Add Choice
              </ButtonCore>
            </DndProvider>
          )}
        </Col>

        {/* <Col
          span={8}
          style={{
            background: colors.common_bg,
          }}
        >
          {activeTab === "choices" ? (
            <ChoicesSidebar
              featureType={featureType}
              activeItemId={activeItemId}
              onChange={onChange}
              data={choices || []}
            />
          ) : (
            <AssignmentSidebar />
          )}
        </Col> */}
      </Row>
    </S.Wrapper>
  );
};

export default Choices;
