import { Typography } from "antd";
import {
  Switch,
  Route,
  useRouteMatch,
  Link,
  HashRouter,
} from "react-router-dom";
import * as S from "../Subscribe.styles";
import { PlanSummary } from "../components/PlanSummary";
import { Checkout } from "../components/Payment";
import { planMutations } from "store/plan";
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";

const { Title, Text, Link: TextLink } = Typography;

export const PlanSummaryHashRouter: React.VFC = () => {
  return (
    <HashRouter>
      <PlanSummaryContainer />
    </HashRouter>
  );
};

const PlanSummaryContainer = () => {
  const match = useRouteMatch();

  const { updatePlan } = planMutations;
  return (
    <HashRouter>
      <Switch>
        <Route exact path={match.path}>
          <S.TitleContainer justify="space-between" align="middle">
            <Title level={1}>Pricing</Title>
            <TextLink strong onClick={() => updatePlan(null, 0)}>
              <ArrowLeftOutlined style={{ fontSize: 10 }} /> Back to Select a
              Plan
            </TextLink>
          </S.TitleContainer>
          <PlanSummary />
        </Route>
        <Route path={"/payment"}>
          <S.TitleContainer justify="space-between" align="middle">
            <Link to={"/"}>
              <Text strong>
                <ArrowLeftOutlined style={{ fontSize: 10 }} /> Back to Pricing
              </Text>
            </Link>
          </S.TitleContainer>
          <Checkout />
        </Route>
      </Switch>
    </HashRouter>
  );
};
