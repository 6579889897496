export const getFormattedDate = (
  isoDate: string,
  isMonthNumeric = false,
  showTime = false
): string => {
  const date = new Date(isoDate);

  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
  } as Intl.DateTimeFormatOptions;

  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  } as Intl.DateTimeFormatOptions;

  // if isMonthNumeric is true, returned format is "17/12/2021", else it's "Dec 17, 2021"
  return isMonthNumeric
    ? date.toLocaleDateString()
    : date.toLocaleDateString(undefined, {
        ...options,
        ...(showTime && timeOptions),
      });
};

export const getFormattedTime = (isoDate: string): string => {
  const date = new Date(isoDate);
  if (!isoDate) return new Date().toDateString();

  const formatter = new Intl.DateTimeFormat("en", {
    timeStyle: "short",
  });

  let formattedTime;
  try {
    formattedTime = formatter.format(date);
  } catch (e) {
    formattedTime = "N/A";
  }

  return formattedTime;
};

const createFormattedUsdFactory = () => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    /*
     * @archived
     *  These options are needed to round to whole numbers if that's what you want.
     * minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
     * maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
     */
  });

  return (value: number) => formatter.format(value);
};

export const formattedUsd = createFormattedUsdFactory();

const createFormattedDaysFactory = () => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "unit",
    unit: "day",
  });

  return (value: number) => formatter.format(value);
};

export const formattedDays = createFormattedDaysFactory();
