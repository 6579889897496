import React, { useContext } from "react";
import skyBoySvg from "assets/images/signUp.svg";
import { BackgroundContainer } from "components/BackgroundContainer";
import {
  SignUpSuccess as SignUpSuccessComponent,
  InviteSignUpSuccess,
} from "components/SignUpSuccess";

import { ProfileUpdateSuccess } from "components/ProfileUpdateSuccess";

import { ContainerWithImage } from "components/ContainerWithImage";
import { useMeQuery } from "codegen/generated/graphql";
import { AuthContext } from "utils/context/auth";

export const SignUpSuccess: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data } = useMeQuery();
  const { user } = useContext(AuthContext);
  console.log("user data ", data, user);
  const renderUI = () => {
    if (data !== null && data?.me !== null && data?.me?.activity === null) {
      console.log("Me data ==>>", data?.me);
      if (!data.me.isVerified && !data?.me.isInvited) {
        return <SignUpSuccessComponent />;
      } else {
        return <InviteSignUpSuccess />;
      }
    } else if (user !== null && user?.activity === "EMAIL_UPDATED") {
      return <ProfileUpdateSuccess />;
    } else if (data && data?.me === null) {
      return <ProfileUpdateSuccess />;
    }
  };
  return (
    <BackgroundContainer>
      <ContainerWithImage image={{ src: skyBoySvg, alt: "sky-boy" }}>
        {renderUI()}
      </ContainerWithImage>
    </BackgroundContainer>
  );
};

export default SignUpSuccess;
