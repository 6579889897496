import React, { useEffect } from "react";
import { Button, Col, Row, Space, Typography } from "antd";
import { DashboardCard } from "../DashboardCard";
import * as S from "./styles";
import {
  useNotificationsQuery,
  useMeQuery,
  useMarkNotificationReaderMutation,
} from "codegen/generated/graphql";
import Image from "components/Image";
import UnReadNotificationIcon from "assets/icons/mindset-traveler.svg";
import { LABEL } from "utils/constValues";
import moment from "moment";

const { Title } = Typography;

export const Notifications: React.FC = () => {
  const [markNotification] = useMarkNotificationReaderMutation();
  const { data: meQuery } = useMeQuery();
  const { data: notificationData, refetch } = useNotificationsQuery();

  const notiList: any = notificationData?.notifications.filter((item: any) => {
    return item.user?.account?.id === meQuery?.me?.account?.id;
  });

  const notificationIds: any = [];
  const readerId = meQuery?.me?.id;
  const state = "READ";

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleMarkRead = (noti: any = []) => {
    if (noti === true) {
      const input: any = {
        notificationId: notificationIds,
        state,
        readerId,
      };
      markNotification({
        variables: {
          input: input,
        },
      }).then(() => {
        refetch();
      });
    } else {
      const input: any = {
        notificationId: noti,
        state,
        readerId,
      };
      markNotification({
        variables: {
          input: input,
        },
      }).then((res: any) => {
        refetch();
      });
    }
  };

  const handleClick = (notiValue: any) => {
    window.open(
      process.env.REACT_APP_DOMAIN_URL +
        "/" +
        notiValue.user.profile?.customSharableString,
      "_blank"
    );
  };

  return (
    <DashboardCard title="Notifications" icon="bellYellow">
      <Button
        type="text"
        style={{
          fontSize: 15,
          textAlign: "right",
          marginTop: -34,
          marginBottom: 14,
        }}
        onClick={() => handleMarkRead(true)}
      >
        {LABEL.MARK_ALL_AS_READ}
      </Button>

      <S.Container>
        <Title style={{ fontSize: 36 }}>
          {notiList?.map((notiValue: any, index: any) => {
            notificationIds.push(notiValue.id);
            const currentDate: any = new Date();
            const notificationDate: any = new Date(notiValue.updatedAt);
            let finalNotificationTime = "";
            if (
              currentDate.getFullYear() === notificationDate.getFullYear() &&
              currentDate.getMonth() === notificationDate.getMonth() &&
              currentDate.getDate() === notificationDate.getDate()
            ) {
              finalNotificationTime = "Today";
            } else if (
              currentDate.getFullYear() === notificationDate.getFullYear() &&
              currentDate.getMonth() === notificationDate.getMonth() &&
              currentDate.getDate() === notificationDate.getDate() + 1
            ) {
              finalNotificationTime = "Yesterday";
            } else {
              finalNotificationTime = moment
                .utc(notiValue.updatedAt)
                .local()
                .startOf("seconds")
                .fromNow();
            }

            let unReadIcon;
            if (notiValue.status === false) {
              unReadIcon = UnReadNotificationIcon;
            } else {
              unReadIcon = "";
            }
            return (
              <ul style={{ fontSize: 15 }} key={index}>
                <Row
                  onClick={() => {
                    handleClick(notiValue);
                    handleMarkRead([notiValue.id]);
                  }}
                >
                  <Col span={7} style={{ cursor: "pointer" }}>
                    {notiValue.user.profile?.fullName}
                  </Col>
                  <Col span={10}>{notiValue.message}</Col>
                  <Col span={6}>{finalNotificationTime}</Col>
                  <Col span={1}>
                    <Image
                      src={unReadIcon}
                      style={{
                        width: 11,
                        textAlign: "right",
                        marginLeft: 8,
                        marginTop: 3,
                      }}
                    />
                  </Col>
                </Row>
              </ul>
            );
          })}
        </Title>
        <Space
          size={15}
          direction="vertical"
          style={{ padding: "0 1rem 0 32px" }}
        />
      </S.Container>
    </DashboardCard>
  );
};
