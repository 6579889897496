import { useState, useEffect, ReactElement } from "react";
import {
  Modal,
  Row,
  Col,
  Typography,
  Spin,
  Form,
  notification,
  Alert,
} from "antd";
import {
  AccountSubscriptionDocument,
  CurrentAccountUsersDocument,
  useInviteMemberToAccountMutation,
  useUpdateSubscriptionWithInviteMutation,
} from "codegen/generated/graphql";
import { ButtonTertiary } from "components";
import { LABEL } from "utils/constValues";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import { emailValidation } from "utils/validations";
import { ApolloError } from "@apollo/client";
import { STRIPE_PRODUCTION } from "pages/plan/constants";
import AlertModal from "pages/boards/components/alertModal";

const { Text, Title } = Typography;
/*
 * @archived
 * interface IProps {}
 * interface IState {
 *   emails: string[];
 * }
 */

const NewMemberInviteModal = ({
  isPeopleInviteModal,
  setShowPeopleInviteModal,
  accountData,
  accountSubscriptionData,
}: {
  isPeopleInviteModal: boolean;
  setShowPeopleInviteModal: (visible: boolean) => void;
  accountData: any;
  accountSubscriptionData: any;
}): ReactElement => {
  const { account } = accountData;
  const { accountSubscription } = accountSubscriptionData;
  const [error, setError] = useState(false);
  const [emails, setEmails] = useState<any>([]);
  const [alertModal, setAlertModal] = useState(false);

  const [isEnable, setIsEnable] = useState(false);
  const [updateSubscriptionWithInvite] =
    useUpdateSubscriptionWithInviteMutation();

  const [invalidEmails, setInvalidEmails] = useState<string[] | []>([]);
  const _invalidEmails: string[] = [];
  const unusedSeats: any =
    accountSubscription?.seats - accountSubscription?.seatsTaken;

  const [inviteMember] = useInviteMemberToAccountMutation({
    onCompleted: (res: any) => {
      if (res?.inviteMemberToAccount) {
        setEmails([]);
        setShowPeopleInviteModal(false);
        if (res.inviteMemberToAccount.addedUsers.length > 0) {
          const { addedUsers } = res.inviteMemberToAccount;
          let messageItem = "";
          addedUsers.map((item: string) => {
            if (messageItem === "") {
              messageItem = item;
            } else {
              messageItem += ", " + item;
            }
            return item;
          });
          notification.success({
            type: "success",
            message: messageItem + " invited successfully",
            placement: "bottomLeft",
          });
        }
        if (res.inviteMemberToAccount.alreadyExistUsers.length > 0) {
          const { alreadyExistUsers } = res.inviteMemberToAccount;
          let messageItem = "";
          alreadyExistUsers.map((item: string) => {
            if (messageItem === "") {
              messageItem = item;
            } else {
              messageItem += ", " + item;
            }
            return item;
          });
          Modal.warning({
            title: "Message",
            content: (
              <div>
                The following users have not been invited because they are
                already on another subscription. Please contact LLUNA support if
                you have any additional questions or needs.
                <br />
                <div style={{ marginTop: 5 }}>
                  {messageItem.split(",").map((str) => (
                    <>
                      <a href={`mailto: ${str}`}>{str}</a>
                      <br />
                    </>
                  ))}
                </div>
              </div>
            ),
          });
        }
      }
    },
    onError: (err: ApolloError) => {
      notification.error({
        type: "warning",
        message: err.message.split("[")[0],
        placement: "bottomLeft",
      });
    },

    refetchQueries: [
      AccountSubscriptionDocument,
      {
        query: CurrentAccountUsersDocument,
        variables: {
          input: {
            search: "",
            take: 30,
          },
        },
      },
    ],
  });

  useEffect(() => {
    handleCost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emails]);

  const handleAlertModal = async (val: boolean) => {
    setAlertModal(val);
  };

  const onFinish = async () => {
    if (emails?.length > 0) {
      const input = {
        emails: emails,
      };
      if (emails.length <= unusedSeats) {
        await inviteMember({
          variables: { input: input },
        });
      } else {
        handleUpdateSubscription();
      }
    } else {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  const handleCancel = () => {
    setEmails([]);
    setShowPeopleInviteModal(false);
  };

  const handleCost = () => {
    if (emails.length > unusedSeats) {
      let data: any;
      if (accountSubscription?.plan?.interval === "YEAR") {
        data = (
          +accountSubscription?.plan?.pricePerUnit *
            (emails.length - unusedSeats) *
            12 || 0
        ).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else if (accountSubscription?.plan?.interval === "MONTH") {
        data = (
          +accountSubscription?.plan?.pricePerUnit *
            (emails.length - unusedSeats) *
            1 || 0
        ).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
      return data;
    } else {
      return unusedSeats;
    }
  };

  const handleUpdateSubscription = () => {
    setIsEnable(true);
    const input: any = {
      emails: emails,
      planPriceId:
        process.env.REACT_APP_PRODUCTION === STRIPE_PRODUCTION
          ? accountSubscription?.plan?.liveStripePriceId
          : accountSubscription?.plan?.testStripePriceId,
      quantity: emails.length > unusedSeats ? emails.length - unusedSeats : 0,
    };
    updateSubscriptionWithInvite({
      variables: { input: input },
      refetchQueries: [
        AccountSubscriptionDocument,
        {
          query: CurrentAccountUsersDocument,
          variables: {
            input: {
              search: "",
              take: 30,
            },
          },
        },
      ],
    })
      .then((res) => {
        if (res?.data?.updateSubscriptionWithInvite) {
          setEmails([]);
          setIsEnable(false);
          setShowPeopleInviteModal(false);
          if (res.data.updateSubscriptionWithInvite.addedUsers.length > 0) {
            const { addedUsers } = res.data.updateSubscriptionWithInvite;
            let messageItem = "";
            addedUsers.forEach((item: string) => {
              if (messageItem === "") {
                messageItem = item;
              } else {
                messageItem += ", " + item;
              }
              return item;
            });
            notification.success({
              type: "success",
              message: messageItem + " invited successfully",
              placement: "bottomLeft",
            });
          }
          if (
            res.data.updateSubscriptionWithInvite.alreadyExistUsers.length > 0
          ) {
            const { alreadyExistUsers } = res.data.updateSubscriptionWithInvite;
            let messageItem = "";
            alreadyExistUsers.forEach((item: string) => {
              if (messageItem === "") {
                messageItem = item;
              } else {
                messageItem += ", " + item;
              }
              return item;
            });
            Modal.warning({
              title: "Message",
              content: (
                <div>
                  The following users have not been invited because they are
                  already on another subscription. Please contact LLUNA support
                  if you have any additional questions or needs.
                  <br />
                  <div style={{ marginTop: 5 }}>
                    {messageItem.split(",").map((str) => (
                      <>
                        <a href={`mailto: ${str}`}>{str}</a>
                        <br />
                      </>
                    ))}
                  </div>
                </div>
              ),
            });
          }
        }
      })
      .catch((err: ApolloError) => {
        setIsEnable(false);
        setEmails([]);
        notification.error({
          type: "warning",
          message: err.message.split("[")[0],
          placement: "bottomLeft",
        });
      });
  };

  /*
   * @archived
   * const handleCheckPlan = () => {
   *   if (accountSubscription?.interval === "YEAR") {
   *     return "annual ";
   *   } else if (accountSubscription?.interval === "MONTH") {
   *     return "Monthly ";
   *   }
   * };
   */

  const removeDuplicates = (list: string[]) => Array.from(new Set(list));

  return (
    <>
      <Modal
        visible={isPeopleInviteModal}
        destroyOnClose={true}
        footer={null}
        style={{
          overflow: "hidden",
          borderRadius: "1rem",
          textAlign: "center",
          padding: "0",
          position: "relative",
        }}
        centered
        closable={true}
        onCancel={handleCancel}
        width={"45rem"}
      >
        <Spin tip="Loading..." spinning={isEnable}>
          <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            autoComplete="off"
          >
            {error && (
              <Alert
                message="Please add at least one email"
                type="error"
                showIcon
                closable
                onClose={() => setError(false)}
                style={{ marginTop: 20 }}
              />
            )}
            <Row style={{ margin: " 0.3rem 0.3rem", textAlign: "justify" }}>
              <Col
                className="col-position"
                style={{ position: "static", width: "100%" }}
              >
                <Title style={{ fontSize: 25, fontWeight: "bold" }}>
                  Invite people to {account?.name}
                </Title>
              </Col>
              {accountSubscription?.seats > accountSubscription?.seatsTaken &&
              !(emails.length > unusedSeats) ? (
                <Col
                  className="col-position"
                  style={{ position: "static", marginTop: 5 }}
                >
                  <Title style={{ fontSize: 15 }}>
                    Note: You have {unusedSeats} unused seats. Inviting someone
                    will not impact your cost.
                  </Title>
                </Col>
              ) : (
                <Col
                  className="col-position"
                  style={{ position: "static", marginTop: 5 }}
                >
                  <Title style={{ fontSize: 15 }}>
                    {/* Note: Inviting additional users will increase your{" "}
                    {handleCheckPlan()?.toLowerCase()} cost{" "}
                    {emails?.length > 0 && `by $${handleCost()} per user.`} */}
                    Note: Inviting additional users may increase your monthly
                    cost.
                  </Title>
                </Col>
              )}

              <Col className="col-position" style={{ marginTop: 25 }}>
                <Title style={{ fontSize: 20, marginBottom: 10 }}>
                  {"TO:"}
                </Title>
                <ReactMultiEmail
                  style={{
                    width: "35rem",
                    height: 150,
                    fontSize: 15,
                    overflow: "auto",
                  }}
                  placeholder={"name@hellolluna.com, name@hellolluna.com,"}
                  emails={emails}
                  onChange={(_emails: string[]) => {
                    setInvalidEmails(removeDuplicates(_invalidEmails));
                    setEmails(removeDuplicates(_emails));
                  }}
                  validateEmail={(email) => {
                    // using this as an "onChange" event and getting rid of old values
                    if (invalidEmails.length === 0) {
                      setInvalidEmails([]);
                    }
                    if (email === "undefined") {
                      return false;
                    }
                    const isValid = emailValidation(email);
                    if (!isValid && _invalidEmails.indexOf(email) === -1) {
                      _invalidEmails.push(email);
                    }
                    return isValid;
                  }}
                  getLabel={(
                    email: string,
                    index: number,
                    removeEmail: (index: number) => void
                  ) => {
                    return (
                      <div data-tag key={index}>
                        {email}
                        <span
                          data-tag-handle
                          onClick={() => removeEmail(index)}
                        >
                          ×
                        </span>
                      </div>
                    );
                  }}
                />
              </Col>
              <Col
                style={{
                  marginTop: "1.5rem",
                  marginLeft: "auto",
                }}
              >
                <ButtonTertiary
                  htmlType="submit"
                  style={{
                    background: "#01083A",
                    width: "120px",
                    height: "40px",
                    borderRadius: "3px",
                    border: "none",
                    outline: "none",
                  }}
                >
                  <Text style={{ fontSize: 18 }} strong>
                    {LABEL.SEND}
                  </Text>
                </ButtonTertiary>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
      {alertModal && (
        <AlertModal
          isModalVisible={alertModal}
          setModalVisible={(val: any) => handleAlertModal(val)}
          message={LABEL.PROCEED_MSG}
          dualButton={{ show: false, labelText: LABEL.OK }}
          handleButtonAction
        />
      )}
    </>
  );
};

export default NewMemberInviteModal;
