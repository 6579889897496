import { Modal, Row, Col, Typography } from "antd";
import { ButtonTertiary } from "components";
import { ReactElement } from "react";

const { Text } = Typography;

const AlertModal = ({
  isModalVisible,
  setModalVisible,
  message,
  dualButton,
  handleButtonAction,
}: {
  isModalVisible: boolean;
  setModalVisible: (visible: boolean) => void;
  message: any;
  dualButton: any;
  handleButtonAction: any;
}): ReactElement => {
  const handleCancel = () => {
    setModalVisible(false);
  };
  return (
    <Modal
      visible={isModalVisible}
      footer={null}
      style={{
        overflow: "hidden",
        borderRadius: "1rem",
        textAlign: "center",
        padding: "0",
        position: "relative",
      }}
      centered
      closable={false}
      width={"450px"}
      onCancel={handleCancel}
    >
      <Row style={{ marginTop: "0.5rem" }}>
        <Col span={24}>
          <Text style={{ textAlign: "center" }}>{message}</Text>
        </Col>
        {dualButton?.show ? (
          <Col
            span={24}
            style={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ButtonTertiary
              onClick={() => setModalVisible(false)}
              style={{ marginRight: 10 }}
            >
              {dualButton?.cancelLabelText}
            </ButtonTertiary>
            <ButtonTertiary
              onClick={handleButtonAction}
              style={{ marginLeft: 10 }}
            >
              {dualButton?.labelText}
            </ButtonTertiary>
          </Col>
        ) : (
          <Col
            span={24}
            style={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ButtonTertiary onClick={() => setModalVisible(false)}>
              Ok
            </ButtonTertiary>
          </Col>
        )}
      </Row>
    </Modal>
  );
};

export default AlertModal;
