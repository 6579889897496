import { notification } from "antd";
import { Exact } from "codegen/generated/graphql";
import { MutationHookOptions } from "@apollo/client";

export enum WorkDesignRoutes {
  Mindset = "Mindset",
  Features = "Features",
  Summary = "Summary",
}

export const TABS = [
  WorkDesignRoutes.Mindset,
  WorkDesignRoutes.Features,
  WorkDesignRoutes.Summary,
];

export const WORK_DESIGN_FORM_NAME = "WorkDesignForm";
export const WORK_DESIGN_FORM_LIST_NAME = "WorkDesignFormList";

export const getMutationConfig = (
  onCompleted: () => void
): MutationHookOptions<
  // SubmitWorkDesignMutation,
  Exact<{ responses: any; workDesignId: string }>
> => ({
  onCompleted: () => {
    notification.success({
      type: "success",
      message: `Work design has been submitted.`,
      placement: "bottomLeft",
    });
    onCompleted();
  },
  onError: () => {
    notification.error({
      type: "error",
      message: `Something went wrong`,
      placement: "bottomLeft",
    });
  },
});
