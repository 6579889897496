import styled from "styled-components";

import { TagProps } from "antd/lib/tag";
import { Tag as AntdTag } from "antd";
import theme from "theme";

export const FrameworkProgressTag = styled<
  React.FC<TagProps & { isCompleted: boolean }>
>(AntdTag)`
  border-radius: 20px;
  padding: 5px 12px;
  align-items: center;
  background-color: ${({ isCompleted }) =>
    isCompleted ? theme.colors.green_40 : theme.colors.white};
  color: ${({ isCompleted }) =>
    isCompleted ? theme.colors.white : theme.colors.blue_100};
`;
