import { noop } from "./utils";
import { RouteType } from "types/route";

type TypeOfpages_admin_accounts = typeof import("pages/admin/accounts");
export const adminRoutes = {
  ADMIN_ACCOUNTS: {
    path: "/account",
    component: (): Promise<TypeOfpages_admin_accounts> =>
      import("pages/admin/accounts"),
    type: RouteType.Private,
    exact: false,
  },
  ADMIN_ACCOUNT_CREATE: {
    path: "/account/create",
    component: noop,
    type: RouteType.Private,
    exact: false,
  },
  ADMIN_ACCOUNT_USER_LIST: {
    path: "/account/:id",
    component: noop,
    type: RouteType.Private,
    exact: false,
  },
  ADMIN_ACCOUNT_USER_ADD: {
    path: "/account/:id/add",
    component: noop,
    type: RouteType.Private,
    exact: false,
  },
};
