import styled from "styled-components";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";
import { Col } from "antd";
import theme from "theme";

export const Wrapper = styled.div`
  padding: 24px 32px;
  height: 100%;
  overflow: auto;

  form {
    margin-top: 24px;
  }

  .ant-input-number,
  .ant-picker {
    width: 100%;
  }
`;

export const SectionWrapper = styled(Col)<{ active?: boolean }>`
  padding: 30px;
  margin-top: 15px;
  background: linear-gradient(
    109.04deg,
    rgba(255, 255, 255, 0.63) -23.19%,
    rgba(255, 255, 255, 0.09) 101.71%
  );
  box-shadow: 0px 11px 18px -5px rgba(1, 8, 58, 0.04);
  border-radius: 20px;
  color: ${({ active }) =>
    active ? theme.colors.white : theme.colors.blue_100};
  // disable blue background when selecting text
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
`;

export const UIRightOutlined = styled(RightOutlined)`
  font-size: 6px;
  vertical-align: middle;
  margin-right: 10px;
`;
