import styled from "styled-components";
import { Col } from "antd";
import theme from "theme";
import { default as AntTitle } from "antd/lib/typography/Title";

export const Wrapper = styled(Col)<{ active?: boolean }>`
  display: block;
  padding: 30px !important;
  margin-top: 15px;
  background: linear-gradient(
    109.04deg,
    rgba(255, 255, 255, 0.63) -23.19%,
    rgba(255, 255, 255, 0.09) 101.71%
  );
  box-shadow: 0px 11px 18px -5px rgba(1, 8, 58, 0.04);
  border-radius: 20px;
  color: ${({ active }) =>
    active ? theme.colors.white : theme.colors.blue_100};

  // disable blue background when selecting text
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
`;

export const Title = styled(AntTitle)<{ margin?: string }>`
  color: ${({ theme }) => theme.colors.blue_100} !important;
  font-weight: bold;
  margin: ${({ margin }) => (margin ? margin : 0)} !important;
`;
