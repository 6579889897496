import { Col, Form, Row, Checkbox, message, Tooltip } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {
  CreateUserMutationVariables,
  UserRole,
} from "codegen/generated/graphql";
import {
  MaterialInput,
  ButtonPrimary,
  PasswordTooltipContent,
} from "components";
import { useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { Routes } from "router/routes";

export const AccountUserAdd: React.VFC<{
  handleCreate: (fields: CreateUserMutationVariables) => Promise<any>;
}> = ({ handleCreate }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { xs } = useBreakpoint();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [isPasswordCheck, setIsPasswordCheck] = useState(false);

  const roles = [UserRole.PrimaryAdmin, UserRole.Admin, UserRole.Member];

  const verifyPassword: any = (password: any) => {
    const validatePassword = [];
    let checkValidatePassword = [];
    validatePassword.push(password?.length >= "8" ? true : false);
    validatePassword.push(password?.match(/[A-Z]/) ? true : false);
    validatePassword.push(password?.match(/[0-9]/) ? true : false);
    validatePassword.push(password?.match(/(?=.[!@#$%^&])/) ? true : false);
    checkValidatePassword = validatePassword?.filter((res: any) => {
      return res === false;
    });
    return checkValidatePassword.length > 0 ? true : false;
  };

  const passwordWatcher = Form.useWatch("password", form);

  return (
    <Form
      name={"signUpForm"}
      form={form}
      autoComplete={"off"}
      onFinish={({ repeatPassword, ...fields }) => {
        setLoading(true);
        handleCreate({ input: { ...fields, accountId: id } })
          .then(() => setLoading(false))
          .then(() =>
            history.push(
              generatePath(Routes.ADMIN_ACCOUNT_USER_LIST.path, { id })
            )
          )
          .catch((e: any) => {
            setLoading(false);
            message.error(e.message.split(".")[0]);
          });
      }}
    >
      <Row justify="start" gutter={[0, 16]}>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row justify="space-between" gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Value is required",
                      },
                      {
                        pattern: /.*\S.*/,
                        message: "Value is required",
                      },
                    ]}
                  >
                    <MaterialInput placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="surname"
                    rules={[
                      {
                        required: true,
                        message: "Value is required",
                      },
                      {
                        pattern: /.*\S.*/,
                        message: "Value is required",
                      },
                    ]}
                  >
                    <MaterialInput placeholder="Last Name" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <MaterialInput placeholder={"Email Address"} />
              </Form.Item>
            </Col>
            <Col span={12} style={{ marginRight: 10 }}>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldsValue }) => {
                  const { password } = getFieldsValue();
                  return (
                    <Tooltip
                      // visible={!!password && !xs}
                      visible={password ? verifyPassword(password) : false}
                      color="#f5f5f5"
                      placement={xs ? "bottom" : "right"}
                      overlayInnerStyle={{
                        boxShadow: "0px 2px 4px rgba(232, 232, 233, 0.5)",
                        width: "270px",
                        padding: "15px",
                        fontSize: "13px",
                      }}
                      trigger={"click"}
                      title={
                        <PasswordTooltipContent
                          isValidate={(val: boolean) => setIsPasswordCheck(val)}
                          isLengthValid={password?.length >= "8" ? true : false}
                          isUppercaseValid={
                            password?.match(/[A-Z]/) ? true : false
                          }
                          isStrengthValid={
                            password?.match(/[0-9]/) ? true : false
                          }
                          isSpecialValid={
                            password?.match(/(?=.[!@#$%^&])/) ? true : false
                          }
                        />
                      }
                    >
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                      >
                        <MaterialInput
                          placeholder={"Password"}
                          type="password"
                        />
                      </Form.Item>
                    </Tooltip>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="repeatPassword"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, repeatedPassword) {
                      if (
                        !repeatedPassword ||
                        repeatedPassword === getFieldValue("password")
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <MaterialInput
                  placeholder={"Repeat Password"}
                  type="password"
                  disabled={verifyPassword(passwordWatcher)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="roles">
                <Checkbox.Group options={roles}>
                  <Checkbox>Primary Admin</Checkbox>
                  <Checkbox>Admin</Checkbox>
                  <Checkbox>Team manager</Checkbox>
                  <Checkbox>Team member</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <ButtonPrimary
                loading={loading}
                htmlType="submit"
                disabled={!isPasswordCheck}
              >
                {!isPasswordCheck ? (
                  <b style={{ fontWeight: "500" }}>Sign up</b>
                ) : (
                  "Sign up"
                )}
              </ButtonPrimary>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
