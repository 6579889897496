import styled from "styled-components";

export const Wrapper = styled.div`
  display: inline-grid;
  justify-items: center;
  align-self: center;
  grid-gap: 20px;
`;

export const Container = styled.div`
  overflow: auto;
  height: 25vh;
  width: 100%;
  display: inline-grid;
  padding: 0 10px 10px 10px;

  @media (max-width: 640px) {
    padding: 0 20px 10px 10px;
    max-height: 100%;
  }
`;
