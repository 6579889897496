import { TableProps } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import { OrderType } from "codegen/generated/graphql";
import { useState, useMemo, useEffect } from "react";
import { isArray, get } from "lodash";
import { OperationVariables, QueryTuple } from "@apollo/client";

const PAGE_SIZE = 30;

const getSortOrder = (tableOrder: SortOrder | undefined): OrderType => {
  switch (tableOrder) {
    case "descend":
      return OrderType.Desc;
    case "ascend":
    default:
      return OrderType.Asc;
  }
};

type UseTableReturnType<TData> = {
  tableProps: TableProps<TData>;
  data: any;
  selected: string[];
};

export const useTable = <TData = any, TParams = any>(
  fetchDataSource: QueryTuple<TData, OperationVariables>,
  params: TParams
): UseTableReturnType<TData> => {
  const {
    searchInputValue,
    userScopeInput,
    key,
    additionalVariables = {},
  } = params as any;

  const [fetch, { data, loading }] = fetchDataSource;
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const dataSource = useMemo(() => {
    return get(data, key);
  }, [data, key]);
  /*
   * @archived
   * const cursor = useMemo<string | null | undefined>(
   *   () => dataSource?.cursor,
   *   [dataSource]
   * );
   */
  const [sortInfo, setSortInfo] = useState<{ key: string; order: OrderType }>();

  const handleSelectionChange = (nextSelectedIds: any) => {
    setSelectedIds(nextSelectedIds);
  };

  const variables = useMemo(() => {
    console.log("MyMemo", sortInfo, searchInputValue, userScopeInput);
    return {
      take: PAGE_SIZE,
      sort: sortInfo,
      search: searchInputValue,
      userScope: userScopeInput,
    };
  }, [sortInfo, searchInputValue, userScopeInput]);

  // @TODO implement this for XHR tables
  /*
   * @archived
   *const handleMore = useCallback(
   *(pagination: TablePaginationConfig) => {
   *  if (!loading && cursor) {
   *    fetchMore({
   *      variables: { input: { ...variables, cursor } },
   *    });
   *  }
   *},
   *[fetchMore, loading, variables, cursor]
   *);
   */

  // @TODO - get rid of additionalVariables and pass as variables to useLazyQuery?
  useEffect(() => {
    fetch({ variables: { input: variables, ...additionalVariables } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables]);

  /*
   * @archived
   * useEffect(() => {
   *   fetch({ variables: { input:{take:30}, ...additionalVariables } });
   *   // eslint-disable-next-line react-hooks/exhaustive-deps
   * }, []);
   */

  const props = useMemo<UseTableReturnType<TData>>(
    () => ({
      tableProps: {
        scroll: { x: true },
        pagination: {
          simple: true,
        },
        loading,
        onChange: (pagination, __, sorter) => {
          //handleMore(pagination);

          if (!isArray(sorter)) {
            const { columnKey, field } = sorter;
            const key = [columnKey, field].filter(Boolean)[0] as string;
            if (!key) return;

            setSortInfo(
              key && sorter.order
                ? {
                    key,
                    order: getSortOrder(sorter.order),
                  }
                : undefined
            );
          }
        },
        dataSource,
        rowSelection: { type: "checkbox", onChange: handleSelectionChange },
      },
      data,
      selected: selectedIds,
    }),
    [data, dataSource, loading, selectedIds]
  );

  return props;
};
