import { ReactElement, useRef } from "react";
import { useDrop, useDrag } from "react-dnd";
import type { XYCoord, Identifier } from "dnd-core";
import { ChoiceCreateInput } from "codegen/generated/graphql";
import { Input, Wrapper } from "components";
import * as S from "./styles";
import PlaceholderValue from "components/Placeholder";

interface Props {
  id: number;
  data: ChoiceCreateInput;
  onDelete?: () => void;
  onFocus?: () => void;
  onChange?: (propsToMerge: any, idx?: number) => void;
  onMove: (dragIndex: number, hoverIndex: number) => void;
  deletable?: boolean;
  isActive?: boolean;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const style = {
  cursor: "move",
};

export const Choice = ({
  deletable = true,
  data: choice,
  onDelete = () => void 0,
  onFocus,
  onChange,
  onMove,
  id,
  isActive = false,
}: Props): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: "Choice",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = id;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2 +
        hoverBoundingRect.top;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get y coordinate of mouse position
      const hoverClientY = (clientOffset as XYCoord).y;

      /*
       * @archived
       * Only perform the move when the mouse has crossed half of the items height
       * When dragging downwards, only move when the cursor is below 50%
       * When dragging upwards, only move when the cursor is above 50%
       */

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      onMove(dragIndex, hoverIndex);

      /*
       * @archived
       * Note: we're mutating the monitor item here!
       * Generally it's better to avoid mutations,
       * but it's good here for the sake of performance
       * to avoid expensive index searches.
       */
      item.index = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    type: "Choice",
    item: () => {
      return { id, index: choice.order - 1 };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  /*
   * @TODO - correct behavior should be switching the currently dragged item reference.
   * Now, the opacity is changing for the index of initialy dragged item.
   */

  /*
   * @archived
   *  Solvable by assigning the key to the parent component
   * const opacity = isDragging ? 0.1 : 1;
   */

  return (
    <div ref={ref} style={{ ...style }} data-handler-id={handlerId}>
      <Wrapper margin="1em 0 0 0">
        {deletable && (
          <S.DeleteChoiceIcon onClick={onDelete} name="deleteRed" />
        )}
        <Input
          label={`Choice no. ${id + 1}`}
          name="question"
          value={choice.text}
          autoFocus
          onFocus={onFocus}
          onChange={(e) => {
            onChange && onChange({ text: e.target.value });
          }}
          placeholder="Type choice here"
          disabled={!deletable}
          className={(isActive && "ant-input-focused") || ""}
          addonBefore={
            choice.placeholderValue && (
              <div onClick={() => onFocus && onFocus()}>
                <PlaceholderValue
                  isEditable
                  text={choice.placeholderText}
                  value={choice.placeholderValue}
                  onChangePlaceholderText={(value) =>
                    onChange && onChange({ placeholderText: value })
                  }
                />
              </div>
            )
          }
          addonAfter={choice.order}
        />
        {/* <Input
          label={`Choice no. ${id + 1} order`}
          name="order"
          value={choice.order.toString()}
          onChange={(e) => {
            onChange && onChange({ text: e.target.value });
            console.log(e.target.value);
          }}
          className={(isActive && "ant-input-focused") || ""}
        /> */}
      </Wrapper>
    </div>
  );
};
