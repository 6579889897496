import React, { useEffect } from "react";
import { Row, Col } from "antd";
import Text from "antd/es/typography/Text";
import { SText } from "./styles";
import { ExclamationCircleOutlined, CheckOutlined } from "@ant-design/icons";
import theme from "theme";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const passwordListItems = {
  length: ` At least 8 characters`,
  strength: ` At least one number`,
  uppercase: ` At least one capital letter`,
  char: ` At least one special character`,
};

type Props = {
  isUppercaseValid?: boolean;
  isStrengthValid?: boolean;
  isLengthValid?: boolean;
  isSpecialValid?: boolean;
  isValidate: (visible: boolean) => void;
  xs?: any;
};

export const PasswordTooltipContent: React.FC<Props> = (props) => {
  const {
    isUppercaseValid,
    isStrengthValid,
    isLengthValid,
    isSpecialValid,
    isValidate,
    xs,
  } = props;

  useEffect(() => {
    if (
      isUppercaseValid &&
      isStrengthValid &&
      isLengthValid &&
      isSpecialValid
    ) {
      isValidate(true);
    } else {
      isValidate(false);
    }
  }, [
    isUppercaseValid,
    isStrengthValid,
    isLengthValid,
    isSpecialValid,
    isValidate,
  ]);

  return (
    <Row>
      <Col span={24}>
        <Text
          strong
          style={{
            fontSize: xs ? "9px" : "13px",
            color: theme.colors.grey_70,
          }}
        >
          Current Password Strength
        </Text>
      </Col>
      <Col span={24}>
        <Text>
          {!isUppercaseValid ||
          !isStrengthValid ||
          !isLengthValid ||
          !isSpecialValid ? (
            <>
              <ExclamationCircleOutlined
                style={{ marginRight: "4px", color: theme.colors.red_40 }}
              />
              <SText strong textColor={"red"}>
                Weak
              </SText>
            </>
          ) : (
            <SText strong textColor={"green"}>
              Strong
            </SText>
          )}
        </Text>
      </Col>
      <Col span={24}>
        <Text
          style={{
            fontSize: xs ? "9px" : "13px",
            color: theme.colors.grey_70,
            fontWeight: "600",
          }}
        >
          The Password must have:
        </Text>
      </Col>
      <Col span={24}>
        <>
          {isLengthValid && (
            <CheckOutlined
              style={{ marginRight: "4px", color: theme.colors.green_40 }}
            />
          )}
          <SText textColor={!isLengthValid ? "red" : "green"}>
            {!isLengthValid
              ? `• ` + passwordListItems.length
              : passwordListItems.length}
          </SText>
        </>
      </Col>
      <Col span={24}>
        <>
          {isStrengthValid && (
            <CheckOutlined
              style={{ marginRight: "4px", color: theme.colors.green_40 }}
            />
          )}
          <SText textColor={!isStrengthValid ? "red" : "green"}>
            {!isStrengthValid
              ? `• ` + passwordListItems.strength
              : passwordListItems.strength}
          </SText>
        </>
      </Col>
      <Col span={24}>
        <>
          {isUppercaseValid && (
            <CheckOutlined
              style={{ marginRight: "4px", color: theme.colors.green_40 }}
            />
          )}
          <SText textColor={!isUppercaseValid ? "red" : "green"}>
            {!isUppercaseValid
              ? `• ` + passwordListItems.uppercase
              : passwordListItems.uppercase}
          </SText>
        </>
      </Col>
      <Col span={24}>
        <>
          {isSpecialValid && (
            <CheckOutlined
              style={{ marginRight: "4px", color: theme.colors.green_40 }}
            />
          )}
          <SText textColor={!isSpecialValid ? "red" : "green"}>
            {!isSpecialValid
              ? `• ` + passwordListItems.char
              : passwordListItems.char}
          </SText>
        </>
      </Col>
    </Row>
  );
};
