import { CSSProperties, FC, useContext } from "react";
import { Typography } from "antd";
import { useReactiveVar } from "@apollo/client";
import { selectedRoleVar, useApplicationStore } from "store/app";

import { ButtonSecondary } from "components";
import * as S from "./actionBanner.styles";

import { SetupProgressManager, SetupProgressMember } from "common/types";
import {
  MeQuery,
  UserRole,
  useGetReadMeQuery,
} from "codegen/generated/graphql";
import { Link } from "react-router-dom";
import { ActionBanners, actionBanners } from "constants/miscellaneous";
import { AuthContext } from "utils/context/auth";

type MeData = MeQuery["me"];

export interface ActionBannerProps {
  isLoading: boolean;
  meData?: MeData;
  style?: CSSProperties;
}

const { Text, Title } = Typography;

const useManagerInvariants = (meData?: MeData) => {
  /*
   * @archived
   * const { data: frameworksData, loading: loadingFrameworks } =
   *   useFrameworksCollectionQuery();
   */

  // const hasTeam = !!meData?.team;

  /*
   * @archived
   * const frameworks = frameworksData?.frameworks;
   * const hasFramework = frameworks && frameworks?.length > 0;
   */

  /*
   * @archived
   * const didShareFramework = frameworks?.some((framework) =>
   *   framework.status.includes("SHARED")
   * );
   */

  // if (!frameworks) return null;

  /*
   * @archived
   * if (hasTeam && !hasFramework) return SetupProgressManager.CreateFramework;
   * if (hasFramework && !didShareFramework)
   * return SetupProgressManager.ShareFramework;
   * if (hasFramework && didShareFramework)
   * return SetupProgressManager.FrameworkShared;
   */

  /*
   * @archived
   * READ-111 will need to update this once board and paid plans are created
   * return SetupProgressManager.CreateTeam;
   */
  const { user } = useContext(AuthContext);
  const { data: readmeData } = useGetReadMeQuery({
    variables: {
      userId: user?.id || "",
    },
  });
  const hasQuickStart = !!readmeData?.readMe?.quickStartComplete;
  if (hasQuickStart) return SetupProgressMember.ActivePOP;
  return SetupProgressMember.CreatePOP;
};

const useMemberInvariants = () => {
  const { user } = useContext(AuthContext);
  const { data: readmeData } = useGetReadMeQuery({
    variables: {
      userId: user?.id || "",
    },
  });
  const hasQuickStart = !!readmeData?.readMe?.quickStartComplete;
  if (hasQuickStart) return SetupProgressMember.ActivePOP;

  return SetupProgressMember.CreatePOP;
};

// @TODO - typing
const hooks: { [key in UserRole]?: any } = {
  [UserRole.PrimaryAdmin]: useManagerInvariants,
  [UserRole.Admin]: useManagerInvariants,
  //[UserRole.TeamManager]: useManagerInvariants,
  [UserRole.Member]: useMemberInvariants,
  [UserRole.LlunaAdmin]: () => {
    return null;
  },
};

// @TODO - create better logic for handling different invariants for members and managers
const ActionBanner: FC<ActionBannerProps> = ({ meData, isLoading, style }) => {
  const selectedRole = useReactiveVar(selectedRoleVar);
  const { user } = useApplicationStore();
  /*
   * @archived
   * const { data: frameworksData, loading: loadingFrameworks } =
   *   useFrameworksCollectionQuery();
   */

  const role = selectedRole?.id || user?.roles[0];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const useInvariants = hooks[role || UserRole.Member];
  const progress: SetupProgressManager | SetupProgressMember | null =
    useInvariants(meData);

  if (!role || !progress) return null;

  const actionBannersForRole = actionBanners[role as keyof ActionBanners];
  if (!actionBannersForRole) return null;

  const bannerConfig = actionBannersForRole[progress];

  // const frameworkEndDate = meData?.myLatestWorkDesign?.availableUntil;

  /*
   * @archived
   * const activeFrameworks = frameworksData?.frameworks.filter(
   *   (fw) => fw.status === FrameworkStatus.Shared
   * ).length;
   */

  // if (isLoading || loadingFrameworks) return <Loader />;
  return (
    <S.Wrapper
      bgColor={bannerConfig.bannerBgColor}
      style={style}
      bgSrc={bannerConfig.imageSrc}
    >
      <S.Container>
        <Title>{bannerConfig.headline}</Title>
        <div>
          <Text strong>{bannerConfig.firstLine}</Text>
          <br />
          <Text strong>{bannerConfig.secondLine}</Text>
        </div>
        <ButtonSecondary
          presetName={bannerConfig.buttonPreset}
          style={{ width: "10rem" }}
        >
          <Link to={bannerConfig.link}>
            <Text strong style={{ color: "white" }}>
              {bannerConfig.buttonText}
            </Text>
          </Link>
        </ButtonSecondary>
      </S.Container>
    </S.Wrapper>
  );
};

export default ActionBanner;
