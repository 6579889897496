import React from "react";
import { notification } from "antd";
import { useHistory } from "react-router-dom";
import {
  useCreateFeatureMutation,
  FeaturesDocument,
} from "codegen/generated/graphql";
import { Routes } from "router/routes";
import UpsertFeatureForm from "../components/UpsertFeatureForm";
import { StateProvider } from "../components/UpsertFeatureForm/context";

export const CreateNew: React.FC = () => {
  const history = useHistory();
  const [createFeature] = useCreateFeatureMutation({
    refetchQueries: [
      { query: FeaturesDocument, variables: { input: { take: 30 } } },
    ],
    onError: (e) => {
      notification.error({
        type: "error",
        message: `${e}`,
        placement: "bottomLeft",
      });
    },
    onCompleted: () => {
      notification.success({
        type: "success",
        message: "New Feature created",
        placement: "bottomLeft",
      });
      history.push(Routes.FRAMEWORKS_FEATURES.path);
    },
  });

  return (
    <StateProvider>
      <UpsertFeatureForm
        onSubmit={(values) =>
          createFeature({
            variables: {
              data: values,
            },
          })
        }
      />
    </StateProvider>
  );
};

export default CreateNew;
