import { Routes } from "router/routes";
import { MenuItem } from "types/menu";

export const menuItem = (def: MenuItem): MenuItem => {
  return def;
};

export const HELP_CENTER_URI = "https://help.hellolluna.com";
export const NEW_APP_URI = "https://app.hellolluna.com";

export const DASHBOARD = menuItem({
  key: "home",
  title: "Home",
  activeIcon: "panelsYellow",
  inactiveIcon: "panelsGrey",
  path: Routes.DASHBOARD.path,
});

export const HELP_CENTER = menuItem({
  key: "help-center",
  title: "Help Center",
  activeIcon: "resourcesYellow",
  inactiveIcon: "resourcesGrey",
  path: { pathname: HELP_CENTER_URI },
  target: "_blank",
});

export const README = menuItem({
  key: "readme",
  title: "Readme",
  activeIcon: "eclipseYellow",
  inactiveIcon: "eclipseGrey",
  path: Routes.README.path,
});

export const MY_POP = menuItem({
  key: "pop",
  title: "My POP",
  activeIcon: "eclipseYellow",
  inactiveIcon: "eclipseGrey",
  // path will need to change
  path: Routes.README.path,
});

export const BOARDS = menuItem({
  key: "boards",
  title: "Boards",
  activeIcon: "teamYellow",
  inactiveIcon: "teamGrey",
  // path  need  not to be  change
  path: Routes.BOARDS.path,
});

export const SETTINGS = menuItem({
  // key will need to change
  key: "my-profile",
  title: "Settings",
  activeIcon: "settingsYellow",
  inactiveIcon: "settingsGray",
  // path will need to change
  path: Routes.MY_PROFILE.path,
});

export const PREVIEW_LINK = menuItem({
  key: "preview",
  title: "Go to new POP",
  activeIcon: "rightArrowYellow",
  inactiveIcon: "rightArrowYellow",
  path: { pathname: NEW_APP_URI },
  target: "_top",
});
