import { UserRole } from "../../codegen/generated/graphql";
export type UserRoleConfig = {
  id: UserRole;
  title: string;
};

export const USER_ROLES: UserRoleConfig[] = [
  {
    id: UserRole.PrimaryAdmin,
    title: "Team",
  },
  {
    id: UserRole.Admin,
    title: "Team",
  },
  { id: UserRole.Member, title: "" },
];

export const USER_TABS_PRIMARY_ADMIN: UserRoleConfig[] = [
  {
    id: UserRole.PrimaryAdmin,
    title: "Team",
  },
  { id: UserRole.Member, title: "" },
];

export const USER_TABS_ADMIN: UserRoleConfig[] = [
  {
    id: UserRole.Admin,
    title: "Team",
  },
  { id: UserRole.Member, title: "" },
];

export const USER_TABS_MANAGER: UserRoleConfig[] = [
  { id: UserRole.Member, title: "" },
];
