import { FC, useEffect, useState } from "react";
import { Form, Select, Typography } from "antd";
import moment from "moment-timezone";

const { Text } = Typography;

type TimeZone = {
  value: string;
  label: string;
};

type Props = {
  name: string;
};

const sortTimeZonesByOffset = (timeZones: Array<moment.MomentZoneOffset>) => {
  timeZones.sort((a, b) => a.offset - b.offset);
};

const formatTimeZoneWithGmtOffset = (timeZone: string) => {
  const formattedTimeZone = timeZone.replaceAll("_", " ");
  const zulu = moment.tz(timeZone).format("Z z");
  return `${formattedTimeZone} (GMT${zulu})`;
};

const TimeZonePicker: FC<Props> = ({ name }) => {
  const [timeZoneList, setTimeZoneList] = useState<Array<TimeZone>>([]);

  const timeZoneOptions = timeZoneList.map((timeZone) => (
    <Select.Option value={timeZone.value} key={timeZone.value}>
      {timeZone.label}
    </Select.Option>
  ));

  useEffect(() => {
    const resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
    let region = "US"; // Default to US if unable to detect

    try {
      region = resolvedOptions.locale.split("-")[1];
    } catch (e) {
      console.error(`Unable to detect region ${e}`);
    }

    const timeZones = moment.tz.zonesForCountry(region, true);
    sortTimeZonesByOffset(timeZones);

    const formattedTimeZones = timeZones.map((timeZone) => ({
      value: timeZone.name,
      label: formatTimeZoneWithGmtOffset(timeZone.name),
    }));

    setTimeZoneList(formattedTimeZones);
  }, []);

  return (
    <Form.Item name={name} label={<Text strong>Time Zone</Text>}>
      <Select size="large">{timeZoneOptions}</Select>
    </Form.Item>
  );
};

export default TimeZonePicker;
