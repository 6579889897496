import { ReactElement, useState } from "react";
import { Row, Col, Space, Collapse, Typography, InputNumber } from "antd";
import * as S from "./Subscribe.styles";
import Button from "components/Button";
import { useReactiveVar } from "@apollo/client";
import { selectedPlanVar, planMutations } from "store/plan";
import * as T from "../Subscribe.styles";
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";
import { Checkout } from "./Payment";

const { Panel } = Collapse;
const { Title, Link: TextLink, Text } = Typography;

export const withCommas = (num: string): string =>
  num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const PlanSummary = ({
  setModalContent,
  planData,
}: {
  setModalContent: any;
  planData: any;
}): ReactElement => {
  const activePlans = planData?.prices?.filter((item: any) => item?.isActive);
  const planTuple = useReactiveVar(selectedPlanVar);
  const { updatePlan, updateAmountOfSeats } = planMutations;
  const [quantity, setquantity] = useState<any>(planData?.meta?.freeUser);
  const [selectedRadio] = useState<any>(activePlans[0]);
  if (!planTuple) return <></>;
  const onContinueHandler = async () => {
    setModalContent(
      <Checkout
        planData={planData}
        quantity={quantity}
        priceData={selectedRadio}
        setModalContent={setModalContent}
        subscription={true}
        handleModal
      />
    );
  };
  const pricePerUnit = planData?.prices?.find(
    (priceUnit: any) => priceUnit.id === "Annual-plus-1"
  ).pricePerUnit;
  const metaData: any = planData?.prices?.find(
    (metadata: any) => metadata?.id === "Annual-plus-1"
  ).meta?.intervalText;
  return (
    <Row gutter={24}>
      <Col span={24}>
        <T.TitleContainer justify="space-between" align="middle">
          <Text
            type="secondary"
            style={{ display: "flex", alignItems: "center", gap: "1rem" }}
          >
            <Title level={1}>Plus |</Title>
            <Text style={{ fontSize: 25 }}>
              Starting at ${pricePerUnit} {metaData}, billed annually
            </Text>
          </Text>
          <TextLink
            strong
            onClick={() => {
              updatePlan(null, 0);
              setModalContent("");
            }}
          >
            <ArrowLeftOutlined style={{ fontSize: 10 }} /> Back to Select a Plan
          </TextLink>
        </T.TitleContainer>
      </Col>
      <Col span={12} style={{ display: "flex" }}>
        <S.FancyCard>
          <Space direction="vertical" size="large">
            <S.TextTitle strong>
              Boost your subscription with add on services available at any
              time.
            </S.TextTitle>
          </Space>
          <S.Collapse ghost defaultActiveKey={["1"]}>
            {/* {plan.details.map((detail, i) => ( */}
            <Panel
              style={{ padding: "16px 0" }}
              header={"Live team readout sessions"}
              key={1}
            >
              <Space direction="vertical" size="small">
                <S.Text>
                  {
                    "Schedule either in-person or remote sessions with your team and a LLUNA expert to review team board results and actions. A great way to kickoff your subscription! "
                  }
                </S.Text>
              </Space>
            </Panel>
            <Panel
              style={{ padding: "16px 0" }}
              header={"Future of Work coaching"}
              key={2}
            >
              <Space direction="vertical" size="small">
                <S.Text>
                  {
                    "Consulting services on topics including: company culture curation, future of work roadmap, manager training & ERG design, and employee listening strategy"
                  }
                </S.Text>
              </Space>
            </Panel>
          </S.Collapse>
          <S.TextTitle strong>
            Reach out to the LLUNA team to discuss your specific needs.
          </S.TextTitle>
          <S.Paragraph style={{ marginTop: 23, fontSize: 15 }}>
            Contact us at{" "}
            <a href="mailto: howdy@hellolunna.com">howdy@hellolluna.com</a>
          </S.Paragraph>
        </S.FancyCard>
      </Col>
      <Col span={12} style={{ display: "flex" }}>
        <S.FancyCard>
          <Space direction="vertical" size="middle">
            <S.TextTitle strong>
              All plans include {planData?.meta?.freeUser} free users. Would you
              like to purchase additional seats now?
            </S.TextTitle>
            <Row>
              <Col span={8}>
                <InputNumber
                  value={quantity}
                  onChange={(e: any) => {
                    if (e >= 2) {
                      updateAmountOfSeats(e);
                      setquantity(e);
                    }
                  }}
                  name="seats"
                  style={{ marginBottom: "9px" }}
                  min={2}
                />
              </Col>
            </Row>
          </Space>
          <Row gutter={[8, 8]} style={{ margin: "24px 0" }}>
            <Col span={24} style={{ marginTop: "40px" }}>
              <S.TextTitle strong> Total Price </S.TextTitle>
            </Col>
            <Col span={24} style={{ marginBottom: "40px" }}>
              <Title level={3}>
                $
                {selectedRadio?.interval === "YEAR"
                  ? (
                      +selectedRadio?.pricePerUnit * 12 * (+quantity - 2) || 0
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : (
                      +selectedRadio?.pricePerUnit * (+quantity - 2) || 0
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                <S.TextTitle>
                  {" "}
                  per {selectedRadio?.interval === "YEAR" ? "year" : "month"}
                </S.TextTitle>
              </Title>
            </Col>
          </Row>

          <S.ButtonWrap>
            <Space direction="vertical" size="middle">
              <Button
                type="primary"
                $bgColor="#01083A"
                $hoverBgColor="#01083A"
                size="large"
                $height="48px"
                $width="100%"
                onClick={onContinueHandler}
              >
                Continue
              </Button>
            </Space>
          </S.ButtonWrap>
        </S.FancyCard>
      </Col>
    </Row>
  );
};
