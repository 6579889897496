import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { ReactElement } from "react";
const CouponsModal = ({
  isVisible,
  setisVisible,
}: {
  isVisible: boolean;
  setisVisible: any;
  setCouponData: any;
}): ReactElement => {
  const handleCancel = () => {
    setisVisible(false);
  };

  return (
    <Modal
      maskClosable={false}
      destroyOnClose={true}
      title="Add Coupons"
      visible={isVisible}
      footer={null}
      style={{
        overflow: "hidden",
        borderRadius: "1rem",
        padding: "0",
        position: "relative",
      }}
      onCancel={handleCancel}
      closeIcon={
        <CloseOutlined style={{ position: "relative", bottom: 10, left: 10 }} />
      }
    ></Modal>
  );
};
export default CouponsModal;
