import {
  FeatureType,
  FeatureCreateInput,
  FeatureUpdateInput,
} from "codegen/generated/graphql";
import React, { createContext, Dispatch, useContext, useReducer } from "react";

const INITIAL_FORM = {
  choices: [],
  dimensionId: "",
  text: "",
  title: "",
  type: FeatureType.SingleOption,
};
type StoreContext = {
  state: State;
  dispatch: Dispatch<Action>;
  onChange: (arg: keyof InputData) => Dispatch<any>;
};
const initialState: State = {
  data: INITIAL_FORM,
  activeTab: "dimension",
};
export const store = createContext<StoreContext>({
  state: initialState,
  dispatch: () => void 0,
  onChange: () => () => void 0,
});

const { Provider } = store;

type State = {
  data: InputData;
  activeTab: string;
};

type InputData = FeatureCreateInput | FeatureUpdateInput;

export enum ActionType {
  UPDATE,
  UPDATE_ALL,
  CHANGE_TAB,
}

type Action =
  | { type: ActionType.UPDATE; key: keyof InputData; value: any }
  | { type: ActionType.UPDATE_ALL; data: InputData }
  | { type: ActionType.CHANGE_TAB; key: string };

export const StateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    (state: State, action: Action): State => {
      switch (action.type) {
        case ActionType.UPDATE:
          return {
            ...state,
            data: { ...state.data, [action.key]: action.value },
          };
        case ActionType.UPDATE_ALL: {
          return { ...state, data: { ...state.data, ...action.data } };
        }
        case ActionType.CHANGE_TAB:
          return { ...state, activeTab: action.key };
        default:
          throw new Error();
      }
    },
    initialState
  );

  const onChange = (key: keyof InputData) => (value: any) =>
    dispatch({ type: ActionType.UPDATE, key, value });

  return <Provider value={{ state, dispatch, onChange }}>{children}</Provider>;
};

export const useFeatureContext = (): StoreContext => useContext(store);
