import styled, { css } from "styled-components";
import { Collapse as AntCollapse } from "antd";

export const Grid = styled.div<{ isData: boolean }>`
  margin: 15px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: auto auto ${({ isData }) =>
      isData ? "430px 200px" : "350px"};
  gap: 25px 20px;
  grid-template-areas:
    ". . ."
    ". . ."
    ". . ."
    ". . ."
    "preferredTimes teamLocations teamLocations"
    "preferredTimes frameworkInsights frameworkInsights"
    "dimensions dimensions dimensions";
`;
/////////////////// Heading section with exporting buttons & filters
export const HeaderWrap = styled.div`
  grid-column: 1 / 4;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey_30};
`;
type RevenueProp = { lastItem?: boolean; highlightedText?: boolean };

export const RevenueImpactRow = styled.div<RevenueProp>`
  display: grid;
  grid-template-columns: 65% 35%;
  grid-auto-rows: 100%;
  gap: 5px;
  margin: 3px 5px;
  border-bottom: 1px solid
    ${({ theme, lastItem }) => (lastItem ? "transparent" : theme.colors.grey_1)};
  font-size: 0.9em;
  color: ${({ theme }) => theme.colors.grey_70};
`;

export const RevenueImpactItem = styled.div<RevenueProp>`
  color: ${({ theme, highlightedText }) =>
    highlightedText ? theme.colors.blue_100 : "inherit"};
`;

export const RevenueImpactValue = styled.div<{ value: number }>`
  font-size: 1em;
  text-align: right;
  color: ${({ theme }) => {
    return theme.colors.blue_100;
  }};
  ${({ value }) => {
    if (value < 0) {
      return css`
        position: relative;
        left: -6px;
      `;
    }
  }}
`;

export const DimensionsWrap = styled.div`
  grid-area: dimensions;
`;

export const DimensionWrap = styled.div`
  margin: 20px 0;
`;

export const DimensionReports = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(auto-fill, 1fr); */
  /* grid-template-rows: masonry; */
`;

export const Collapse = styled(AntCollapse)`
  border: none;
  background-color: transparent;
  & > .ant-collapse-item:last-child {
    border: 0;
  }
  & > div > .ant-collapse-content {
    border: 0;
    background-color: transparent;
  }
`;
export const Feature = styled.div<{ isOpenText: boolean }>`
  grid-column: span ${({ isOpenText }) => (isOpenText ? "3" : "1")};
`;

export const HeaderActions = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
