import React, { useState } from "react";
import { BackgroundContainer } from "components/BackgroundContainer";
import { ContainerWithImage } from "components/ContainerWithImage";
import { Me } from "store/app/types";

import skyGirlSvg from "assets/images/logIn.svg";
import { LogInForm } from "../../components/Forms";
import {
  SyncFirebaseUserMutationOptions,
  useAccountUserByEmailQuery,
  useCreateAccountMutation,
  useSyncFirebaseUserMutation,
} from "codegen/generated/graphql";
import {
  getAuthErrorMessage,
  linkDifferentCredential,
  logSignIn,
  newSignInWithPassword,
  newSignInWithProvider,
  updateLastLoggedIn,
} from "utils/firebase";
import { userName } from "utils/helpers";
import { useHistory } from "react-router-dom";
import { Modal, notification } from "antd";
import { ApolloError } from "@apollo/client";
import { appMutations } from "store/app";
import { Routes } from "router/routes";

type Props = {
  props?: string;
};

/**
 * @description Login page
 */
const LogIn: React.FC<Props> = () => {
  const history = useHistory();
  const [createAccount] = useCreateAccountMutation();
  const [syncFirebaseUser] = useSyncFirebaseUserMutation();
  const [loading, setLoading] = useState(false);
  const { refetch } = useAccountUserByEmailQuery({
    variables: {
      email: "",
    },
  });
  let me: Me;
  const handleSignInWithPassword = (email: any, password: any) => {
    setLoading(true);
    newSignInWithPassword(email, password)
      .then(logSignIn)
      .then(updateLastLoggedIn)
      .catch((e: any) => {
        if (e.message.includes("Firebase")) {
          notification.error({
            type: "error",
            message: "Incorrect email/password",
            placement: "bottomLeft",
          });
        } else {
          notification.error({
            type: "error",
            message: e.message.split("[")[0],
            placement: "bottomLeft",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSignInWithProvider = (providerName: any) => {
    newSignInWithProvider(providerName)
      .then(async (res: any) => {
        if (res) {
          const { user, _tokenResponse } = res;
          const variables: any = {
            email: user.email,
          };
          refetch(variables).then((result: any) => {
            if (result?.data?.accountUserByEmail !== null) {
              // Synchronize Firebase and LLUNA shared fields
              const options: SyncFirebaseUserMutationOptions = {
                variables: {
                  email: user.email,
                  sso: true,
                },
              };
              syncFirebaseUser(options).then(({ data }) => {
                const isEmailVerified = Boolean(
                  data?.syncFirebaseUser.isVerified
                );

                if (isEmailVerified) {
                  history.push(Routes.DASHBOARD.path);
                } else {
                  appMutations.reset();
                  history.push(Routes.ERROR.path);
                }
              });
            } else {
              const resultName: any = userName(user.displayName);
              const userObj: any = {
                firebaseProvider: providerName,
                firebaseId: user?.uid,
                email: user?.email,
              };
              const inputData: any = {
                name: _tokenResponse
                  ? _tokenResponse.displayName
                  : user.displayName,
                firstName: _tokenResponse
                  ? _tokenResponse.firstName
                  : resultName.firstName,
                lastName: _tokenResponse
                  ? _tokenResponse.lastName
                  : result.lastName,
                user: userObj,
              };
              createAccount({
                variables: {
                  input: inputData,
                },
              })
                .then((result) => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  me = result?.data?.createAccount.users[0] as Me;
                  history.push("/plans");
                })
                .catch((err: ApolloError) => {
                  notification.error({
                    type: "warning",
                    message: err.message.split("[")[0],
                    placement: "bottomLeft",
                  });
                });
            }
          });
        }
      })
      .catch((e) => {
        if (
          getAuthErrorMessage(e) ===
          "Authentication failed: account exists with different credential"
        ) {
          Modal.error({
            title: `${getAuthErrorMessage(e)}. Please try with password`,
          });
        } else {
          Modal.error({
            title: `${getAuthErrorMessage(e)}`,
          });
        }
      })
      .catch((e) => {
        linkDifferentCredential(e).catch((differentCredentialError) => {
          Modal.error({
            title: ` ${differentCredentialError}`,
          });
        });
      });
  };
  return (
    <BackgroundContainer>
      <ContainerWithImage image={{ src: skyGirlSvg, alt: "sky-girl" }}>
        <LogInForm
          signInWithPassword={handleSignInWithPassword}
          signInWithProvider={handleSignInWithProvider}
          loading={loading}
        />
      </ContainerWithImage>
    </BackgroundContainer>
  );
};
export default LogIn;
