import React, { useContext } from "react";
import { Space, Typography } from "antd";
import { DashboardCard } from "../DashboardCard";
import * as S from "./styles";
import { ButtonSecondary } from "components";
import { AuthContext } from "utils/context/auth";
import { Link } from "react-router-dom";

export const UpdateProfile: React.FC = () => {
  const { Text } = Typography;
  const { user } = useContext(AuthContext);

  return (
    <DashboardCard span={24} icon="settingsYellow" title="Update My Profile">
      <S.Container>
        <Space
          size={18}
          direction="vertical"
          style={{ padding: "0 1rem 0 32px" }}
        />
        <div style={{ fontSize: 14, textAlign: "left", padding: "10px" }}>
          Customize information including your timezone, preferred pronouns,
          profile picture - and more!
        </div>
        <ButtonSecondary
          presetName="blue"
          style={{ width: "6rem", margin: "auto", marginTop: "2rem" }}
        >
          <Link to={`/my-profile/${user?.id}`}>
            <Text strong style={{ color: "white" }}>
              Update
            </Text>
          </Link>
        </ButtonSecondary>
      </S.Container>
    </DashboardCard>
  );
};
