import { Typography } from "antd";
import { SelectPlan } from "../components/SelectPlan";
import plans from "../plans.json";
import { Plan } from "types/plan";
import * as S from "../Subscribe.styles";
import { ReactElement } from "react";

const { Title, Text } = Typography;

// @TODO - think more about using classNames inside styled components

export const PlanSelectContainer = (): ReactElement => {
  return (
    <>
      <S.TitleContainer justify="space-between" align="middle">
        <Title level={1}>Select plan</Title>
        <Text className="trial-feature">7-day free trial on all plan</Text>
      </S.TitleContainer>
      <SelectPlan plans={plans as Plan[]} />
    </>
  );
};
