import { FC } from "react";
import { useDashboardTeamMemberQuery } from "codegen/generated/graphql";
import ContentLayout from "layout/ContentLayout";
import { ContentType } from "layout/ContentLayout/ContentLayout";
import { Col, Row } from "antd";
import { ActionBanner } from "components";
import * as S from "./styles";
import { Notifications } from "../components/Notifications";
import { ShareMyPOP } from "../components/ShareMyPOP";
import { CurrentPOP } from "../components/CurrentPOP";
import { UpdateProfile } from "../components/UpdateProfile";

type Props = {
  xs?: boolean;
};
export const Dashboard: FC<Props> = ({ xs }) => {
  const { data, loading } = useDashboardTeamMemberQuery();

  if (!data) return null;

  return (
    <ContentLayout typeContent={ContentType.FullWidth}>
      <S.ContentWrap>
        <Row style={{ display: "flex", flex: 1 }} gutter={[15, 10]}>
          <Col span={24}>
            <ActionBanner meData={data?.me} isLoading={loading} />
          </Col>
        </Row>
        <Row style={{ display: "flex", flex: 1 }} gutter={[15, 10]}>
          <Col lg={12} sm={24}>
            <CurrentPOP />
          </Col>
          <Col lg={12} sm={24}>
            <S.ContentWrap>
              <Col lg={24} md={24} sm={24}>
                <S.ContentWrap>
                  <Row style={{ display: "flex", flex: 1 }} gutter={[15, 10]}>
                    <Col
                      lg={12}
                      sm={24}
                      xs={24}
                      md={12}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <UpdateProfile />
                    </Col>
                    <Col lg={12} sm={24} xs={24} md={12}>
                      <ShareMyPOP />
                    </Col>
                  </Row>
                </S.ContentWrap>
              </Col>
              <Row gutter={[15, 10]}>
                <Col span={24}>
                  <Notifications />
                </Col>
              </Row>
            </S.ContentWrap>
          </Col>
        </Row>
        {/* )} */}
      </S.ContentWrap>
    </ContentLayout>
  );
};
