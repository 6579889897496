import React, { useState, ReactElement, useCallback } from "react";
import { Modal, ModalProps as AntModalProps } from "antd";

type InitCallback<T> = (props?: T) => void;
type OnSave = () => void;
type OnHide = () => void;
type OnConfirm = () => void;

export type UseModalType<T> = [
  (props: T) => ReactElement | null,
  InitCallback<T>
];

export interface ModalProps {
  setShow: (show: boolean) => void;
  onHide?: OnHide;
  onConfirm?: OnConfirm;
}

export function useModal<T>(
  Dialog: React.FC<T & ModalProps>,
  modalProps?: AntModalProps,
  onSave?: OnSave
): UseModalType<T & { onHide?: () => void }> {
  const [showDialog, setShowDialog] = useState(false);
  const [cProps, setCProps] = useState<T | undefined>();

  const initDialog = (props?: T) => {
    setCProps(props);
    setShowDialog(true);
  };

  const createDialog = useCallback(
    (props: T & { onHide?: () => void }): ReactElement | null => {
      const localSetShow = (show: boolean) => {
        if (!show && showDialog && props.onHide) {
          props.onHide();
        }

        setShowDialog(show);
      };

      const handleHide = () => {
        if (props.onHide && showDialog) {
          props.onHide();
        }
        setShowDialog(false);
      };

      const handleSave = () => {
        if (onSave) {
          onSave();
        }

        setShowDialog(false);
      };

      return (
        <Modal
          visible={showDialog}
          onCancel={handleHide}
          footer={null}
          bodyStyle={{ overflow: "hidden", background: "#f8f8ff" }}
          {...modalProps}
        >
          <Dialog
            {...{ ...props, ...cProps }}
            setShow={localSetShow}
            onConfirm={handleSave}
          />
        </Modal>
      );
    },
    [Dialog, cProps, modalProps, onSave, showDialog]
  );

  return [createDialog, initDialog];
}

export function makeUseModal<T>(
  Dialog: React.FC<T & ModalProps>,
  props?: AntModalProps
) {
  return (): UseModalType<T & { onHide?: () => void }> =>
    useModal<T>(Dialog, props);
}
