import { ButtonCore } from "components";
import styled from "styled-components";
import { Tag as AntdTag, TagProps } from "antd";
import theme from "theme";

export const Container = styled.div`
  overflow: auto;
  height: 50vh;
  width: 100%;
  display: inline-grid;
  padding: 0 10px 10px 30px;
  @media (max-width: 640px) {
    height: 100%;
  }
`;

export const WrapperCenter = styled.div`
  display: inline-grid;
  justify-items: center;
  align-self: center;
  grid-gap: 20px;
`;
export const WrapperLeft = styled.div`
  display: inline-grid;
  justify-items: left;
  align-self: left;
`;
export const VideoButton = styled(ButtonCore)`
  background-color: ${({ theme }) => theme.colors.blue_30};
  color: ${({ theme }) => theme.colors.blue_100};
  font-weight: bolder;
  height: 48px;
  width: 212px;
`;
export const FrameworkProgressTag = styled<
  React.FC<TagProps & { isCompleted: boolean }>
>(AntdTag)`
  border-radius: 20px;
  padding: 5px 12px;
  align-items: center;
  background-color: ${({ isCompleted }) =>
    isCompleted ? theme.colors.green_40 : theme.colors.white};
  color: ${({ isCompleted }) =>
    isCompleted ? theme.colors.white : theme.colors.blue_100};
`;
