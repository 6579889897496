import { ReactElement, useEffect, useState } from "react";
import {
  Row,
  Col,
  Space,
  Modal,
  Divider,
  Typography,
  notification,
  Input,
} from "antd";

import { loadStripe } from "@stripe/stripe-js/pure";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { history } from "router/history";
import * as S from "./Subscribe.styles";
import * as Sp from "./Payment.styles";
import { ButtonPrimary } from "components/Button";
import { ApolloError, useLazyQuery } from "@apollo/client";
import {
  useStripeSetupPaymentIntentMutation,
  useCreateSubscriptionMutation,
  MeDocument,
  useChangeSubscriptionPaymentMethodMutation,
  AccountSubscriptionDocument,
  CustomerPaymentMethodsDocument,
  GetStripeCouponDocument,
} from "codegen/generated/graphql";
import { Routes } from "router/routes";
import { Loader } from "components";
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";

import { PlanSummary } from "./PlanSummary";
import { STRIPE_PRODUCTION } from "../constants";
import { useLocation } from "react-router-dom";
import CouponsModal from "./CouponsModal";

export const Checkout = ({
  planData,
  quantity,
  priceData,
  setModalContent,
  subscription,
  handleModal,
}: {
  planData: any;
  quantity: any;
  priceData: any;
  setModalContent: any;
  subscription: boolean;
  handleModal: any;
}): ReactElement => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? ""
  );
  const [stripeSetupPaymentIntent] = useStripeSetupPaymentIntentMutation();
  const [clientSecret, setClientSecret] = useState<any>("");
  const [isCheckoutFormReady, setIsCheckoutFormReady] = useState(false);
  const [t, setT] = useState(false);

  useEffect(() => {
    async function fetchClientSecret() {
      const { data, errors } = await stripeSetupPaymentIntent();
      if (!errors && data) {
        setClientSecret(data?.stripeSetupPaymentIntent?.clientSecret || "123");
        setTimeout(() => setT(true), 0);
      }
    }
    fetchClientSecret();
  }, [stripeSetupPaymentIntent]);

  if (!t) return <Loader />;

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
      }}
    >
      <div
        style={{
          maxHeight: t && isCheckoutFormReady ? 1000 : 30,
          transition: "all",
          transitionDuration: "1000ms",
          transitionTimingFunction: "ease-out",
          overflow: "hidden",
        }}
      >
        {(!t || !isCheckoutFormReady) && <Loader />}
        <Payment
          setIsCheckoutFormReady={setIsCheckoutFormReady}
          planData={planData}
          quantity={quantity}
          priceData={priceData}
          setModalContent={setModalContent}
          subscription={subscription}
          handleModal={handleModal}
        />
      </div>
    </Elements>
  );
};

const Payment: React.VFC<{
  setIsCheckoutFormReady: (state: boolean) => void;
  planData: any;
  quantity: any;
  priceData: any;
  setModalContent: any;
  subscription: boolean;
  handleModal: any;
}> = ({
  setIsCheckoutFormReady,
  planData,
  quantity,
  priceData,
  setModalContent,
  subscription,
  handleModal,
}) => {
  const [getStripeCoupon] = useLazyQuery(GetStripeCouponDocument);
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isVisible, setisVisible] = useState<any>(false);
  const [couponData, setCouponData] = useState<any>();
  const [createSubscribtion] = useCreateSubscriptionMutation();
  const [updatePayment] = useChangeSubscriptionPaymentMethodMutation();
  const [checkcouponId, setCouponId] = useState("");
  if (!stripe || !elements) return null;

  const { Title, Text, Link } = Typography;
  const handleClick = async (e: any) => {
    getStripeCoupon({
      variables: {
        code: checkcouponId,
      },
    }).then(({ data }: { data: any }) => {
      if (data?.getStripeCoupon.length) {
        setCouponData(
          data?.getStripeCoupon?.find(
            (coupon: any) => coupon.code === checkcouponId
          )?.coupon
        );
      } else {
        alert("You have invalid coupon");
      }
    });
  };

  const finalPrice = () => {
    /*
     * @archived
     * const priceWithDiscount_100 =
     *   +priceData?.pricePerUnit *
     *     (12 -
     *       (couponData?.percent_off === 100
     *         ? couponData?.duration_in_months || 0
     *         : 0)) *
     *     (+quantity - 2) || 0;
     */
    const priceWithotherDiscounts =
      (+priceData?.pricePerUnit *
        (12 -
          (couponData?.percent_off === 100
            ? couponData?.duration_in_months || 0
            : 0)) *
        (+quantity - 2) || 0) -
      (couponData?.percent_off
        ? (+priceData?.pricePerUnit *
            (12 -
              (couponData?.percent_off === 100
                ? couponData?.duration_in_months || 0
                : 0)) *
            (+quantity - 2) || 0) *
          (couponData?.percent_off / 100)
        : 0);
    return (
      <span>
        $
        {priceData?.interval === "YEAR"
          ? priceWithotherDiscounts.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : (+priceData?.pricePerUnit * (+quantity - 2) || 0).toLocaleString(
              undefined,
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )}
      </span>
    );
  };

  const totalPriceLabel = (
    <span>
      $
      {priceData?.interval === "YEAR"
        ? (+priceData?.pricePerUnit * 12 * (+quantity - 2) || 0).toLocaleString(
            undefined,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )
        : (+priceData?.pricePerUnit * (+quantity - 2) || 0).toLocaleString(
            undefined,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}
    </span>
  );

  const onContinueHandler = async () => {
    setLoading(true);
    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
    });
    if (error) {
      Modal.error({ title: error.message });
      setLoading(false);
    }

    if (!subscription && setupIntent) {
      handleModal(false);
      const input: any = {
        striptPaymentMethodId: setupIntent?.payment_method,
      };
      updatePayment({
        variables: { input: input },
        refetchQueries: [
          AccountSubscriptionDocument,
          MeDocument,
          CustomerPaymentMethodsDocument,
        ],
      })
        .then((res) => {
          if (res.data) {
            handleModal(false);
            notification.success({
              type: "success",
              message: "Payment method updated successfully",
              placement: "bottomLeft",
            });
          }
        })
        .catch((err: ApolloError) => {
          notification.error({
            type: "warning",
            message: err.message.split("[")[0],
            placement: "bottomLeft",
          });
        });

      setLoading(false);
    } else if (setupIntent && setupIntent.payment_method) {
      const subscriptionResult = await createSubscribtion({
        variables: {
          input: {
            quantity: quantity,
            couponId: couponData ? couponData?.id : null,
            paymentMethodId: setupIntent.payment_method as string,
            planPriceId:
              process.env.REACT_APP_PRODUCTION === STRIPE_PRODUCTION
                ? priceData?.liveStripePriceId
                : priceData?.testStripePriceId,
          },
        },
        // @TODO - use cache update instead
        refetchQueries: [MeDocument, AccountSubscriptionDocument],
        awaitRefetchQueries: true,
      });
      const { data, errors } = subscriptionResult;
      const subscription = data?.createSubscription;
      if (!errors && subscription) {
        const onOk = () => history.replace(Routes.DASHBOARD.path);
        if (subscription.active) {
          if (location?.pathname === "/app/dashboard") {
            window.location.reload();
          } else {
            history.replace(Routes.DASHBOARD.path);
          }
          notification.success({
            type: "success",
            message: "Success!",
            description: "The LLUNA service has been provisioned. Enjoy!",
            placement: "bottomLeft",
          });
        } else {
          Modal.warning({
            onOk,
            title: "Action required",
            content:
              "The LLUNA service will be provisioned when you complete the required action.",
          });
        }
      }
      setLoading(false);
    }
  };

  return (
    <Row>
      {subscription && (
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "1.5rem 0",
          }}
        >
          <Title level={3}>Complete Payment</Title>
          <Link
            strong
            onClick={() => {
              setModalContent(
                <PlanSummary
                  planData={planData}
                  setModalContent={setModalContent}
                />
              );
            }}
          >
            <ArrowLeftOutlined style={{ fontSize: 10 }} /> Back to Pricing
          </Link>
        </Col>
      )}
      <Col span={24}>
        <Row gutter={24}>
          {!subscription && (
            <Col span={24}>
              <Title level={3}>Add Payment Method</Title>
            </Col>
          )}
          {subscription && (
            <Col span={12} style={{ display: "flex" }}>
              <S.FancyCard>
                <Space direction="vertical" size="middle">
                  <div>
                    <Text
                      type="secondary"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <Title level={3}>Plus |</Title>
                      <text style={{ fontSize: 16 }}>
                        ${priceData?.pricePerUnit} per user/month, billed
                        annually
                      </text>
                    </Text>
                  </div>
                  <div>
                    <Row justify="space-between" style={{ marginTop: "2rem" }}>
                      <Col>
                        <Sp.Text strong>TeamOS Plus Plan</Sp.Text>
                      </Col>
                    </Row>

                    <Row justify="space-between">
                      <Col>
                        <Sp.Text alt>Quantity {quantity}</Sp.Text>
                      </Col>
                      <Col>
                        <Sp.Text alt>
                          {totalPriceLabel} /{" "}
                          {priceData?.interval === "YEAR" ? "year" : "month"}
                        </Sp.Text>
                      </Col>
                    </Row>
                  </div>
                  <Row justify="space-between">
                    <Col>
                      <Sp.Text strong>Subtotal</Sp.Text>
                    </Col>
                    <Col>
                      <Sp.Text strong>{totalPriceLabel}</Sp.Text>
                    </Col>
                    <Divider />
                  </Row>
                  <Row justify="space-between">
                    <Col span={couponData ? "" : 24}>
                      {couponData ? (
                        `${couponData?.name} - ${couponData?.percent_off}% off for ${couponData?.duration_in_months} months`
                      ) : (
                        <Input
                          value={checkcouponId}
                          placeholder="Enter coupon code"
                          onChange={(e: any) => setCouponId(e.target.value)}
                          suffix={
                            <span
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={(e) => handleClick(e)}
                            >
                              CHECK
                            </span>
                          }
                        />
                      )}
                    </Col>
                    {couponData && (
                      <Col>
                        <span
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => setCouponData("")}
                        >
                          Remove
                        </span>
                      </Col>
                    )}
                    {/* <Divider /> */}
                  </Row>
                  <div>
                    <Row justify="space-between">
                      <Col>
                        <Sp.Text strong>Total due today</Sp.Text>
                      </Col>
                      <Col>
                        <Sp.Text strong>{finalPrice()}</Sp.Text>
                      </Col>
                    </Row>
                  </div>
                </Space>
              </S.FancyCard>
            </Col>
          )}

          <Col span={subscription ? 12 : 24} style={{ display: "flex" }}>
            <S.FancyCard>
              <PaymentElement onReady={() => setIsCheckoutFormReady(true)} />

              <S.ButtonWrap>
                <ButtonPrimary
                  onClick={() => !loading && onContinueHandler()}
                  style={{ fontWeight: 600, marginTop: "0.5rem" }}
                  loading={loading}
                >
                  {!subscription
                    ? "Add"
                    : loading
                    ? "Processing..."
                    : "Make Payment"}
                </ButtonPrimary>
              </S.ButtonWrap>
            </S.FancyCard>
          </Col>
        </Row>
      </Col>
      <CouponsModal
        isVisible={isVisible}
        setisVisible={setisVisible}
        setCouponData={setCouponData}
      />
    </Row>
  );
};
